import * as bootstrap from "bootstrap";
import List from "list.js";

const firebaseService = require("../services/firebase");
const { toDateTime, modalMsg, modalAlert } = require("../utils");

var listOptions = {
	valueNames: [
		"id",
		"domain",
		"status",
		"host",
		"txt",
		"dnsType",
		"dnsEntry",
		"options",
	],
	page: 7,
	pagination: true,
};
var listAdminOptions = {
	valueNames: [
		"id",
		"email",
		"domain",
		"status",
		"options",
	],
	page: 7,
	pagination: true,
};

let userDomainModal, domaintxtModal, domainDnsModal,btnModalListener;


const initDomains = function (functions, modalAlert) {

	if (document.getElementById("domainModal")) {
		initDomainModal(functions, modalAlert);
	}
	if (document.getElementById("tbDomainList")) {
		setupListDomains(functions, modalAlert);
	}
	if (document.getElementById("tbDomainAdminList")) {
		setupListAdminDomains(functions, modalAlert);
	}
	if (document.getElementById("domaintxtModal")) {
		initDomainModaltxt(functions, modalAlert);
	}
	if (document.getElementById("domainDnsFinalModal")) {
		initDomainModalDns(functions, modalAlert);
	}
};

const initDomainModal = function (functions, modalAlert) {
	const modalEl = document.getElementById("domainModal");

	if (!modalEl) {
		return;
	}

	userDomainModal = bootstrap.Modal.getOrCreateInstance(modalEl);

	const userDomainForm = document.getElementById("userDomainForm");

	if (userDomainForm != null) {
		userDomainForm.addEventListener("submit", async (e) => {
			e.preventDefault();

			document.getElementById("btnDomainAdd").innerHTML = `
			<div class="spinner-border spinner-border-sm" role="status">
			<span class="sr-only">Carregando...</span>
			</div>Aguarde...`;

			callAddDomainToUser(functions, modalAlert);
		});
	}
	modalEl.addEventListener("hidden.bs.modal", function (event) {
		document.getElementById("domainUrl").innerHTML = "";
	});

	//check user before create domain

	const btnAddDomain= document.getElementById("btnAddDomain")

	if (btnAddDomain != null) {
		btnAddDomain.addEventListener("click", async (e) => {
			e.preventDefault();

			btnAddDomain.innerHTML=`
			<div class="spinner-border spinner-border-sm" role="status">
			<span class="sr-only">Carregando...</span>
			</div>Aguarde...`;

			firebaseService.callFirebaseFunction("checkUseDomains", {
			userEmail: sessionStorage.getItem("viewAsUsr")
			},{useCache: false})
			.then(result => {
				console.log(result.data);
				if(result.data.numDomains>result.data.numDomainsActive){
					userDomainModal.toggle();
					btnAddDomain.innerHTML='<i class="fas fa-globe"></i> Cadastrar Domínio';
				}else{
					modalMsg('danger.svg','Cadastrar Domínio','Voce já atingiu sua conta de '+result.data.numDomains+' domínios ativos. Para cadastrar mais domínios faça um upgrade de plano.','Ver Opções de Planos','btn-success');
					btnModalListener = (e)=> {
					e.preventDefault();
					top.location.href='subscribe.html'
					};
					
					document.getElementById('modalInfo' + 'btn').addEventListener("click", btnModalListener, true);
				
					btnAddDomain.innerHTML='<i class="fas fa-globe"></i> Cadastrar Domínio';

				}

			
			})
			.catch(error => {
				modalAlert(error.message);
				console.log(error);
				
			});
			});
	}

	
	
};
const initDomainModaltxt = function (functions, modalAlert) {
	const modalEl = document.getElementById("domaintxtModal");

	if (!modalEl) {
		return;
	}

	domaintxtModal = bootstrap.Modal.getOrCreateInstance(modalEl);

	const txtDomainForm = document.getElementById("txtDomainForm");

	if (txtDomainForm != null) {
		txtDomainForm.addEventListener("submit", async (e) => {
			e.preventDefault();

			document.getElementById("btntxtDomainAdd").innerHTML = `
			<div class="spinner-border spinner-border-sm" role="status">
			<span class="sr-only">Carregando...</span>
			</div>Aguarde...`;

			callSendtxtToUser(functions, modalAlert);
		});
	}
	// modalEl.addEventListener("hidden.bs.modal", function (event) {
	// 	document.getElementById("domainUrl").innerHTML = "";
	// });
};

const initDomainModalDns = function (functions, modalAlert) {
	const modalEl = document.getElementById("domainDnsFinalModal");

	if (!modalEl) {
		return;
	}

	domainDnsModal = bootstrap.Modal.getOrCreateInstance(modalEl);

	const dnsDomainForm = document.getElementById("dnsDomainForm");

	if (dnsDomainForm != null) {
		dnsDomainForm.addEventListener("submit", async (e) => {
			e.preventDefault();

			document.getElementById("btndnsDomainAdd").innerHTML = `
			<div class="spinner-border spinner-border-sm" role="status">
			<span class="sr-only">Carregando...</span>
			</div>Aguarde...`;

			callSendDnsToUser(functions, modalAlert);
		});
	}
	// modalEl.addEventListener("hidden.bs.modal", function (event) {
	// 	document.getElementById("domainUrl").innerHTML = "";
	// });
};
const callAddDomainToUser = function (functions, modalAlert) {


	// addDomaintoUserFn({
	// 	domain: document.getElementById("domainUrl").value,
	// 	userEmail: sessionStorage.getItem("viewAsUsr")
	// })

	firebaseService.callFirebaseFunction("addDomaintoUser", {
			domain: document.getElementById("domainUrl").value,
			userEmail: sessionStorage.getItem("viewAsUsr")
			},{useCache: false})
		.then((result) => {
			userDomainModal.toggle();

			document.getElementById("btnDomainAdd").innerHTML = "Confirmar";

			console.log(result.data);

			modalAlert(result.data.message, "success", "domains.html");
		})
		.catch((error) => {
			document.getElementById("btnDomainAdd").innerHTML = "Confirmar";

			const err = document.getElementById("userDomainlError");

			err.innerHTML = error;

			err.style.display = "";
		});

};

const callSendtxtToUser = function (functions, modalAlert) {
	

	firebaseService.callFirebaseFunction("sendtxtToUser",{
		domainId: document.getElementById("domainId").value,
		email: document.getElementById("emailtxt").value,
		host: document.getElementById("hostUrl").value,
		txtentry: document.getElementById("txtEntry").value,
	},{ useCache: false })
		.then((result) => {
			domaintxtModal.toggle();

			document.getElementById("btntxtDomainAdd").innerHTML = "Confirmar";

			console.log(result.data);

			modalAlert(result.data.message, "success", "admin_domains.html");
		})
		.catch((error) => {
			document.getElementById("btntxtDomainAdd").innerHTML = "Confirmar";

			const err = document.getElementById("txtDomainError");

			err.innerHTML = error;

			err.style.display = "";
		});

};

const callSendDnsToUser = function (functions, modalAlert) {

	firebaseService.callFirebaseFunction("sendDnsToUser", {
		domainId: document.getElementById("domainDnsId").value,
		email: document.getElementById("emailDns").value,
		dnsType: document.getElementById("dnsType").value,
		host: document.getElementById("hostDns").value,
		dnsEntry: document.getElementById("dnsEntry").value,
	},{useCache:false})
		.then((result) => {
			domainDnsModal.toggle();

			document.getElementById("btndnsDomainAdd").innerHTML = "Confirmar";

			//console.log(result.data);

			modalAlert(result.data.message, "success", "admin_domains.html");
		})
		.catch((error) => {
			document.getElementById("btndnsDomainAdd").innerHTML = "Confirmar";

			const err = document.getElementById("dnsDomainError");

			err.innerHTML = error;

			err.style.display = "";
		});

};


// setup lastclick in link-details function
const setupListDomains = (functions,modalAlert) => {



	const tbDomainList=document.getElementById('tbDomainList');


	firebaseService.callFirebaseFunction("getCnameByUser", {
		userEmail: sessionStorage.getItem("viewAsUsr")
	},{ttlMinutes:5})
		.then((result) => {
			// Init list
			var listDomain = new List(tbDomainList, listOptions);
			firebaseService.callFirebaseFunction("checkUseDomains", {
				userEmail: sessionStorage.getItem("viewAsUsr")
			},{ttlMinutes:5})
				.then((result2) => {
					const availableDomains = result2.data.numDomains;
					if(availableDomains<6)
						document.getElementById('avalDomains').innerHTML=availableDomains+' <a href="subscribe.html" class="btn btn-success btn-sm">Quero mais domínios</span>';
					else 
						document.getElementById('avalDomains').innerHTML="Ilimitados";

				})
				.catch((error) => {
					modalAlert(error.message);
				});

				document.getElementById('totalDomains').innerHTML=result.data.length;
				
			if (result.data.length) {
				
				let arrStatus=[];
				arrStatus.push('<span class="bg-warning">Aguardando Ação dos técnicos</span>');
				arrStatus.push('<span class="bg-danger text-white">Aguardando Ação do usuário</span> <button class="btn btn-warning btn-sm txtModal fas fa-plus" title="Clique para saber mais"></button>');
				arrStatus.push('<span class="bg-warning">Aguardando técnicos confirmarem suas entradas DNS</span>');
				arrStatus.push('<span class="bg-success">Dóminio pronto para ser configurado</span> <button class="btn btn-warning btn-sm dnsModal fas fa-plus" title="Clique para saber mais"></button>');

				

				let arrOptions=[];
				arrOptions.push('');
				arrOptions.push('<button class="btn btn-success btn-sm confirmEntryTxt fas fa-check-circle" title="Confirmar que a entrada TXT foi efetuada."></button>');
				arrOptions.push('');
				arrOptions.push('<button class="btn btn-success btn-sm setDefaultDomain fas fa-tachometer-alt" title="Configurar como domínio de exibição na dashboard"></button>');


	 
				//list options
				//console.log(result.data);
			


				result.data.forEach(doc => {
					listDomain.add({
						
						id: doc.id,
						domain: doc.domain,
						status: arrStatus[doc.status],
						host: doc.host||"",
						txt: doc.txtEntry||"",
						dnsType : doc.dnsType,
						dnsEntry: doc.dnsEntry,
						options: (!doc.isSet) ? arrOptions[doc.status]:'',
					  });
					
				});


				listDomain.remove("id",0);
				userDomainsOptions(functions,modalAlert);
				// enableDisableUsers('disabledAdmin',functions,modalAlert);
				// enableDisableAdmins('usrAdmin',functions,modalAlert);
				// enableDisableFakeSubscriptions('fakeSubscription',functions,modalAlert);
				// verifyUserEmail('verified',functions,modalAlert);
				// viewAs('usrViewAs');
			
				// fix pagination problem
				var pageClass = document.querySelectorAll('.page');
				pageClass.forEach(item => item.href = '#!');
				//showDeepLnkChoices('options');
			
				listDomain.on('updated', function(menuList){    
					userDomainsOptions(functions,modalAlert);
				//   enableDisableUsers('disabledAdmin',functions,modalAlert);
				//   enableDisableAdmins('usrAdmin',functions,modalAlert);
				//   enableDisableFakeSubscriptions('fakeSubscription',functions,modalAlert);
				//   verifyUserEmail('verified',functions,modalAlert);
				//   viewAs('usrViewAs');
			
				  // fix pagination problem
				  pageClass = document.querySelectorAll('.page');
				  pageClass.forEach(item => item.href = '#!');
				  
				}); 
			
			
			
			  } else {
				listDomain.remove("id",0);
				listDomain.add({id:0,domain:"Nenhum registro encontrado"})
			  }
		})
		.catch((error) => {
			modalAlert(error.message);
		});
	
	//console.log(testFilter);
	
  
  
  };


  // setup lastclick in link-details function
const setupListAdminDomains = (functions,modalAlert) => {



	const tbDomainAdminList=document.getElementById('tbDomainAdminList');

	firebaseService.callFirebaseFunction("getPendingDomains")
		.then((result) => {
			// Init list
			var listAdminDomain = new List(tbDomainAdminList, listAdminOptions);
			const checkUseDomainsFn = functions.httpsCallable("checkUseDomains");
			// checkUseDomainsFn({
			// 	userEmail: sessionStorage.getItem("viewAsUsr")
			// })
			// 	.then((result2) => {
			// 		const availableDomains = result2.data.numDomains;
			// 		if(availableDomains<6)
			// 			document.getElementById('avalDomains').innerHTML=availableDomains+' <a href="subscribe.html" class="btn btn-success btn-sm">Quero mais domínios</span>';
			// 		else 
			// 			document.getElementById('avalDomains').innerHTML="Ilimitados";

			// 	})
			// 	.catch((error) => {
			// 		modalAlert(error.message);
			// 	});

			// 	document.getElementById('totalDomains').innerHTML=result.data.length;
				
			if (result.data.length) {
				
				let arrStatus=[];
				arrStatus.push('<span class="bg-warning">Acessar Console para Gerar Txt - <a href="https://console.firebase.google.com/project/deeplink-b3800/hosting/sites" target="_blank">Link</a></span>');
				arrStatus.push('<span class="bg-warning">Aguardando Ação do usuário</span>');
				arrStatus.push('<span class="bg-warning">Confirmar Entrada TXT Usuário e enviar dados finais - <a href="https://console.firebase.google.com/project/deeplink-b3800/hosting/sites" target="_blank">Link</a></span>');

				let arrOptions=[];
				arrOptions.push('<button class="btn btn-primary btn-sm domainAdminModal fas fa-file-alt" title="Enviar entrada Txt para o usuário"></button>');
				arrOptions.push('');
				arrOptions.push('<button class="btn btn-success btn-sm domainAdminDnsModal fas fa-file-alt" title="Enviar dados finais"></button>');

				result.data.forEach(doc => {
					listAdminDomain.add({
						
						id: doc.id,
						email: doc.email,
						domain: doc.domain,
						status: arrStatus[doc.status],
						options: arrOptions[doc.status],
					  });
					
				});


				listAdminDomain.remove("id",0);
				adminDomainsOptions(functions,modalAlert);
				// enableDisableUsers('disabledAdmin',functions,modalAlert);
				// enableDisableAdmins('usrAdmin',functions,modalAlert);
				// enableDisableFakeSubscriptions('fakeSubscription',functions,modalAlert);
				// verifyUserEmail('verified',functions,modalAlert);
				// viewAs('usrViewAs');
			
				// fix pagination problem
				var pageClass = document.querySelectorAll('.page');
				pageClass.forEach(item => item.href = '#!');
				//showDeepLnkChoices('options');
			
				listAdminDomain.on('updated', function(menuList){    
					adminDomainsOptions(functions,modalAlert);
			
				  // fix pagination problem
				  pageClass = document.querySelectorAll('.page');
				  pageClass.forEach(item => item.href = '#!');
				  
				}); 
			
			
			
			  } else {
				listAdminDomain.remove("id",0);
				listAdminDomain.add({id:0,domain:"Nenhum registro encontrado"})
			  }
		})
		.catch((error) => {
			modalAlert(error.message);
		});
	
	//console.log(testFilter);
	
  
  
  };

function domainAdminModalClickEvent(functions,modalAlert) {
	return function (e) {
	e.preventDefault();
	
	var path = e.path || (e.composedPath && e.composedPath());
	document.getElementById("domainId").value= path[2].getElementsByTagName("td")[0].innerHTML;
	document.getElementById("emailtxt").value= path[2].getElementsByTagName("td")[2].innerHTML;
	//document.getElementById("domaintxtUrl").value= path[2].getElementsByTagName("td")[1].innerHTML;
	domaintxtModal.toggle();
	
	};
}
function domainAdminDnsModalClickEvent(functions,modalAlert) {
	return function (e) {
	e.preventDefault();
	
	var path = e.path || (e.composedPath && e.composedPath());
	document.getElementById("domainDnsId").value= path[2].getElementsByTagName("td")[0].innerHTML;
	document.getElementById("emailDns").value= path[2].getElementsByTagName("td")[2].innerHTML;
	document.getElementById("hostDns").value= path[2].getElementsByTagName("td")[1].innerHTML;
	domainDnsModal.toggle();
	
	};
}

function viewTxtInfoClickEvent(functions,modalAlert) {
	return function (e) {
	e.preventDefault();
	
	var path = e.path || (e.composedPath && e.composedPath());
	const host = path[2].getElementsByTagName("td")[3].innerHTML;
	const txtEntry = path[2].getElementsByTagName("td")[4].innerHTML;
	//document.getElementById("domaintxtUrl").value= path[2].getElementsByTagName("td")[1].innerHTML;
	//domaintxtModal.toggle();

	const htmlModal=`
	<p>Para conseguir validar  é necessário que adicione os registros TXT abaixo ao seu provedor de DNS para confirmar que você é o proprietário de <b>`+host+`</b>. Não exclua seus registros TXT.</p>
	<p>Seguem dados abaixo:<br>
	<table class="table table-bordered">
		<tr>
			<td>Tipo</td><td>Host</td><td>Valor</td>
		</tr>
		<tr>
			<td>TXT</td><td>`+host+`</td><td>`+txtEntry+`</td>
		</tr>
	</table>
	`;

	//modalMsg('help.svg','Configurar Entrada TXT',htmlModal);
	modalAlert(htmlModal,"terms",null,null,"Configurar Entrada TXT");
	
	};
}
function viewDnsInfoClickEvent(functions,modalAlert) {
	return function (e) {
	e.preventDefault();
	
	var path = e.path || (e.composedPath && e.composedPath());
	const host = path[2].getElementsByTagName("td")[1].innerHTML;
	const dnsType = path[2].getElementsByTagName("td")[5].innerHTML;
	const dnsEntry = path[2].getElementsByTagName("td")[6].innerHTML;
	//document.getElementById("domaintxtUrl").value= path[2].getElementsByTagName("td")[1].innerHTML;
	//domaintxtModal.toggle();

	const htmlModal=`
	<p>Seu domínio se encontra pronto para ser configurado em seu provedor de DNS.</p>
	<p>Seguem dados abaixo:<br>
	<table class="table table-bordered">
		<tr>
			<td>Tipo</td><td>Host</td><td>Valor</td>
		</tr>
		<tr>
			<td>`+dnsType+`</td><td>`+host+`</td><td>`+dnsEntry+`</td>
		</tr>
	</table>
	<p><b>OBS:<b> Caso tenha feito é necessário aguardar até 24 horas para a propagação de DNS.</p>
	`;

	//modalMsg('help.svg','Configurar Entrada TXT',htmlModal);
	modalAlert(htmlModal,"terms",null,null,"Configurar Entrada DNS Final");
	
	};
}

function confirmEntryTxtClickEvent(functions,modalAlert) {
	return function (e) {
	e.preventDefault();

	if (
		confirm(
			"Confirma ter efetuado a entrada DNS?"
		))
		{
			e.target.innerHTML="Aguarde...";
			var path = e.path || (e.composedPath && e.composedPath());
			const domainId = path[2].getElementsByTagName("td")[0].innerHTML;
			const host = path[2].getElementsByTagName("td")[3].innerHTML;
			const txtEntry = path[2].getElementsByTagName("td")[4].innerHTML;
		
					firebaseService.callFirebaseFunction("confirmTxtEntry", {
						domainId:domainId,
						host: host,
						txtentry: txtEntry
					},{useCache:false})
						.then((result) => {
							modalMsg('success.svg','Confirmação enviada',result.data.message);
							e.target.innerHTML="";
		
						})
						.catch((error) => {
							modalAlert(error.message);
							e.target.innerHTML="";
						});

		}
	


	
	
	};
}

function setDefaulDomainClickEvent(functions,modalAlert) {
	return function (e) {
	e.preventDefault();
	var path = e.path || (e.composedPath && e.composedPath());

	if (
		confirm(
			"Deseja que o domínio "+path[2].getElementsByTagName("td")[1].innerHTML+" seja o padrão para exibição de links em sua dashboard"
		))
		{
			e.target.innerHTML="Aguarde...";
			
			const domainId = path[2].getElementsByTagName("td")[0].innerHTML;
			const domain = path[2].getElementsByTagName("td")[1].innerHTML;
	
					firebaseService.callFirebaseFunction("setUserDefaultDomain", {
						domainId:domainId,
						userEmail: sessionStorage.getItem("viewAsUsr"),
						domain: domain
					},{useCache:false})
						.then((result) => {
							modalMsg('success.svg','Domínio Padrão',result.data.message,null,null,'domains.html');
							e.target.innerHTML="";
		
						})
						.catch((error) => {
							modalAlert(error.message);
							e.target.innerHTML="";
						});

		}
	


	
	
	};
}

const adminDomainsOptions = (functions,modalAlert) => {
	//open modal to txt to user
	const domainAdminModalClass = document.querySelectorAll(".domainAdminModal");

	if (domainAdminModalClass != null) {
		
		domainAdminModalClass.forEach((item) => {
			//alert('teste');
			item.onclick = domainAdminModalClickEvent(functions,modalAlert);
		});

	}

	//open modal to send final DNS to user
	const domainAdminDnsModalClass = document.querySelectorAll(".domainAdminDnsModal");

	if (domainAdminDnsModalClass != null) {
		
		domainAdminDnsModalClass.forEach((item) => {
			//alert('teste');
			item.onclick = domainAdminDnsModalClickEvent(functions,modalAlert);
		});

	}
};

const userDomainsOptions = (functions,modalAlert) => {
	//open modal to txt to user
	const txtModalClass = document.querySelectorAll(".txtModal");

	if (txtModalClass != null) {
		
		txtModalClass.forEach((item) => {
			//alert('teste');
			item.onclick = viewTxtInfoClickEvent(functions,modalAlert);
		});

	}

	//confirm txt entry

	const confirmEntryTxt = document.querySelectorAll(".confirmEntryTxt");

	if (confirmEntryTxt != null) {
		
		confirmEntryTxt.forEach((item) => {
			//alert('teste');
			item.onclick = confirmEntryTxtClickEvent(functions,modalAlert);
		});

	}

	//open modal to dns to user
	const dnsModalClass = document.querySelectorAll(".dnsModal");

	if (dnsModalClass != null) {
		
		dnsModalClass.forEach((item) => {
			//alert('teste');
			item.onclick = viewDnsInfoClickEvent(functions,modalAlert);
		});

	}
	//set defaul domain to user's dashboard

	const setDefaulDomain = document.querySelectorAll(".setDefaultDomain");

	if (setDefaulDomain != null) {
		
		setDefaulDomain.forEach((item) => {
			//alert('teste');
			item.onclick = setDefaulDomainClickEvent(functions,modalAlert);
		});

	}
};


  





export { initDomains };
