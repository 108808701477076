module.exports = [
	{
		onCall: ["createUser","editUser","addUserDetails","enableDisableUser","enableDisableAdmin","verifyUnverifyEmail","enableDisableFakeSubscription"],
		clean: ["getUsers"],
	},
	{
		onCall: ["addAlternateEmail","delUserAlternateEmail"],
		clean: ["fetchUserAlternateEmails"],
	},
	{
		onCall: ["addLink","edtLink","editLink","hideUnhideLink"],
		clean: ["getAvailableClicks", "getLinksByUser"],
	},
	{
		onCall: ["addDomaintoUser"],
		clean: ["checkUseDomains","getPendingDomains","getCnameByUser"],
	},
];
