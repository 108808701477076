import {
	query,
	collection,
	getDocs,
	orderBy,
	startAt,
	where,
	endAt,
} from "firebase/firestore";

let db;
let linkTypesCol,linksCol;

const init = function (firestore) {
	db = firestore;
	linkTypesCol = collection(db, "linkTypes");
	linksCol = collection(db, "links");
};

const getLinkTypesByBaseDesc = function (baseDescription) {
	const q = query(
		linkTypesCol,
		orderBy("descriptionLowerCase"),
		startAt(baseDescription.toLowerCase()),
		endAt(baseDescription.toLowerCase() + "\uf8ff")
	);

	const querySnapshot = getDocs(q);

	return querySnapshot;
};

const checkLinkExists = function (linkType,shortLink) {
	const q = query(
		linksCol,
		where("linkType", "==", linkType),
		where("shortLink", "==", shortLink)
	);

	const querySnapshot = getDocs(q);

	return querySnapshot;
};

const getLinksByMedia = function (media) {
	const q = query(
		linksCol,
		where("media", "==", media)
	);

	const querySnapshot = getDocs(q);

	return querySnapshot;
};
const getLinkTypesByMedia = function (media) {
	const q = query(
		linkTypesCol,
		where("media", "==", media)
	);

	const querySnapshot = getDocs(q);

	return querySnapshot;
};

const getLinkTypes = function () {
	const q = query(
		linkTypesCol,
		orderBy("description")
	);

	const querySnapshot = getDocs(q);

	return querySnapshot;
};

export { init, getLinkTypesByBaseDesc,checkLinkExists, getLinksByMedia, getLinkTypes, getLinkTypesByMedia };
