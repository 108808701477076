import * as bootstrap from "bootstrap";
import List from "list.js";

const firebaseService = require("../services/firebase");

const { toDateTime, modalMsg, modalAlert } = require("../utils");

var listOptions = {
	valueNames: [
		"id",
		"displayName",
		"email",
		"creationTime",
		"lastSignInTime",
		"disabledAdmin",
		"usrAdmin",
		"usrPlan",
		"options",
	],
	page: 7,
	pagination: true,
};

let userModal,userEmailModal;
let registerModal;

const checkUserPlan = function (admin = false, modalAlert) {
	//Caching getUserActivePlan
	console.log(admin);

	if (window.location.pathname == "/links.html"){
		firebaseService.callFirebaseFunction("getUserActivePlan", {
			userEmail: sessionStorage.getItem("viewAsUsr"),
		}).then((result) => {
			//console.log("getUserActivePlan cached");
			//exibir alert se o usuário for do plano free
			//console.log(result.data);
			
			if (result.data=="vazio"&&!admin)
				document.getElementById("alertSubscribe").style.display="";
		})
		.catch((error) => {
			modalAlert(error);
		
			console.log(error);
		});

	}

};

const initUsers = function (functions, modalAlert) {
	// if (document.getElementById("userModal")) {
	// 	initUsertModal(functions, modalAlert);
	// }

	// if (document.getElementById("registerModal")) {
	// 	initRegisterModal(functions, modalAlert);
	// }
	// if (document.getElementById("registerForm")) {
	// 	initUsertRegister(functions, modalAlert);
	// }
	// if (document.getElementById("spnUserEmail")) {
	// 	initUserDetailsandPLan(functions, modalAlert);
	// }

	if (document.getElementById("userEmailModal")) {
		initUserEmailModal(functions, modalAlert);
	}
};

const initUserEmailModal = function (functions, modalAlert) {
	const modalEl = document.getElementById("userEmailModal");

	if (!modalEl) {
		return;
	}

	userEmailModal = bootstrap.Modal.getOrCreateInstance(modalEl);

	const userEmailAddForm = document.getElementById("userAddEmailForm");

	if (userEmailAddForm != null) {
		userEmailAddForm.addEventListener("submit", async (e) => {
			e.preventDefault();

			document.getElementById("btnUserEmailAdd").innerHTML = `
			<div class="spinner-border spinner-border-sm" role="status">
			<span class="sr-only">Carregando...</span>
			</div>Aguarde...`;

			callAddEmailToUser(functions, modalAlert);
		});
	}
	modalEl.addEventListener("hidden.bs.modal", function (event) {
		document.getElementById("userEmailList").innerHTML = "";
		document.getElementById("userEmailListGrp").style.display = "none";
		document.getElementById("userEmailError").style.display = "none";
	});
};

const callAddEmailToUser = function (functions, modalAlert) {
	//const addAlternateEmailFn = functions.httpsCallable("addAlternateEmail");

	// addAlternateEmailFn({
	// 	userEmail: document.getElementById("userMainEmail").value,
	// 	newEmail: document.getElementById("newEmail").value,
	// })
	firebaseService.callFirebaseFunction("addAlternateEmail", {
		userEmail: document.getElementById("userMainEmail").value,
		newEmail: document.getElementById("newEmail").value,
		},{useCache:false})
		.then((result) => {
			userEmailModal.toggle();

			document.getElementById("btnUserEmailAdd").innerHTML = "Confirmar";

			modalAlert(result.data.message, "success", "users.html");
		})
		.catch((error) => {
			document.getElementById("btnUserEmailAdd").innerHTML = "Confirmar";

			const err = document.getElementById("userEmailError");

			err.innerHTML = error;

			err.style.display = "";
		});

};

const initUserDetailsandPLan = function (functions, modalAlert) {
	const getUserDetails = functions.httpsCallable("getUserDetails");
	getUserDetails({
		userEmail: sessionStorage.getItem("viewAsUsr"),
	})
		.then((result) => {
			//console.log(result.data);
			document.getElementById("spnUserDisplayName").innerHTML =
				result.data.userDisplayName;
			document.getElementById("spnUserEmail").innerHTML = result.data.id;
			document.getElementById("spnUserPhone").innerHTML =
				result.data.phone || "N/A";
			document.getElementById("spnActuaPlan").innerHTML = result.data.userPlan;
			document.getElementById("spnPlanValue").innerHTML = result.data.planPrice;

			//fill user details form
			document.getElementById("userFrmEmail").value = result.data.id;
			document.getElementById("userId").value = result.data.userId;
			document.getElementById("userFrmPhone").value =
				result.data.phone || "N/A";

			const myModalEl = document.getElementById("userEditModal");

			if (!myModalEl) {
				return;
			}

			userModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

			//user edit modal action

			document
				.getElementById("userEditForm")
				.addEventListener("submit", async (e) => {
					e.preventDefault();

					document.getElementById("btnUserEdit").innerHTML = `
				<div class="spinner-border spinner-border-sm" role="status">
				<span class="sr-only">Carregando...</span>
				</div>Aguarde...`;

					callEditOwnUser(functions, modalAlert);
				});

			//if user has plan change button action
			if (result.data.userPlan != "Gratuito") {
				document.getElementById("btnChangePlan").innerText = "Cancelar Plano";
				document.getElementById("btnChangePlan").href = "#!";

				document
					.getElementById("btnChangePlan")
					.addEventListener("click", async (e) => {
						e.preventDefault();
						const cancelPlanTxt =
							`Para troca de plano é necessário acessar a plataforma hotmart na qual o link pode
					ser acessado do e-mail que você recebeu quando fez a compra ou fazendo um cadastro usando o email
					<i>` +
							(sessionStorage.getItem("viewAsUsr") || result.data.id) +
							`</i> no seguinte endereço:<br>
					<a href="https://purchase.hotmart.com" target="_blank">https://purchase.hotmart.com/</a>`;

						modalMsg(
							"danger.svg",
							"Cancelar Plano",
							cancelPlanTxt,
							null,
							null,
							null,
							null,
							true
						);
					});
			}
		})
		.catch((error) => {
			modalAlert(error.message);
			console.log(error);
		});
};

//init userRegistration
const initUsertRegister = function (functions, modalAlert) {
	const registerForm = document.getElementById("registerForm");

	if (registerForm != null) {
		registerForm.addEventListener("submit", async (e) => {
			e.preventDefault();

			document.getElementById("btnRegister").innerHTML = `
				<div class="spinner-border spinner-border-sm" role="status">
				<span class="sr-only">Carregando...</span>
				</div>Aguarde...`;

			callRegisterUser(functions, modalAlert);
		});
	}
};

// Init modal for adding new clients
const initUsertModal = function (functions, modalAlert) {
	const myModalEl = document.getElementById("userModal");

	if (!myModalEl) {
		return;
	}

	userModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

	myModalEl.addEventListener("hidden.bs.modal", function (event) {
		document.getElementById("userFrmEmail").value = "";
		document.getElementById("userFrmEmail").disabled = false;

		document.getElementById("displayName").value = "";

		document.getElementById("userPassword").value = "";

		document.getElementById("userId").value = "";

		document.getElementById("modalUserTitle").innerHTML = "Cadastrar Usuário";

		document.getElementById("lblFrmPassword").innerHTML =
			'Senha<span class="text-danger">*</span>';

		document.getElementById("userError").style.display = "none";

		document.getElementById("selectProjectCompanyId").disabled = "";
		document.getElementById("selectProjectCompanyId").text = "";
		document.getElementById("selectProjectCompanyId").value = "";
		document.getElementById("selectProjectCompanyId").selectedIndex = 0;

		document.getElementById("selectProjectClientId").disabled = "";
		document.getElementById("selectProjectClientId").text = "";
		document.getElementById("selectProjectClientId").value = "";
		document.getElementById("selectProjectClientId").selectedIndex = 0;
	});

	const userAddForm = document.getElementById("userAddForm");

	if (userAddForm != null) {
		userAddForm.addEventListener("submit", async (e) => {
			e.preventDefault();

			document.getElementById("btnUserAdd").innerHTML = `
   <div class="spinner-border spinner-border-sm" role="status">
   <span class="sr-only">Carregando...</span>
   </div>Aguarde...`;

			if (document.getElementById("userId").value) {
				callEditUser(functions, modalAlert);
			} else {
				callCreateUser(functions, modalAlert);
			}
		});
	}

	//Fill Company Dropdown
	const getCompanyList = functions.httpsCallable("fetchCompanies");
	getCompanyList({ showHidden: false })
		.then((result) => {
			var select = document.getElementById("selectProjectCompanyId");
			result.data.forEach((doc) => {
				var el = document.createElement("option");
				el.text = doc.name;
				el.value = doc.id;

				select.add(el);
			});

			fillClientDropdown(functions, modalAlert);

			select.addEventListener("change", function (e) {
				fillClientDropdown(functions, modalAlert);
			});
		})
		.catch((error) => {
			modalAlert(error.message);
			console.log(error);
		});
};

const fillClientDropdown = function (functions, modalAlert, selectId = 0) {
	// const getClientList = functions.httpsCallable("fetchClients");
	// getClientList({ showHidden: false })
	firebaseService.callFirebaseFunction("fetchClients", {
		showHidden: false,
		})
		.then((result) => {
			var select = document.getElementById("selectProjectClientId");
			select.innerHTML = "";
			// for (let i = 0; i < select.options.length; i++) {
			// 	const element = select.options[i];
			// 	select.remove(element);
			// }

			const selectedCompanyId = document.getElementById(
				"selectProjectCompanyId"
			).value;

			var el = document.createElement("option");
			el.text = "Todas";
			el.value = "0";

			select.add(el);

			result.data.forEach((doc) => {
				if (selectedCompanyId && doc.companyId == selectedCompanyId) {
					var el = document.createElement("option");
					el.text = doc.name;
					el.value = doc.id;

					select.add(el);
				}

				if (!selectedCompanyId) {
					var el = document.createElement("option");
					el.text = doc.name;
					el.value = doc.id;

					select.add(el);
				}
			});

			if (selectId) select.value = selectId;
		})
		.catch((error) => {
			modalAlert(error.message);
			console.log(error);
		});
};

// setup lastclick in link-details function
const setupListUsers = (data,functions,modalAlert) => {

	const listUsers=document.getElementById('listUsers');
	console.log(data);
	//console.log(userDetailsdata);
	
	//console.log(testFilter);
	if (data != null) {
	 
	  //list options
  
	  var listOptions = {
		valueNames: [ 'displayName','email', 'phone','creationTime', 'lastSignInTime', 'disabledAdmin', 'verified', 'usrAdmin', 'fakeSubscription', 'options'],
		page: 7,
		pagination: true
	  };
  
	  // Init list
	  var lCList = new List('listUsers', listOptions);
	  var textDisabled='';
	  var textVerified='';
	  var textAdmin='';
	  var textPhone='';
	  let options = '';
	  var textFakeSubs='';
	  var auxLimitDate;
	  var dateClass='';
	  var diffDays='';
	  var UnverifiedEmail=0;
	  const dateNow=new Date();
	  for (var i=0;i<data.length;i++) {
		if(data[i].fb){

			// if (userDetailsdata.msg=='Necessita ser configurado'){
		//   dateClass='bg-danger text-white';
		//   auxLimitDate='não tem assinatura';
		  
		// }else{
		//   const avalClicksarr = userDetailsdata.filter(function(element){return element.id == data[i]['email'];});
		//   //console.log(avalClicksarr.length);
		//   if(avalClicksarr.length&&avalClicksarr[0].namePlan){
		// 	auxLimitDate=toDateTime(avalClicksarr[0].nextCharge['_seconds']-10800).toISOString().replace(/T/, ' ').replace(/\..+/, '')+' - '+avalClicksarr[0].namePlan;
		// 	diffDays=DateDiff.inDays(dateNow,toDateTime(avalClicksarr[0].nextCharge['_seconds']));
  
		// 	if (diffDays<10&&diffDays>=0)
		// 	  dateClass='bg-warning';
		// 	else if (diffDays<0)
		// 	  dateClass='bg-danger text-white';
		// 	else
		// 	  dateClass='';
  
		//   }else{
		// 	auxLimitDate='não tem assinatura';
		// 	dateClass='bg-danger text-white';
		// 	diffDays=0;
		//   }
  
		// }
  
		//get user detais data
		//const userDetaisarr = userDetailsdata.filter(function(element){return element.id == data[i]['email'];});
  
		//console.log(userDetaisarr);
  
		if(data[i].details)
		textPhone=data[i].details.userPhoneNumber||"";
  

	  // if(userDetaisarr.length&&userDetaisarr[0].deeplinkChoices)
	  //   DeepCh=userDetaisarr[0].deeplinkChoices.join(' ');
	  // else
	  //   DeepCh='';

	  //dateClass='bg-danger text-white';
	  //auxLimitDate='Necessita ser configurado';
	  let dtlastSignInTime,dtcreationTime;

	  if(Date(data[i]['fb']&&data[i]['fb']['metadata']&&data[i]['fb']['metadata']['lastSignInTime'])){
		  dtlastSignInTime = new Date(data[i]['fb']['metadata']['lastSignInTime']);
		  dtlastSignInTime.setHours(dtlastSignInTime.getHours() - 3);
		  dtcreationTime = new Date(data[i]['fb']['metadata']['creationTime']);
		  dtcreationTime.setHours(dtcreationTime.getHours() - 3);

	  }else{
		  dtlastSignInTime="Nunca";
		  dtcreationTime="Nunca";
	  }

	  //const dtcreationTime = new Date(data[i]['metadata']['creationTime']);


	  

	  if (data[i]['fb']['customClaims']){
		if (data[i]['fb']['customClaims']['admin'])
		textAdmin='<span class="text-danger" id="'+data[i]['email']+'">●</span> Admin <span class="fas fa-user-minus mr-4 text-success"></span>';
		else
		textAdmin='<span class="text-success" id="'+data[i]['email']+'">●</span> Usuário <i class="fas fa-user-ninja mr-4 text-danger"></i>';

		
		
		if (data[i]['fb']['customClaims']['fakeSubscription'])
		textFakeSubs='<span class="text-danger" id="'+data[i]['email']+'">●</span> Ativa <span class="fas fa-newspaper mr-4 text-success"></span>';
		else
		textFakeSubs='<span class="text-success" id="'+data[i]['email']+'">●</span> Inativa <i class="fas fa-newspaper mr-4 text-danger"></i>';
		
		
	  }else{
	  textAdmin='<span class="text-success" id="'+data[i]['email']+'">●</span> Usuário <i class="fas fa-user-ninja mr-4 text-danger"></i>';
	  textFakeSubs='<span class="text-success" id="'+data[i]['email']+'">●</span> Inativa <i class="fas fa-newspaper mr-4 text-danger"></i>';
	  }
	  if (data[i]['fb']['disabled'])
	  textDisabled='<span class="text-warning" id="'+data[i]['email']+'">●</span> Inativo <i class="fas fa-user mr-4 text-success"></i>';
	  else
	  textDisabled='<span class="text-success" id="'+data[i]['email']+'">●</span> Ativo <i class="fas fa-user-slash mr-4 text-danger"></i>';

	  if (data[i]['fb']['emailVerified']){
	  textVerified='<span class="text-success" id="'+data[i]['email']+'">●</span> Sim <i class="fas fa-envelope mr-4 text-success"></i>';
	  }else{
	  UnverifiedEmail++;
	  textVerified='<span class="text-danger" id="'+data[i]['email']+'">●</span> Não <i class="fas fa-envelope-open mr-4 text-danger"></i>';
	  }
	  lCList.add({
		displayName: data[i]['fb']['displayName'],
		email: data[i]['email']+' <a href="#"><i class="fas fa-eye usrViewAs" title="visualizar como"></i></a>',
		phone: '<a href="https://api.whatsapp.com/send?phone='+textPhone.replace(/\D/g, '')+'">'+textPhone+'</a>',
		creationTime: dtcreationTime.toISOString().replace(/T/, ' ').replace(/\..+/, ''),
		lastSignInTime: dtlastSignInTime.toISOString().replace(/T/, ' ').replace(/\..+/, ''),
		//limitDate: '<span class="'+dateClass+'">'+auxLimitDate+'</span>',//toDateTime(avalClicksarr.limitDate['_seconds']).toLocaleString('pt-BR'),
		disabledAdmin: textDisabled,
		verified: textVerified,
		usrAdmin: textAdmin,
		fakeSubscription: textFakeSubs,
		options:
			`<!--<button class="btn btn-warning btn-sm userEditModal fas fa-edit" title="Alterar Usuário"></button>--> 
			 <button class="btn btn-primary btn-sm userEmailModal fas fa-at" title="Emails Adicionais"></button> `,
	  });

		}
		
		
  
  
	  }
	  document.getElementById('unconfirmedUsersCount').innerText=' Não Verificado: '+UnverifiedEmail;
	  lCList.remove('displayName','Aguarde...');
	  enableDisableUsers('disabledAdmin',functions,modalAlert);
	  enableDisableAdmins('usrAdmin',functions,modalAlert);
	  enableDisableFakeSubscriptions('fakeSubscription',functions,modalAlert);
	  verifyUserEmail('verified',functions,modalAlert);
	  viewAs('usrViewAs');
  
	  // fix pagination problem
	  var pageClass = document.querySelectorAll('.page');
	  pageClass.forEach(item => item.href = '#!');
	  //showDeepLnkChoices('options');
  
	  lCList.on('updated', function(menuList){    
		enableDisableUsers('disabledAdmin',functions,modalAlert);
		enableDisableAdmins('usrAdmin',functions,modalAlert);
		enableDisableFakeSubscriptions('fakeSubscription',functions,modalAlert);
		verifyUserEmail('verified',functions,modalAlert);
		viewAs('usrViewAs');
  
		// fix pagination problem
		pageClass = document.querySelectorAll('.page');
		pageClass.forEach(item => item.href = '#!');
		
	  }); 
  
  
  
	} else {
	  listUsers.innerHTML = 'Sem Cliques no momento';
	}
  
  
  };

function viewAsClickEvent(e) {
	e.preventDefault();
	var path = e.path || (e.composedPath && e.composedPath());
	//console.log(e.path[2].innerText.trim());
	sessionStorage.setItem("viewAsUsr", path[2].innerText.trim());
	top.location.href = "links.html";
}

const viewAs = (linkClass) => {
	const viewAsClass = document.querySelectorAll("." + linkClass);

	if (viewAsClass != null) {
		//alert('teste');
		viewAsClass.forEach((item) =>
			item.removeEventListener("click", viewAsClickEvent)
		);
		viewAsClass.forEach((item) =>
			item.addEventListener("click", viewAsClickEvent)
		);
	}
};


function enableDisableUsersClickEvent(functions,modalAlert) {
	return function (e) {
		e.preventDefault();
	
			var path = e.path || (e.composedPath && e.composedPath());
			//console.log(e.path[2].innerText.trim());
			//sessionStorage.setItem("viewAsUsr", e.path[2].innerText.trim());
			//top.location.href='links.html'
			e.preventDefault();
			var txtAtivo='';
			var sendBoolDisabled;
			//console.log(e.target.innerText);
			if(e.target.innerText=='● Ativo '){
			txtAtivo='desativar';
			sendBoolDisabled=true;
			}else{
			txtAtivo='ativar';
			sendBoolDisabled=false;
			}
	
			//modalAlert("Função disponível em breve", "warning");
			if (confirm('deseja '+txtAtivo+' o usuário '+path[1].children[1].innerText.trim()+'?')){
			// const enableDisableUser = functions.httpsCallable('enableDisableUser');
				
			// enableDisableUser({ 
			// 	email: e.path[1].children[1].innerText.trim(),
			// 	disabled: sendBoolDisabled
			// })
			firebaseService.callFirebaseFunction("enableDisableUser", {
				email: path[1].children[1].innerText.trim(),
				disabled: sendBoolDisabled,
				},{useCache:false})
			.then(result => {
			
				//console.log(result);
				modalAlert(result.data.message,'success','users.html');
				return true;
			})
			.catch(error => {
				modalAlert(error.message);
			});
			
			}
		};
  }
  
  const enableDisableUsers = (linkClass='disabledAdmin',functions,modalAlert) => {
  
  
  
	const enableDisableUsersClick = document.querySelectorAll('.'+linkClass);
  
	if (enableDisableUsersClick != null) {
	  //alert('teste');
	  //enableDisableUsersClick.forEach(item => item.removeEventListener('click', enableDisableUsersClickEvent(functions,modalAlert)));
	  enableDisableUsersClick.forEach(item => item.onclick=enableDisableUsersClickEvent(functions,modalAlert));
  
	}
  
	
  
	
  };

  const enableDisableFakeSubscriptions = (linkClass,functions,modalAlert) => {


	const enableDisableFakeClick = document.querySelectorAll('.'+linkClass);
  
	if (enableDisableFakeClick != null) {
	  //alert('teste');
	  enableDisableFakeClick.forEach(item => item.onclick=enableDisableFakeClickEvent(functions,modalAlert));
  
	}
  };

  function verifyUserEmailClickEvent(functions,modalAlert) {
	return function (e) {
		e.preventDefault();
			var path = e.path || (e.composedPath && e.composedPath());
			//console.log(e.path[2].innerText.trim());
			//sessionStorage.setItem("viewAsUsr", e.path[2].innerText.trim());
			//top.location.href='links.html'
			e.preventDefault();
			var txtAtivo='';
			var sendBoolEnabled;
			//console.log(e.target.innerText);
			if(e.target.innerText=='● Não '){
			txtAtivo='confirmar';
			sendBoolEnabled=true;
			}else{
			txtAtivo='desconfirmar';
			sendBoolEnabled=false;
			}
	
			//modalAlert("Função disponível em breve", "warning");
			if (confirm('deseja '+txtAtivo+' o email '+path[1].children[1].innerText.trim()+'?')){
			// const verifyUnverifyEmail = functions.httpsCallable('verifyUnverifyEmail');
				
			// verifyUnverifyEmail({ 
			// 	email: e.path[1].children[1].innerText.trim() ,
			// 	enabled: sendBoolEnabled
			// })
			firebaseService.callFirebaseFunction("verifyUnverifyEmail", {
				email: path[1].children[1].innerText.trim() ,
				enabled: sendBoolEnabled,
				},{useCache:false})
			.then(result => {
				
				//console.log(result);
				modalAlert(result.data.message,'success','users.html');
				return true;
			})
			.catch(error => {
				modalAlert(error.message);
			});
			
			}
	};
  }
  
  const verifyUserEmail = (linkClass,functions,modalAlert) => {
  
  
	const verifyUserEmailClick = document.querySelectorAll('.'+linkClass);
  
	if (verifyUserEmailClick != null) {
	  //alert('teste');
	  //verifyUserEmailClick.forEach(item => item.removeEventListener('click', verifyUserEmailClickEvent(functions,modalAlert)));
	  verifyUserEmailClick.forEach(item => item.onclick = verifyUserEmailClickEvent(functions,modalAlert));
  
	}
  };
  

const enableDisableAdmins = (linkClass, functions, modalAlert) => {
	const enableDisableAdminsClick = document.querySelectorAll("." + linkClass);

	if (enableDisableAdminsClick != null) {
		//alert('teste');

		enableDisableAdminsClick.forEach((item) => {
			item.onclick = enableDisableAdminsClickEvent(functions, modalAlert);
		});
	}

	const userEmailClick = document.querySelectorAll(".userEmailModal");

	if (userEmailClick != null) {
		userEmailClick.forEach((item) => {
			item.onclick = userEmailClickEvent(functions, modalAlert);
			//console.log(item);
		});
	}

	const userEditClick = document.querySelectorAll(".userEditModal");

	if (userEditClick != null) {
		userEditClick.forEach((item) => {
			item.onclick = userEditClickEvent(functions, modalAlert);
			//console.log(item);
		});
	}
};

const enableDisableAdminsClickEvent = function (functions, modalAlert) {
	return function (e) {
		e.preventDefault();
		var txtAtivo = "";
		var sendBoolEnabled;
		console.log(e.target.innerText);
		//return ;
		if (e.target.innerText == "● Usuário ") {
			txtAtivo = "ativar";
			sendBoolEnabled = true;
		} else {
			txtAtivo = "desativar";
			sendBoolEnabled = false;
		}
		
		//modalAlert("Função disponível em breve", "warning");
		if (confirm("deseja " + txtAtivo + " esse usuário como Admin?")) {
			//const enableDisableAdmin = functions.httpsCallable("enableDisableAdmin");

			var path = e.path || (e.composedPath && e.composedPath());
			//console.log(path[1]);
			// enableDisableAdmin({
			// 	email: path[1].getElementsByTagName("td")[1].innerText.trim(),
			// 	userId: path[1].getElementsByTagName("td")[0].innerHTML,
			// 	enabled: sendBoolEnabled,
			// })
			firebaseService.callFirebaseFunction("enableDisableAdmin", {
				email: path[1].getElementsByTagName("td")[1].innerText.trim(),
				userId: path[1].getElementsByTagName("td")[0].innerHTML,
				enabled: sendBoolEnabled,
				},{useCache:false})
				.then((result) => {
					console.log(result.data);
					modalAlert(result.data.message, result.data.alert, "users.html");
					return true;
				})
				.catch((error) => {
					modalAlert(error.message);
				});
		}
	};
};

const enableDisableFakeClickEvent = function (functions, modalAlert) {
	return function (e) {
		e.preventDefault();
		var txtAtivo='';
		var sendBoolEnabled;
		console.log(e.target.innerText);
		if(e.target.innerText=='● Ativa '){
		  txtAtivo='desativar';
		  sendBoolEnabled=false;
		}else{
		  txtAtivo='ativar';
		  sendBoolEnabled=true;
		}

		var path = e.path || (e.composedPath && e.composedPath());
  
		//modalAlert("Função disponível em breve", "warning");
		if (confirm('deseja '+txtAtivo+' assinatura falsa para o email '+path[1].getElementsByTagName("td")[1].innerText.trim()+'?')){
		//   const enableDisableFakeSubscription = functions.httpsCallable('enableDisableFakeSubscription');
			 
		//   enableDisableFakeSubscription({ 
		// 	  email: path[1].getElementsByTagName("td")[1].innerText.trim() ,
		// 	  enabled: sendBoolEnabled
		//   })
		  firebaseService.callFirebaseFunction("enableDisableFakeSubscription", {
			email: path[1].getElementsByTagName("td")[1].innerText.trim() ,
			enabled: sendBoolEnabled
			},{useCache:false})
		  .then(result => {
			  
			  //console.log(result);
			  modalAlert(result.data.message,'success','users.html');
			  return true;
		  })
		  .catch(error => {
			  modalAlert(error.message);
		  });
		
		}
	};
};

function userEditClickEvent(functions, modalAlert) {
	return function (e) {
		// Use both myParam and event here
		e.preventDefault();
		//alert("ok");
		var path = e.path || (e.composedPath && e.composedPath());

		document.getElementById("userId").value =
			path[2].getElementsByTagName("td")[0].innerHTML;
		document.getElementById("displayName").value =
			path[2].getElementsByTagName("td")[1].innerHTML;

		document.getElementById("userFrmEmail").value =
			path[2].getElementsByTagName("td")[2].innerText;

		document.getElementById("lblFrmPassword").innerHTML = "Senha";

		document.getElementById("userFrmEmail").disabled = true;

		document.getElementById("modalUserTitle").innerHTML = "Alterar Usuário";

		userModal.toggle();
	};
}


function userEmailClickEvent(functions, modalAlert) {
	return function (e) {
		// Use both myParam and event here
		e.preventDefault();
		//alert("ok");
		var path = e.path || (e.composedPath && e.composedPath());

		document.getElementById("userEmailId").value =
			path[2].getElementsByTagName("td")[0].innerHTML;

		document.getElementById("userMainEmail").value =
			path[2].getElementsByTagName("td")[1].innerText.trim();


		// const fetchUseAlternateEmailsFn = functions.httpsCallable("fetchUserAlternateEmails");

		// fetchUseAlternateEmailsFn({
		// 	email: path[2].getElementsByTagName("td")[1].innerText.trim()
		// })
		firebaseService.callFirebaseFunction("fetchUserAlternateEmails", {
			email: path[2].getElementsByTagName("td")[1].innerText.trim()
			})
			.then((result) => {
				if(result.data.length>1){
					document.getElementById("userEmailListGrp").style.display="";
					const userEmailList=document.getElementById("userEmailList");

					result.data.forEach((doc) => {
						console.log(doc);
						if(doc!=path[2].getElementsByTagName("td")[1].innerText.trim())
						userEmailList.innerHTML=userEmailList.innerHTML+'<br>'+doc+' <i class="fas fa-trash text-danger delUserEmail" id="'+doc+'"></i>';
		
						
					});

					const emailDelClick = document.querySelectorAll(".delUserEmail");

					if (emailDelClick != null) {
						emailDelClick.forEach((item) => {
							item.onclick = delUserEmailClickEvent(functions, modalAlert);
							//console.log(item);
						});
	}


				}
			})
			.catch((error) => {
				document.getElementById("btnUserEmailAdd").innerHTML = "Confirmar";
	
				const err = document.getElementById("userEmailError");
	
				err.innerHTML = error;
	
				err.style.display = "";
			});


		userEmailModal.toggle();
	};
}

const delUserEmailClickEvent = function (functions, modalAlert) {
	return function (e) {
		e.preventDefault();

		
		//return ;

		//modalAlert("Função disponível em breve", "warning");
		if (confirm("deseja remover o e-mail " + e.target.id + "?")) {
			const delUserAlternateEmailFn = functions.httpsCallable("delUserAlternateEmail");

			//var path = e.path || (e.composedPath && e.composedPath());

			delUserAlternateEmailFn({
				userEmail: document.getElementById("userMainEmail").value,
				oldEmail: e.target.id
			})
				.then((result) => {
					e.target.onclick="";
					e.target.innerText="removido";
					return true;
				})
				.catch((error) => {
					const err = document.getElementById("userEmailError");
	
					err.innerHTML = error;
		
					err.style.display = "";
				});
		}
	};
};

const callCreateUser = function (functions, modalAlert) {
	// const createUserFn = functions.httpsCallable("createUser");

	// createUserFn({
	// 	name: userAddForm.displayName.value,
	// 	email: userAddForm.userFrmEmail.value,
	// 	password: userAddForm.userPassword.value,
	// 	companyId: userAddForm.selectProjectCompanyId.value,
	// 	clientId: userAddForm.selectProjectClientId.value,
	// })
	firebaseService.callFirebaseFunction("createUser", {
		name: userAddForm.displayName.value,
		email: userAddForm.userFrmEmail.value,
		password: userAddForm.userPassword.value,
		companyId: userAddForm.selectProjectCompanyId.value,
		clientId: userAddForm.selectProjectClientId.value,
		},{useCache:false})
		.then((result) => {
			userModal.toggle();

			document.getElementById("btnUserAdd").innerHTML = "Confirmar";

			modalAlert(result.data.message, "success", "users.html");
		})
		.catch((error) => {
			document.getElementById("btnUserAdd").innerHTML = "Confirmar";

			const err = document.getElementById("userError");

			err.innerHTML = error;

			err.style.display = "";
		});
};

const callEditUser = function (functions, modalAlert) {
	// const editUserFn = functions.httpsCallable("editUser");

	// editUserFn({
	// 	name: userAddForm.displayName.value,
	// 	email: userAddForm.userFrmEmail.value,
	// 	userId: userAddForm.userId.value,
	// 	password: userAddForm.userPassword.value,
	// 	companyId: userAddForm.selectProjectCompanyId.value,
	// 	clientId: userAddForm.selectProjectClientId.value,
	// })
	firebaseService.callFirebaseFunction("editUser", {
		name: userAddForm.displayName.value,
		email: userAddForm.userFrmEmail.value,
		userId: userAddForm.userId.value,
		password: userAddForm.userPassword.value,
		companyId: userAddForm.selectProjectCompanyId.value,
		clientId: userAddForm.selectProjectClientId.value,
		},{useCache:false})
		.then((result) => {
			userModal.toggle();

			document.getElementById("btnUserAdd").innerHTML = "Confirmar";

			modalAlert(result.data.message, "success", "users.html");
		})
		.catch((error) => {
			document.getElementById("btnUserAdd").innerHTML = "Confirmar";

			const err = document.getElementById("userError");

			err.innerHTML = error;

			err.style.display = "";
		});
};

const callEditOwnUser = function (functions, modalAlert) {
	const err = document.getElementById("userError");
	//clear error div
	err.style.display = "none";

	//check password is the same
	if (
		document.getElementById("userPassword").value != "" &&
		document.getElementById("userPassword").value !=
			document.getElementById("userPassword2").value
	) {
		document.getElementById("btnUserEdit").innerHTML = "Confirmar";

		err.innerHTML = "Senhas não conferem";

		err.style.display = "";
		return false;
	}

	// const editUserFn = functions.httpsCallable("editUser");

	// editUserFn({
	// 	email: document.getElementById("userFrmEmail").value,
	// 	userId: document.getElementById("userId").value,
	// 	password: document.getElementById("userPassword").value,
	// 	phone: document.getElementById("userFrmPhone").value,
	// })
	firebaseService.callFirebaseFunction("editUser", {
		email: document.getElementById("userFrmEmail").value,
		userId: document.getElementById("userId").value,
		password: document.getElementById("userPassword").value,
		phone: document.getElementById("userFrmPhone").value,
		},{useCache:false})
		.then((result) => {
			userModal.toggle();

			document.getElementById("btnUserEdit").innerHTML = "Confirmar";

			modalMsg(
				"success.svg",
				"Alterar Usuário",
				result.data.message,
				null,
				null,
				"user.html",
				null,
				true
			);

			//modalAlert(result.data.message, "success", "users.html");
		})
		.catch((error) => {
			document.getElementById("btnUserEdit").innerHTML = "Confirmar";

			err.innerHTML = error;

			err.style.display = "";
		});
};

// Init modal for registering new clients
const initRegisterModal = function (functions, modalAlert) {
	const myModalEl = document.getElementById("registerModal");

	if (!myModalEl) {
		return;
	}

	registerModal = bootstrap.Modal.getOrCreateInstance(myModalEl);

	myModalEl.addEventListener("hidden.bs.modal", function (event) {
		document.getElementById("registerFrmEmail").value = "";
		document.getElementById("registerFrmEmail").disabled = false;

		document.getElementById("displayNameRegister").value = "";

		document.getElementById("registerPassword").value = "";

		document.getElementById("modalRegisterTitle").innerHTML =
			"Cadastrar Usuário";

		document.getElementById("lblFrmPasswordRegister").innerHTML =
			'Senha<span class="text-danger">*</span>';

		document.getElementById("registerError").style.display = "none";
	});
};

const callRegisterUser = function (functions, modalAlert) {
	//console.log("a");
	const createUserFn = functions.httpsCallable("createUser");

	let userName = document.getElementById("displayNameRegister").value;
	let userEmail = document.getElementById("registerFrmEmail").value;
	let userPassword = document.getElementById("registerPassword").value;
	let userPhoneNumber = document.getElementById("registerPhoneNumber").value;

	if (userPassword != document.getElementById("registerPassword2").value) {
		modalAlert("Senhas não conferem", "alert");
		document.getElementById("btnRegister").innerHTML = "Confirmar";
	}
	return false;

	createUserFn({
		name: userName,
		email: userEmail,
		password: userPassword,
		phone: userPhoneNumber,
	})
		.then((result) => {
			//registerModal.toggle();

			document.getElementById("btnRegister").innerHTML = "Confirmar";

			modalAlert(result.data.message, "success", "login.html");
		})
		.catch((error) => {
			document.getElementById("btnRegister").innerHTML = "Confirmar";

			const err = document.getElementById("registerError");

			err.innerHTML = error;

			err.style.display = "";
		});
};

export { setupListUsers, initUsers , checkUserPlan};
