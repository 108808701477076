import * as bootstrap from "bootstrap";

const checkQueryString = function() {
	
	if (window.location.pathname == "/login.html"){
		//alert(window.location.href.includes('?'));
		if(window.location.href.includes('?')){
			//alert("passou aqui");
		const urlParams = new URLSearchParams(window.location.search);
		const redirectUrl = urlParams.get('redirect');
		
		top.location.href=redirectUrl;
		//return true;

		}else
		top.location.href = "links.html";
	}

};

const modalMsgNew = function (imgUrl, txtHeader, txtBody , btnTxt, btnClass,modalRedirect,btnModalListener,btnClose=false,modalInstance=false) {
	if (!modalInstance)
	modalInstance= new bootstrap.Modal(document.getElementById('modalInfo'), '');
	
	document.getElementById('modalInfo' + 'Header').innerHTML = txtHeader;
	document.getElementById('modalInfo' + 'Img').src = 'assets/img/'+imgUrl;
	document.getElementById('modalInfo' + 'Body').innerHTML = txtBody;
  
	if(btnTxt){
	  document.getElementById('modalInfo' + 'btn').innerHTML = btnTxt;
	  document.getElementById('modalInfo' + 'btn').style = 'display:block';
	}else
	  document.getElementById('modalInfo' + 'btn').style = 'display:none';
	
	//clear btn class
	document.getElementById('modalInfo' + 'btn').className = '';
  
	if(btnClass){
	  document.getElementById('modalInfo' + 'btn').className = 'mx-auto btn mb-2 '+btnClass;
	}
  
	if (btnClose)
		document.getElementById("modalInfobtnClose").style.display="";
		
	let myModalredir = document.getElementById('modal' + 'Info');
	  myModalredir.addEventListener('hidden.bs.modal', function (e) {
		//top.location.href = modalRedirect;
		document.getElementById('modalInfo' + 'btn').disabled=false;
		if(btnModalListener)
		document.getElementById('modalInfo' + 'btn').removeEventListener("click", btnModalListener, true);
		if (modalRedirect)
		  top.location.href = modalRedirect;
	  })
  
  
  
	
	
  
	if (modalRedirect) {
	  //myModalredir = document.getElementById('modal' + 'info');
	  myModalredir.addEventListener('hidden.bs.modal', function (e) {
		top.location.href = modalRedirect;
	  })
  
	}
  /*
	if (focusID) {
	  let myModalredir = document.getElementById('modal' + modalID);
	  myModalredir.addEventListener('hidden.bs.modal', function (e) {
		document.getElementById(focusID).focus();
	  })
	}*/
  
	modalInstance.toggle();
  
  }


function convertHMS(value) {
	const sec = parseInt(value, 10); // convert value to number if it's string
	let hours = Math.floor(sec / 3600); // get hours
	let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
	let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
	// add 0 if value < 10; Example: 2 => 02
	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600*24));
	var h = Math.floor(seconds % (3600*24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);
	
	var dDisplay = d > 0 ? d + (d == 1 ? "d:" : "d:") : "";
	var hDisplay = h > 0 ? h + (h == 1 ? "h:" : "h:") : "";
	var mDisplay = m > 0 ? m + (m == 1 ? "m:" : "m:") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
	return dDisplay + hDisplay + mDisplay + sDisplay;
}

const modalAlert = function (
	errMessage,
	modalID = "alert",
	modalRedirect = null,
	focusID = null,
	title = null
) {
	var myModal = new bootstrap.Modal(
		document.getElementById("modal" + modalID),
		""
	);
	myModal.toggle();
	document.getElementById(modalID + "Message").innerHTML = errMessage;

	if(title)
	document.getElementById(modalID + "Title").innerHTML = title;

	if (modalRedirect) {
		let myModalredir = document.getElementById("modal" + modalID);
		myModalredir.addEventListener("hidden.bs.modal", function (e) {
			top.location.href = modalRedirect;
		});
	}

	if (focusID) {
		let myModalredir = document.getElementById("modal" + modalID);
		myModalredir.addEventListener("hidden.bs.modal", function (e) {
			document.getElementById(focusID).focus();
		});
	}
};

//date time
function toDateTime(secs) {
	var t = new Date(1970, 0, 1); // Epoch
	t.setSeconds(secs - 3 * 3600);
	return t;
}

function startTimer(duration, display) {
	if (!display) {
		return;
	}

	var timer = duration,
		minutes,
		seconds;
	setInterval(function () {
		minutes = parseInt(timer / 60, 10);
		seconds = parseInt(timer % 60, 10);

		minutes = minutes < 10 ? "0" + minutes : minutes;
		seconds = seconds < 10 ? "0" + seconds : seconds;

		display.textContent = minutes + ":" + seconds;

		if (--timer < 0) {
			timer = duration;
		}
	}, 1000);
}

const modalMsg = function (imgUrl, txtHeader, txtBody , btnTxt, btnClass,modalRedirect,btnModalListener,btnClose=false,modalInstance=false) {
	if (!modalInstance)
	modalInstance= new bootstrap.Modal(document.getElementById('modalInfo'), '');
	
	document.getElementById('modalInfo' + 'Header').innerHTML = txtHeader;
	document.getElementById('modalInfo' + 'Img').src = 'assets/img/'+imgUrl;
	document.getElementById('modalInfo' + 'Body').innerHTML = txtBody;
  
	if(btnTxt){
	  document.getElementById('modalInfo' + 'btn').innerHTML = btnTxt;
	  document.getElementById('modalInfo' + 'btn').style = 'display:block';
	}else
	  document.getElementById('modalInfo' + 'btn').style = 'display:none';
	
	//clear btn class
	document.getElementById('modalInfo' + 'btn').className = '';
  
	if(btnClass){
	  document.getElementById('modalInfo' + 'btn').className = 'mx-auto btn mb-2 '+btnClass;
	}
  
	if (btnClose)
		document.getElementById("modalInfobtnClose").style.display="";
		
	let myModalredir = document.getElementById('modal' + 'Info');
	  myModalredir.addEventListener('hidden.bs.modal', function (e) {
		//top.location.href = modalRedirect;
		document.getElementById('modalInfo' + 'btn').disabled=false;
		if(btnModalListener)
		document.getElementById('modalInfo' + 'btn').removeEventListener("click", btnModalListener, true);
		if (modalRedirect)
		  top.location.href = modalRedirect;
	  })
  
  
  
	
	
  
	if (modalRedirect) {
	  //myModalredir = document.getElementById('modal' + 'info');
	  myModalredir.addEventListener('hidden.bs.modal', function (e) {
		top.location.href = modalRedirect;
	  })
  
	}
  /*
	if (focusID) {
	  let myModalredir = document.getElementById('modal' + modalID);
	  myModalredir.addEventListener('hidden.bs.modal', function (e) {
		document.getElementById(focusID).focus();
	  })
	}*/
  
	modalInstance.toggle();
  
  }

  function makeId(length = 6) {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

// async function getFinalURL(initialURL) {
//     try {
//         // Faz uma requisição fetch sem seguir automaticamente o redirecionamento
//         const response = await fetch(initialURL, { method: 'GET', redirect: 'manual' });

//         // Se a resposta contém o cabeçalho Location, significa que houve um redirecionamento
//         if (response.status >= 300 && response.status < 400 && response.headers.has('Location')) {
//             const finalURL = response.headers.get('Location');
//             console.log('URL final:', finalURL);
//             return finalURL;
//         } else {
//             console.log('Nenhum redirecionamento encontrado. URL final:', initialURL);
//             return initialURL;
//         }
//     } catch (error) {
//         console.error('Erro ao obter a URL final:', error);
//         return null;
//     }
// }


export { checkQueryString, convertHMS, makeId, modalAlert, modalMsg, modalMsgNew, secondsToDhms,startTimer, toDateTime };
