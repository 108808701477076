//
// user.js
// Use this to write your custom JS
//

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/compat/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

import { init, getLinkTypesByBaseDesc,checkLinkExists,getLinksByMedia,getLinkTypes,getLinkTypesByMedia} from "./helpers/db";
import { redirect} from "./appClick/redirect";

const firebaseService = require("./services/firebase");
import { ttlStorage } from "./helpers/ttlStorage";

import {
	connectFirestoreEmulator,
	initializeFirestore,
	getFirestore,
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
	apiKey: "AIzaSyBxspS6T2_wVBROeKrKwt8IWIMWNgNSeG8",
	authDomain: "deeplink-b3800.firebaseapp.com",
	databaseURL: "https://deeplink-b3800.firebaseio.com",
	projectId: "deeplink-b3800",
	storageBucket: "deeplink-b3800.appspot.com",
	messagingSenderId: "801637131445",
	appId: "1:801637131445:web:6642382abcdee3ebe28afc",
	measurementId: "G-C3SGMNNEQ2",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//firebase.analytics();

const auth = firebase.auth();

const db = firebase.firestore();

const functions = firebase.functions();

redirect(functions);

firebaseService.init(ttlStorage, functions, false);

//local tests
if (
	location.hostname === "localhost" ||
	location.hostname === "127.0.0.1" ||
	location.hostname === "0.0.0.0"
) {
	functions.useEmulator("localhost", 5001);
	connectFirestoreEmulator(db, "localhost", 8070);
	auth.useEmulator("http://localhost:9099");
} else if (
	location.hostname === "xmicroserver.ddns.net" ||
	location.hostname === "192.168.1.14"
) {
	functions.useEmulator("xmicroserver.ddns.net", 5001);
	connectFirestoreEmulator(db, "xmicroserver.ddns.net", 8070);
	auth.useEmulator("http://xmicroserver.ddns.net:9099");
}

init(db);

let linkDomain = "https://clico.link/"; //global var for link domain and cnames
//local tests
if (
	location.hostname === "localhost" ||
	location.hostname === "127.0.0.1" ||
	location.hostname === "0.0.0.0" ||
	location.hostname === "xmicroserver.ddns.net" ||
	location.hostname === "192.168.1.14"
) {
	linkDomain = "http://" + location.hostname + ":5000/"; //dev test
}

//Facebook Pixel Code -->

!(function (f, b, e, v, n, t, s) {
	if (f.fbq) return;
	n = f.fbq = function () {
		n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
	};
	if (!f._fbq) f._fbq = n;
	n.push = n;
	n.loaded = !0;
	n.version = "2.0";
	n.queue = [];
	t = b.createElement(e);
	t.async = !0;
	t.src = v;
	s = b.getElementsByTagName(e)[0];
	s.parentNode.insertBefore(t, s);
})(
	window,
	document,
	"script",
	"https://connect.facebook.net/en_US/fbevents.js"
);
fbq("init", "6685889378104364");
fbq("track", "PageView");
// End Facebook Pixel Code -->

//Global vars
const axios = require("axios");

const { checkQueryString, toDateTime, modalMsg, modalAlert, modalMsgNew } = require("./utils");

const { initUsers, setupListUsers, checkUserPlan } = require("./appClick/users");

const { initDomains } = require("./appClick/domains");

//modal info global var

const globModalInfo = bootstrap.Modal.getOrCreateInstance(
	document.getElementById("modalInfo")
);

initUsers(functions, modalAlert);
initDomains(functions, modalAlert);

let userLoggedEmail = "";
let btnModalListener;
//alert ('{webRoot}');

//modal create link wizard
let modalCreateLink, modalLinkTypes;

if (document.getElementById("modalCreateLink")) {
	modalCreateLink = new bootstrap.Modal(
		document.getElementById("modalCreateLink")
	);
}

//modal all links wizard

if (document.getElementById("modalLinkTypes")) {
	modalLinkTypes = new bootstrap.Modal(
		document.getElementById("modalLinkTypes")
	);
}

let backEndBaseURL = "https://us-central1-deeplink-b3800.cloudfunctions.net";

//backEndBaseURL='http://localhost:5001/deeplink-b3800/us-central1'; //dev tests

import * as bootstrap from "bootstrap";

import List from "list.js";

//tiktok api change

//const tiktok = require('tiktok-app-api');

//slider initial test

// Without JQuery

function executeFunctionByName(functionName, context /*, args */) {
	var args = Array.prototype.slice.call(arguments, 2);
	var namespaces = functionName.split(".");
	var func = namespaces.pop();
	for (var i = 0; i < namespaces.length; i++) {
		context = context[namespaces[i]];
	}
	return context[func].apply(context, args);
}

function debounce(callback, wait) {
	let timerId;
	return (...args) => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			callback(...args);
		}, wait);
	};
}

function validURL(str) {
	var pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i"
	); // fragment locator
	return !!pattern.test(str);
}

function pasteClipboard(documentID) {
	var pasteText = document.getElementById(documentID);
	pasteText.focus();
	document.execCommand("paste");
	
}

function FormatDatetoBR(data) {
	var dia = data.split("-")[2];
	var mes = data.split("-")[1];
	var ano = data.split("-")[0];

	return ("0" + dia).slice(-2) + "/" + ("0" + mes).slice(-2) + "/" + ano;
	// Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

var DateDiff = {
	inDays: function (d1, d2) {
		var t2 = d2.getTime();
		var t1 = d1.getTime();

		return parseInt((t2 - t1) / (24 * 3600 * 1000));
	},

	inWeeks: function (d1, d2) {
		var t2 = d2.getTime();
		var t1 = d1.getTime();

		return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
	},

	inMonths: function (d1, d2) {
		var d1Y = d1.getFullYear();
		var d2Y = d2.getFullYear();
		var d1M = d1.getMonth();
		var d2M = d2.getMonth();

		return d2M + 12 * d2Y - (d1M + 12 * d1Y);
	},

	inYears: function (d1, d2) {
		return d2.getFullYear() - d1.getFullYear();
	},
};

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function imageExists(image_url) {
	const http = new XMLHttpRequest();

	http.open("HEAD", image_url, false);
	http.send();

	return http.status != 404;
}

// Return Address by CEP
const returnAddr = function (cep) {
	axios({
		url: "https://brasilapi.com.br/api/cep/v1/" + cep,
		method: "GET",
	})
		.then((response) => {
			//modalAlert(response,'success');
			
			document.getElementById("address").value = response.data.street;
			document.getElementById("province").value = response.data.neighborhood;
			document.getElementById("address").value = response.data.street;
			document.getElementById("state").value = response.data.state;
			document.getElementById("city").value = response.data.city;
			document.getElementById("btn-addr-form").disabled = false;
		})
		.catch((error) => {
			console.log(error.response.data.errors);
			modalAlert(error.response.data.errors[1].message);
			document.getElementById("btn-addr-form").disabled = true;

			//exportClicksToExcel.innerText='Exportar Todos os Cliques';
			//exportClicksToExcel.disabled=false;
		});
};

function fnSendVerifyEmail(email, uid, btnID = null) {
	if (btnID) {
		var btnPrevTxt = document.getElementById(btnID).innerText;
		document.getElementById(btnID).innerHTML = `
    <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only">Aguarde...</span>
    </div>Aguarde...`;
		//document.getElementById(btnID).readOnly=true;
	}

	const sendVerifyEmail = functions.httpsCallable("sendVerifyEmail");

	sendVerifyEmail({
		email: email,
		uid: uid,
	})
		.then((result) => {
			//modalAlert('Email de confirmação reenviado','success');
			if (btnID) {
				document.getElementById(btnID).innerText = "Email Reenviado";
				document.getElementById(btnID).classList =
					"mx-auto btn mb-2 btn-success";
				document.getElementById(btnID).disabled = true;
				//document.getElementById(btnID).removeEventListener('click', e, false);
			} else {
				modalMsg(
					"success.svg",
					"Email de Confirmação Reenviado",
					"Caso não encontre a mensagem verifique sua caixa de SPAM"
				);
			}
			//modalMsg('email.svg','Email de Confirmação Reenviado','');
		})
		.catch((err) => {
			if (btnID) {
				document.getElementById(btnID).innerText = btnPrevTxt;
				document.getElementById(btnID).readOnly = false;
			}
			modalAlert(err.message);
			console.log(err);
		});
}

const sendVerifyEmailLnk01 = document.getElementById("sendVerifyEmailLnk01");
if (sendVerifyEmailLnk01) {
	sendVerifyEmailLnk01.addEventListener("click", (e) => {
		e.preventDefault();

		modalMsg(
			"email.svg",
			"Confirme seu email",
			"Para criar novos links é necesssário confirmar o seu email de acesso.",
			"Reenviar Email",
			"btn-outline-secondary"
		);

		btnModalListener = (e) => {
			e.preventDefault();
			fnSendVerifyEmail(
				auth.currentUser.email,
				auth.currentUser.uid,
				"modalInfo" + "btn"
			);
		};
		document
			.getElementById("modalInfo" + "btn")
			.addEventListener("click", btnModalListener, true);
	});
}

const btnreturnAddr = document.getElementById("returnAddr");
if (btnreturnAddr) {
	btnreturnAddr.addEventListener("click", (e) => {
		e.preventDefault();
		var postalCode = document.getElementById("postalCode").value;
		returnAddr(postalCode);
	});
}

const formAddress = document.getElementById("add-addr-form");

if (formAddress) {
	const getAddress = functions.httpsCallable("getAddress");
	getAddress()
		.then((result) => {
			

			if (result.data.totalCount == 0) {
				const txtBillWarn =
					"É necessário dados de endereço de fatura para assinar algum plano.";
				modalAlert(txtBillWarn, "warning");
				document
					.getElementById("billingLink")
					.addEventListener("click", (e) => {
						e.preventDefault();

						modalAlert(txtBillWarn, "warning");
					});
			} else {
				formAddress.name.value = result.data.data[0].name;
				formAddress.additionalEmails.value =
					result.data.data[0].additionalEmails || "";
				formAddress.cpfCnpj.value = result.data.data[0].cpfCnpj;
				formAddress.mobilePhone.value = result.data.data[0].mobilePhone || "";
				formAddress.address.value = result.data.data[0].address;
				formAddress.addressNumber.value = result.data.data[0].addressNumber;
				formAddress.complement.value = result.data.data[0].complement;
				formAddress.province.value = result.data.data[0].province;
				formAddress.postalCode.value = result.data.data[0].postalCode;
				//formAddress.city.value=result.data.data[0].city;
				formAddress.state.value = result.data.data[0].state;
				formAddress.country.value = result.data.data[0].country;
				formAddress.cusID.value = result.data.data[0].id;

				axios({
					url:
						"https://brasilapi.com.br/api/cep/v1/" +
						result.data.data[0].postalCode,
					method: "GET",
				})
					.then((response) => {
						//modalAlert(response,'success');
						
						document.getElementById("city").value = response.data.city;
						//document.getElementById('btn-addr-form').disabled=false;
					})
					.catch((error) => {
						console.log(error.response.data.errors);
						modalAlert(error.response.data.errors[1].message);
						//document.getElementById('btn-addr-form').disabled=true;

						//exportClicksToExcel.innerText='Exportar Todos os Cliques';
						//exportClicksToExcel.disabled=false;
					});

				//modalAlert('Dados atualizados com sucesso','success'); //,'account-general.html'
				document.getElementById("btn-addr-form").innerHTML = "Salvar Dados";
				document.getElementById("btn-addr-form").disabled = false;
				document.getElementById("billingLink").href = "account-billing.html";
			}
		})
		.catch((error) => {
			modalAlert(error);
			document.getElementById("btn-addr-form").innerHTML = "Salvar Dados";
			document.getElementById("btn-addr-form").disabled = true;
			
		});

	formAddress.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btn-addr-form").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Aguarde...</span>
      </div>Aguarde...`;

		const addAddress = functions.httpsCallable("addAddress");
		addAddress({
			cusID: formAddress.cusID.value,
			name: formAddress.name.value,
			additionalEmails: formAddress.additionalEmails.value,
			cpfCnpj: formAddress.cpfCnpj.value,
			mobilePhone: formAddress.mobilePhone.value,
			address: formAddress.address.value,
			addressNumber: formAddress.addressNumber.value,
			complement: formAddress.complement.value,
			province: formAddress.province.value,
			postalCode: formAddress.postalCode.value,
			city: formAddress.city.value,
			state: formAddress.state.value,
			country: formAddress.country.value,
		})
			.then((result) => {
				
				modalAlert(
					"Dados atualizados com sucesso",
					"success",
					"account-general.html"
				); //,'account-general.html'
				//document.getElementById('btn-addr-form').innerHTML='Salvar Dados';
			})
			.catch((error) => {
				modalAlert(error);
				document.getElementById("btn-addr-form").innerHTML = "Salvar Dados";
				console.log(error);
			});
	});
}

// get current logged user active plan

const activePlan = document.getElementById("activePlan");

if (activePlan) {
	// const getUserActivePlan = functions.httpsCallable("getUserActivePlan");
	// getUserActivePlan({ userEmail: sessionStorage.getItem("viewAsUsr") })
		firebaseService.callFirebaseFunction("getUserActivePlan", {
			userEmail: sessionStorage.getItem("viewAsUsr"),
		}).then((result) => {
			if (result.data != "vazio") {
				
				document.getElementById("activePlanDesc").innerHTML =
					"Plano:<br> " + result.data.namePlan;
				document.getElementById("activePlanPrice").innerText =
					"" +
					result.data.price.toFixed(2).replace(".", ",") +
					"/" +
					result.data.cicle;
				document.getElementById("btnSelectPlan").innerText = "Troca de Plano";
				document.getElementById("btnSelectPlan").href = "#";
				document.getElementById("colAddClicks").innerHTML =
					'<a class="btn btn-sm btn-primary" id="btnSelectAddClicks" href="add-clicks.html">Quero mais cliques</a>';
				document
					.getElementById("planDateLimit")
					.setAttribute(
						"data-original-title",
						"Próxima renovação até: " +
							toDateTime(result.data.nextCharge["_seconds"]).toLocaleString(
								"pt-BR"
							)
					);
				//document.getElementById('btnSelectPlan').style.display = "none";
				//document.getElementById('divUnsubscribe').style.display = "block";

				//Button to show

				document
					.getElementById("btnSelectPlan")
					.addEventListener("click", (e) => {
						const chanhePlanHtml =
							`Para efetuar troca de plano é necessário acessar a plataforma hotmart na qual o link pode
      ser acessado do e-mail que você recebeu quando fez a compra ou fazendo um cadastro usando o email
      <i>` + sessionStorage.getItem("viewAsUsr") ||
							userLoggedEmail +
								`</i> no seguite endereço:<br>
      <a href="https://purchase.hotmart.com" target="_blank">https://purchase.hotmart.com/</a>
      `;
						modalAlert(chanhePlanHtml, "success"); //,'account-general.html'
						//document.getElementById('btn-addr-form').innerHTML='Salvar Dados';
					});

				/* Cancel subscription now in on the hotmart marketplace
    document.getElementById('btnUnsubscribePlan').addEventListener('click', (e) => {
      if (confirm('Tem certeza que deseja cancelar? Essa ação não pode ser desfeita')){
        const unSignPlan = functions.httpsCallable('unSignPlan');
        unSignPlan({ "planID": result.data.data[0].id})
        .then(result => {
            
           
            modalAlert(result.data.message,result.data.alertType,'account-billing.html'); //,'account-general.html'
            //document.getElementById('btn-addr-form').innerHTML='Salvar Dados';
        })
        .catch(error => {
            modalAlert(error);
            //document.getElementById('btn-addr-form').innerHTML='Salvar Dados';
            console.log(error);
        });
      }

    });
    */
			} else {
				document.getElementById("btnSelectPlan").innerText = "Assinar";
				document.getElementById("btnSelectPlan").href = "subscribe.html";
				/*document.getElementById('btnSelectPlan').addEventListener('click', (e) => {
        if (confirm('Ao Selecionar um novo plano seu plano anterior será cancelado. Deseja Continuar?')){
          top.location.href='pricing.html'
        }
              
  
      });*/
			}
		})
		.catch((error) => {
			modalAlert(error);

			console.log(error);
		});
}

// get current logged user invoices

const userInvoices = document.getElementById("userInvoiceList");

//Caching getUserPayments
if (window.location.pathname == "/links.html"){
	firebaseService.callFirebaseFunction("getUserPayments", {
		userEmail: sessionStorage.getItem("viewAsUsr"),
	}).then((result) => {
		//console.log("getUserPayments cached");
	})
	.catch((error) => {
		modalAlert(error);
	
		console.log(error);
	});

}

if (userInvoices) {
	firebaseService.callFirebaseFunction("getUserPayments", {
		userEmail: sessionStorage.getItem("viewAsUsr"),
	}).then((result) => {
			

			if (result.data != "vazio") {
				
				//list options

				//document.getElementById('activePlanDesc').innerText='Plano Atual: '+result.data.data[0].description;
				//document.getElementById('activePlanPrice').innerText='R$ '+result.data.data[0].value.toFixed(2).replace('.',',')+'/mês';
				var listOptions = {
					valueNames: ["id", "data", "desc", "valor", "status"],
				};

				// Init list
				var invoiceList = new List("userInvoiceList", listOptions);

				result.data.forEach((doc) => {
					const link = doc;
					//const invoiceurl = "https://www.asaas.com/i/" + link.id.substr(4, link.id.length);
					let badgebg = "";
					let badgetxt = "";

					switch (link.signature_status.toUpperCase()) {
						case "CANCELED":
							badgebg = "alert";
							badgetxt = "Vencida";
							break;
						case "ACTIVE":
							badgebg = "success";
							badgetxt = "Pago";
							break;
						case "OVERDUE":
							badgebg = "alert";
							badgetxt = "Vencida";
							break;
						case "REFUNDED":
							badgebg = "secondary";
							badgetxt = "Estornada";
							break;
						case "RECEIVED_IN_CASH":
							badgebg = "success";
							badgetxt = "Recebido em Dinheiro";
							break;
						case "AWAITING_RISK_ANALYSIS":
							badgebg = "warning";
							badgetxt = "Pagamento em análise";
							break;
					}

					invoiceList.add({
						id: link.id.split('-')[0],
						data: toDateTime(
							link.confirmation_purchase_date["_seconds"]
						).toLocaleString("pt-BR"),
						desc: link.name_subscription_plan || "Cobrança Mensal",
						valor: "R$ " + link.price.toFixed(2).replace(".", ","),
						status:
							' <span class="badge bg-' + badgebg + '">' + badgetxt + "</span>",
					});
				});
				invoiceList.remove("id", "Aguarde...");
			} else {
				document.getElementById("userInvoiceListFirstCol").innerText =
					"Nenhum Fatura Gerada";
			}
		})
		.catch((error) => {
			modalAlert(error);

			console.log(error);
		});
}

//get users available Clicks

const availableClicks = document.getElementById("lblavailableClicks");

if (availableClicks) {
	// const getAvailableClicks = functions.httpsCallable("getAvailableClicks");
	// getAvailableClicks({ userEmail: sessionStorage.getItem("viewAsUsr") })
	firebaseService.callFirebaseFunction("getAvailableClicks", {
		userEmail: sessionStorage.getItem("viewAsUsr"),
	},{ useCache:true, ttlMinutes: 5})
		.then((result) => {
			
			//calculo de porcentagem consumida
			const percentUsage =  ((result.data.totalClicks - result.data.availableClicks)/result.data.totalClicks)*100
			//console.log(percentUsage);

			if (result.data.totalClicks<0&&percentUsage>80&&window.location.pathname == "/links.html")
			{
				//exibir alerta de consumo de 80% sugerindo cliques adicionais
				document.getElementById("alertPercentUsage").style.display="";
			}

			//list options
			if (
				document.getElementById("extraClicksBagde") &&
				result.data.availableClicks < 0
			)
				document.getElementById("extraClicksBagde").style.display = "";

			availableClicks.innerText =
				result.data.availableClicks + " de " + result.data.totalClicks;

			if (result.data.limitDate)
				document
					.getElementById("clicksDateLimit")
					.setAttribute(
						"data-bs-original-title",
						"Seus cliques expiram em: " +
							toDateTime(result.data.limitDate["_seconds"]).toLocaleString(
								"pt-BR"
							)
					);

			//set cusID
			//document.getElementById('cusID').value=result.data.cusID;
			//get over click state

			/*
      if (result.data.allowOverClicks){
        document.getElementById('chkAllowOverClicks').checked=true;
        document.getElementById('lblAllowOverClicks').innerText='Ativo';
        document.getElementById('txtAllowOverClicks').innerText='Desativar cliques adicionais';
      }else{
        document.getElementById('chkAllowOverClicks').checked=false;
        document.getElementById('lblAllowOverClicks').innerText='Inativo';
        document.getElementById('txtAllowOverClicks').innerText='Ativar cliques adicionais';
      }
      

      //set switch event to enable/disable overclicks
     
      document.getElementById('chkAllowOverClicks').addEventListener('change', function() {
        let overClicksWarntxt='';
        document.getElementById('lblAllowOverClicks').innerHTML=`
        <div class="spinner-border  spinner-border-sm text-primary" role="status">
          <span class="sr-only">Loading...</span> 
        </div>
        Aguarde
        `;
        if (document.getElementById('chkAllowOverClicks').checked)
        overClicksWarntxt='Cobranças adicionais poderão ocorrer no seu planos, caso você ative cliques adicionais. Deseja mesmo fazer isso?';
        else
        overClicksWarntxt='Seus links poderão ficar inativos, caso você desative cliques adicionais. Deseja mesmo fazer isso?';


        if (confirm(overClicksWarntxt)){
          
          const enableDisableOverClicks = functions.httpsCallable('enableDisableOverClicks');
          enableDisableOverClicks({allowOverClicks:document.getElementById('chkAllowOverClicks').checked})
          .then(result => {
           
           
            
            document.getElementById('lblAllowOverClicks').innerText=result.data.lblAllowOverClicks;
            
            if(result.data.allowOverClicks)
            document.getElementById('txtAllowOverClicks').innerText='Desativar cliques adicionais';
            else
            document.getElementById('txtAllowOverClicks').innerText='Ativar cliques adicionais';

            document.getElementById('chkAllowOverClicks').checked=result.data.allowOverClicks;
            
            modalAlert(result.data.message,result.data.alertType);




            

            
          })
          .catch(error => {
            modalAlert(error);

            console.log(error);
          });


        }else if(document.getElementById('chkAllowOverClicks').checked)
          document.getElementById('chkAllowOverClicks').checked=false;
          else
          document.getElementById('chkAllowOverClicks').checked=true;

      });
      */
		})
		.catch((error) => {
			modalAlert(error);

			console.log(error);
		});
}

//get pricing plans to sign OLD ONE

const plansCards = document.getElementById("plansCards");

if (plansCards) {
	const getPlans = functions.httpsCallable("getPlans");
	getPlans()
		.then((result) => {
			let j = 0; //
			let arrPlan = [];
			
			let plansHtml = "";
			let userCusID = "";
			result.data.forEach((doc) => {
				userCusID = doc.userCusID;

				arrPlan[j] = doc.price;
				plansHtml +=
					`
        <div class="col-12 col-lg-4">

            <!-- Card -->
            <div class="card">
              <div class="card-body">

                <!-- Title -->
                <h6 class="text-uppercase text-center text-muted my-4">
                  ` +
					doc.id +
					`
                </h6>

                <!-- Price -->
                <div class="row g-0 align-items-center justify-content-center">
                  <div class="col-auto">
                    <div class="h2 mb-0">R$</div>
                  </div>
                  <div class="col-auto">
                    <div class="display-2 mb-0">` +
					doc.price +
					`</div>
                  </div>
                </div> <!-- / .row -->

                <!-- Period -->
                <div class="h6 text-uppercase text-center text-muted mb-5">
                  / mês
                </div>

                <!-- Features -->
                <div class="mb-3">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Utilizar domínio próprio</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Rastreio facebook pixel</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Rastreio google analytics</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Ativar cliques avulsos</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Número de cliques/mês</small> <small class="numClicks"> ` +
					doc.clicks.toLocaleString("pt-br") +
					`</small>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Cliques adicionais avulsos</small> <small>R$ ` +
					String(doc.extraClick).replace(".", ",") +
					`</small>
                    </li>
                  </ul>
                </div>

                <!-- Button -->
                <button href="#!" class="btn btn-block btn-primary doSignPlan">
                  Assinar ` +
					doc.id +
					`
                </button>

              </div>
            </div>

          </div>
        
        
        `;
				j++;
			});
			plansCards.innerHTML = plansHtml;

			// Princing Plan click
			var doSignPlan = document.querySelectorAll(".doSignPlan");
			var numClicks = document.querySelectorAll(".numClicks");
			if (doSignPlan != null) {
				//alert('teste');

				for (var i = 0; i < doSignPlan.length; i++) {
					(function (i) {
						doSignPlan[i].onclick = function () {
							//modalAlert('linkType: ' + linkType[i].value + '/ Shorlink: ' + shortLinkID[i].value, "success");
							//alert('Element #' + i);
							if (
								confirm(
									"Deseja assinar o plano " +
										doSignPlan[i].textContent.trim().split(" ")[1] +
										"?"
								)
							) {
								const signPlan = functions.httpsCallable("signPlan");

								signPlan({
									value: arrPlan[i],
									clicks: numClicks[i].innerText.replace(".", ""),
									planDescription: doSignPlan[i].textContent
										.trim()
										.split(" ")[1],
									userCusID: userCusID,
								})
									.then((result) => {
										//modalAlert('Link Criado com Sucesso','success','links.html');
										
										modalAlert(
											result.data.message,
											result.data.alertType,
											"account-billing.html"
										);
									})
									.catch((error) => {
										modalAlert(error);
										console.log(error);
									});
								//modalAlert('Assinou plano '+doSignPlan[i].textContent.trim().split(" ")[1],'success');
							}
							//let jsarray = [linkType[i].value, shortLinkID[i].value];
							//sessionStorage.setItem("linkType", linkType[i].value);
							//sessionStorage.setItem("shortLinkID", shortLinkID[i].value);
						};
					})(i);
				}
			}
			//list options
			//availableClicks.innerText=result.data.availableClicks+' de '+result.data.totalClicks;
			//document.getElementById('clicksDateLimit').setAttribute('data-original-title','Seus cliques expiram em: '+toDateTime(result.data.limitDate['_seconds']).toLocaleString('pt-BR')) ;
		})
		.catch((error) => {
			modalAlert(error);

			console.log(error);
		});
}

//get pricing plans on page

const plansCardsHompage = document.getElementById("plansCardsHompage");

if (plansCardsHompage) {
	const getPlans = functions.httpsCallable("getPlans");
	getPlans({ fromHomePage: 1 })
		.then((result) => {
			let j = 0; //
			let arrPlan = [];
			
			let plansHtml = "";
			let userCusID = "";
			result.data.forEach((doc) => {
				userCusID = doc.userCusID;

				arrPlan[j] = doc.price;
				plansHtml +=
					`
        <div class="col-12 col-lg-4">

            <!-- Card -->
            <div class="card">
              <div class="card-body">

                <!-- Title -->
                <h6 class="text-uppercase text-center text-muted my-4">
                  ` +
					doc.id +
					`
                </h6>

                <!-- Price -->
                <div class="row g-0 align-items-center justify-content-center">
                  <div class="col-auto">
                    <div class="h2 mb-0">R$</div>
                  </div>
                  <div class="col-auto">
                    <div class="display-2 mb-0">` +
					doc.price +
					`</div>
                  </div>
                </div> <!-- / .row -->

                <!-- Period -->
                <div class="h6 text-uppercase text-center text-muted mb-5">
                  / mês
                </div>

                <!-- Features -->
                <div class="mb-3">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Utilizar domínio próprio</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Rastreio facebook pixel</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Rastreio google analytics</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Ativar cliques avulsos</small> <i class="fe fe-check-circle text-success"></i>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Número de cliques/mês</small> <small class="numClicks"> ` +
					doc.clicks.toLocaleString("pt-br") +
					`</small>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Cliques adicionais avulsos</small> <small>R$ ` +
					String(doc.extraClick).replace(".", ",") +
					`</small>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

          </div>
        
        
        `;
				j++;
			});
			plansCardsHompage.innerHTML = plansHtml;

			//list options
			//availableClicks.innerText=result.data.availableClicks+' de '+result.data.totalClicks;
			//document.getElementById('clicksDateLimit').setAttribute('data-original-title','Seus cliques expiram em: '+toDateTime(result.data.limitDate['_seconds']).toLocaleString('pt-BR')) ;
		})
		.catch((error) => {
			modalAlert(error);

			console.log(error);
		});
}

//NEW SUBSCRIBE PLAN ON HOTMART

const subscribePlanSelect = document.getElementById("subscribePlanSelect");

if (subscribePlanSelect) {
	auth.onAuthStateChanged(function (user) {
		if (user) {
			// User is signed in.
			// you can safely access the users properties
			var plansOptions = [];
			plansOptions[0] = [];
			plansOptions[0]["name"] = "Starter";
			plansOptions[0]["numClicks"] = 10000;
			plansOptions[0]["actLinks"] = "Até 5 links";
			plansOptions[0]["addDomains"] = "0";
			plansOptions[0]["price1"] = "59";
			plansOptions[0]["price6"] = "554";
			plansOptions[0]["price12"] = "348";
			plansOptions[0]["off1"] = "pfdio1ce";
			plansOptions[0]["off6"] = "5bsgpte6";
			plansOptions[0]["off12"] = "x2fyhj87";

			plansOptions[1] = [];
			plansOptions[1]["name"] = "Professional";
			plansOptions[1]["numClicks"] = 250000;
			plansOptions[1]["actLinks"] = "Até 15 links";
			plansOptions[1]["addDomains"] = "Até 5 domínios";
			plansOptions[1]["price1"] = "197";
			plansOptions[1]["price6"] = "1074";
			plansOptions[1]["price12"] = "1164";
			plansOptions[1]["off1"] = "a8oryihm";
			plansOptions[1]["off6"] = "s6kew8sq";
			plansOptions[1]["off12"] = "ar4vdhcg";

			plansOptions[2] = [];
			plansOptions[2]["name"] = "Enterprise";
			plansOptions[2]["numClicks"] = 500000;
			plansOptions[2]["actLinks"] = "<b>Ilimitados</b>";
			plansOptions[2]["addDomains"] = "<b>Ilimitados</b>";
			plansOptions[2]["price1"] = "497";
			plansOptions[2]["price6"] = "2394";
			plansOptions[2]["price12"] = "2964";
			plansOptions[2]["off1"] = "8vey96ou";
			plansOptions[2]["off6"] = "f5fbvwod";
			plansOptions[2]["off12"] = "me4e1bz2";

			let plansHtml = "";

			for (var i = 0; i < 3; i++) {
				plansHtml +=
					`
            <div class="col-12 col-lg-4">
    
                <!-- Card -->
                <div class="card">
                  <div class="card-body">
    
                    <!-- Title -->
                    <h6 class="text-uppercase text-center text-muted my-4" id="namePlan` +
					[i] +
					`">
                      ` +
					plansOptions[i]["name"] +
					`
                    </h6>
    
                    <!-- Price -->
                    <div class="row g-0 align-items-center justify-content-center">
                      <div class="col-auto">
                        <div class="h2 mb-0">R$</div>
                      </div>
                      <div class="col-auto">
                        <div class="display-2 mb-0" id="pricePlan` +
					[i] +
					`">` +
					plansOptions[i]["price1"] +
					`</div>
                      </div>
                    </div> <!-- / .row -->
    
                    <!-- Period -->
                    <div class="h6 text-uppercase text-center text-muted mb-5" id="periodPlan` +
					[i] +
					`">
                      /mês
                    </div>

					<!-- Monthly value -->
                    <div class="text-center mb-5" style="display:none" id="showMonthlyVale` +
					[i] +
					`">
					<small><b>(Equivalente à R$ `+roundTo(plansOptions[i]["price12"]/12,2)+`/mês)</b></small>
                    </div>
    
                    <!-- Features -->
                    <div class="mb-3">
                      
                      <ul class="list-group list-group-flush">
                      <!-- 
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Utilizar domínio próprio</small> <i class="fe fe-check-circle text-success"></i>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                        <small>Rastreio facebook pixel</small> <i class="fe fe-check-circle text-success"></i>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                        <small>Rastreio google analytics</small> <i class="fe fe-check-circle text-success"></i>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Contratar cliques avulsos</small> <i class="fe fe-check-circle text-success"></i>
                        </li>
                      -->
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Número de cliques/mês</small> <small class="numClicks"> ` +
					plansOptions[i]["numClicks"].toLocaleString("pt-br") +
					`</small>
                        </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                        <small>Número de links ativos</small> <small class="numClicks"> ` +
					plansOptions[i]["actLinks"] +
					`</small>
                      </li>
                      <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                      <small>Uso de domínio próprio</small> <small class="numClicks"> ` +
					plansOptions[i]["addDomains"] +
					`</small>
                    </li>
                        <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Contratar cliques adicionais</small> <small><i class="fe fe-check-circle text-success"></i></small>
                        </li>
                      </ul>
                    </div>
                    <!-- Button -->
                    <a href="https://pay.hotmart.com/W54792545L?off=` +
					plansOptions[i]["off1"] +
					`&checkoutMode=10&bid=1623777460085&email=` +
					(sessionStorage.getItem("viewAsUsr") || user.email) +
					`" class="btn w-100 btn-primary" id="btnSignPlan` +
					[i] +
					`">
                      Assinar ` +
					plansOptions[i]["name"] +
					` Mensal
                    </a>
    
                  </div>
                </div>
    
              </div>
    
            
            `;
			}
			subscribePlanSelect.innerHTML = plansHtml;

			document.getElementById("monthlyPlan").addEventListener("click", (e) => {
				e.preventDefault();
				for (i = 0; i < 3; i++) {
					document.getElementById("showMonthlyVale" + i).style.display ="none";
					document.getElementById("pricePlan" + i).innerText =
						plansOptions[i]["price1"];
					document.getElementById("periodPlan" + i).innerText = "/mês";
					document.getElementById("btnSignPlan" + i).innerText =
						"Assinar " + plansOptions[i]["name"] + " Mensal";
					document.getElementById("btnSignPlan" + i).href =
						"https://pay.hotmart.com/W54792545L?off=" +
						plansOptions[i]["off1"] +
						"&checkoutMode=10&bid=1623777460085&email=" +
						userLoggedEmail;
				}
				document.getElementById("monthlyPlan").classList.remove("btn-primary");
				document
					.getElementById("monthlyPlan")
					.classList.remove("btn-secondary");

				// document.getElementById('semiannuallyPlan').classList.remove("btn-primary");
				// document.getElementById('semiannuallyPlan').classList.remove("btn-secondary");

				document.getElementById("annuallyPlan").classList.remove("btn-primary");
				document
					.getElementById("annuallyPlan")
					.classList.remove("btn-secondary");

				document.getElementById("monthlyPlan").classList.add("btn-primary");
				//document.getElementById('semiannuallyPlan').classList.add("btn-secondary");
				document.getElementById("annuallyPlan").classList.add("btn-secondary");
			});

			// document.getElementById('semiannuallyPlan').addEventListener('click', (e) => {
			//   e.preventDefault();
			//   for(i=0;i<3;i++){
			//   document.getElementById('pricePlan'+i).innerText=plansOptions[i]['price6'];
			//   document.getElementById('periodPlan'+i).innerText='/semestre';
			//   document.getElementById('btnSignPlan'+i).innerText='Assinar '+plansOptions[i]['name']+'  Semestral';
			//   document.getElementById('btnSignPlan'+i).href='https://pay.hotmart.com/W54792545L?off='+plansOptions[i]['off6']+'&checkoutMode=10&bid=1623777460085&email='+userLoggedEmail;
			//   }
			//   document.getElementById('monthlyPlan').classList.remove("btn-primary");
			//   document.getElementById('monthlyPlan').classList.remove("btn-secondary");

			//   document.getElementById('semiannuallyPlan').classList.remove("btn-primary");
			//   document.getElementById('semiannuallyPlan').classList.remove("btn-secondary");

			//   document.getElementById('annuallyPlan').classList.remove("btn-primary");
			//   document.getElementById('annuallyPlan').classList.remove("btn-secondary");

			//   document.getElementById('monthlyPlan').classList.add("btn-secondary");
			//   document.getElementById('semiannuallyPlan').classList.add("btn-primary");
			//   document.getElementById('annuallyPlan').classList.add("btn-secondary");

			// });

			document.getElementById("annuallyPlan").addEventListener("click", (e) => {
				e.preventDefault();
				for (i = 0; i < 3; i++) {
					document.getElementById("showMonthlyVale" + i).style.display ="";
					document.getElementById("pricePlan" + i).innerText =
						plansOptions[i]["price12"];
					document.getElementById("periodPlan" + i).innerText = "/ano";
					document.getElementById("btnSignPlan" + i).innerText =
						"Assinar " + plansOptions[i]["name"] + "  Anual";
					document.getElementById("btnSignPlan" + i).href =
						"https://pay.hotmart.com/W54792545L?off=" +
						plansOptions[i]["off12"] +
						"&checkoutMode=10&bid=1623777460085&email=" +
						userLoggedEmail;
				}
				document.getElementById("monthlyPlan").classList.remove("btn-primary");
				document
					.getElementById("monthlyPlan")
					.classList.remove("btn-secondary");

				// document.getElementById('semiannuallyPlan').classList.remove("btn-primary");
				// document.getElementById('semiannuallyPlan').classList.remove("btn-secondary");

				document.getElementById("annuallyPlan").classList.remove("btn-primary");
				document
					.getElementById("annuallyPlan")
					.classList.remove("btn-secondary");

				document.getElementById("monthlyPlan").classList.add("btn-secondary");
				//document.getElementById('semiannuallyPlan').classList.add("btn-secondary");
				document.getElementById("annuallyPlan").classList.add("btn-primary");
			});

			//list options
			//availableClicks.innerText=result.data.availableClicks+' de '+result.data.totalClicks;
			//document.getElementById('clicksDateLimit').setAttribute('data-original-title','Seus cliques expiram em: '+toDateTime(result.data.limitDate['_seconds']).toLocaleString('pt-BR')) ;
		} else {
			// No user is signed in.

			top.location.href = "login.html";
		}
	});
}

//ADDITIONAL CLICKS SELECT

const addClicksSelect = document.getElementById("addClicksSelect");

if (addClicksSelect) {
	auth.onAuthStateChanged(function (user) {
		if (user) {
			// User is signed in.
			// you can safely access the users properties

			// const getUserActivePlan = functions.httpsCallable("getUserActivePlan");
			// getUserActivePlan({ userEmail: sessionStorage.getItem("viewAsUsr") })
			firebaseService.callFirebaseFunction("getUserActivePlan", {
				userEmail: sessionStorage.getItem("viewAsUsr"),
			}).then((result) => {
					if (result.data != "vazio") {
						var plansOptions = [];
						plansOptions[0] = [];
						plansOptions[0]["name"] = "Clique Extra - 5000";
						plansOptions[0]["numClicks"] = 5000;
						plansOptions[0]["price"] = "125";
						plansOptions[0]["off"] = "ckh40st0";

						plansOptions[1] = [];
						plansOptions[1]["name"] = "Clique Extra - 10000";
						plansOptions[1]["numClicks"] = 10000;
						plansOptions[1]["price"] = "250";
						plansOptions[1]["off"] = "ey22m3s4";

						plansOptions[2] = [];
						plansOptions[2]["name"] = "Clique Extra - 25000";
						plansOptions[2]["numClicks"] = 25000;
						plansOptions[2]["price"] = "625";
						plansOptions[2]["off"] = "kgisxkgx";

						plansOptions[3] = [];
						plansOptions[3]["name"] = "Clique Extra - 50000";
						plansOptions[3]["numClicks"] = 50000;
						plansOptions[3]["price"] = "1200";
						plansOptions[3]["off"] = "vmbmgfn3";

						plansOptions[4] = [];
						plansOptions[4]["name"] = "Clique Extra - 100000";
						plansOptions[4]["numClicks"] = 100000;
						plansOptions[4]["price"] = "2200";
						plansOptions[4]["off"] = "bq9fzkeq";

						plansOptions[5] = [];
						plansOptions[5]["name"] = "Clique Extra - 250000";
						plansOptions[5]["numClicks"] = 250000;
						plansOptions[5]["price"] = "5000";
						plansOptions[5]["off"] = "f9r0bux3";

						plansOptions[6] = [];
						plansOptions[6]["name"] = "Clique Extra - 500000";
						plansOptions[6]["numClicks"] = 500000;
						plansOptions[6]["price"] = "9000";
						plansOptions[6]["off"] = "a1fyb3lv";

						plansOptions[7] = [];
						plansOptions[7]["name"] = "Clique Extra - 1000000";
						plansOptions[7]["numClicks"] = 1000000;
						plansOptions[7]["price"] = "16000";
						plansOptions[7]["off"] = "53u4rsj6";

						let plansHtml = "";

						for (var i = 0; i < 8; i++) {
							plansHtml +=
								`
                <div class="col-12 col-lg-4">
        
                    <!-- Card -->
                    <div class="card">
                      <div class="card-body">
        
                        <!-- Title -->
                        <h6 class="text-uppercase text-center text-muted my-4" id="namePlan` +
								[i] +
								`">
                          ` +
								plansOptions[i]["name"] +
								`
                        </h6>
        
                        <!-- Price -->
                        <div class="row g-0 align-items-center justify-content-center">
                          <div class="col-auto">
                            <div class="h2 mb-0">R$</div>
                          </div>
                          <div class="col-auto">
                            <div class="display-2 mb-0" id="pricePlan` +
								[i] +
								`">` +
								plansOptions[i]["price"] +
								`</div>
                          </div>
                        </div> <!-- / .row -->
        
                        <!-- Period -->
                        <div class="h6 text-uppercase text-center text-muted mb-5" id="periodPlan` +
								[i] +
								`">
    
                        </div>
        
                        <!-- Features -->
                        <div class="mb-3">
                          
                          <ul class="list-group list-group-flush">
                          <!-- 
                            <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                              <small>Utilizar domínio próprio</small> <i class="fe fe-check-circle text-success"></i>
                            </li>
                            <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                            <small>Rastreio facebook pixel</small> <i class="fe fe-check-circle text-success"></i>
                            </li>
                            <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                            <small>Rastreio google analytics</small> <i class="fe fe-check-circle text-success"></i>
                            </li>
                            <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                              <small>Contratar cliques avulsos</small> <i class="fe fe-check-circle text-success"></i>
                            </li>
                          -->
                            <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                              <small>Válido por 30 dias</small> <i class="fe fe-check-circle text-success"></i>
                            </li>
                            <li class="list-group-item d-flex align-items-center justify-content-between px-0">
                              <small>Número de cliques adcionais</small> <small class="numClicks"> ` +
								plansOptions[i]["numClicks"].toLocaleString("pt-BR") +
								`</small>
                            </li>
                          </ul>
                        </div>
                        <!-- Button -->
                        <a href="https://pay.hotmart.com/W54792545L?off=` +
								plansOptions[i]["off"] +
								`&checkoutMode=10&bid=1623777460085&email=` +
								(sessionStorage.getItem("viewAsUsr") || user.email) +
								`" class="btn w-100 btn-primary" id="btnSignPlan` +
								[i] +
								`">
                          Contratar ` +
								plansOptions[i]["name"] +
								`
                        </a>
        
                      </div>
                    </div>
        
                  </div>
        
                
                `;
						}
						addClicksSelect.innerHTML = plansHtml;
					} else {
						modalAlert(
							"Somente usuários com assinatura podem contratar cliques adicionais.",
							"alert",
							"subscription-general.html"
						);
						//top.location.href='subscription-general.html';
						/*document.getElementById('btnSelectPlan').addEventListener('click', (e) => {
            if (confirm('Ao Selecionar um novo plano seu plano anterior será cancelado. Deseja Continuar?')){
              top.location.href='pricing.html'
            }
                  
      
          });*/
					}
				})
				.catch((error) => {
					modalAlert(error);

					console.log(error);
				});

			//list options
			//availableClicks.innerText=result.data.availableClicks+' de '+result.data.totalClicks;
			//document.getElementById('clicksDateLimit').setAttribute('data-original-title','Seus cliques expiram em: '+toDateTime(result.data.limitDate['_seconds']).toLocaleString('pt-BR')) ;
		} else {
			// No user is signed in.

			top.location.href = "login.html";
		}
	});
}

const eyeToggle = document.getElementById("eyeToggle");
if (eyeToggle) {
	eyeToggle.addEventListener("click", (e) => {
		e.preventDefault();
		var x = document.getElementById("login-password");
		var y = eyeToggle;
		if (x.type === "password") {
			x.type = "text";
			y.innerHTML = '<i class="fe fe-eye"></i>';
		} else {
			x.type = "password";
			y.innerHTML = '<i class="fe fe-eye-off"></i>';
		}
	});
}
//enable or disable links

const fnlinkActive = function (activeStatus = null, shortLink = null) {
	if (activeStatus != null) {
		const collectionName = db.collection("links");
		const linkAct = {
			active: activeStatus,
		};

		db.collection("links")
			.where("shortLink", "==", shortLink)
			.get()
			.then(
				(snapshot) => {
					let batch = db.batch();
					snapshot.docs.forEach((doc) => {
						const docRef = db.collection("links").doc(doc.id);
						batch.update(docRef, linkAct);
					});
					batch.commit().then(() => {
						modalAlert("Status do Link alterado com sucesso", "success");
						top.location.href = "links.html";
						//
					});
				},
				(err) => console.log(err.message)
			);
	}
};

//fnlinkActive();

//futuro ativar/desativarl links

// DOM elements

//linkDomain='http://192.168.1.12:5001/deeplink-b3800/us-central1/api/'; //dev test
const linkList = document.querySelector(".links");



// listen for auth status changes
auth.onAuthStateChanged((user) => {
	
	if (user) {

		

		
		//redirect if the page is login.html or sign-up.html
		userLoggedEmail = auth.currentUser.email;

		checkQueryString();
			

		user.getIdTokenResult().then((idTokenResult) => {


			

			//check ownDomainLink
			if (
				window.location.pathname != "/" &&
				window.location.pathname != "/index.html" &&
				window.location.pathname != "/privacy.html" &&
				window.location.pathname != "/terms.html" &&
				window.location.pathname != "/sign-up.html" 
			) {

				firebaseService.callFirebaseFunction("checkUseDomains", {
					userEmail: sessionStorage.getItem("viewAsUsr"),
				})
					.then((result) => {
						//var showHiddenStr=;
						//linkList.innerHTML = `Aguarde...`;
						

						if (result.data.numDomains > 0) {
							document
								.getElementById("btnOwnDomains")
								.classList.remove("text-muted");
							document.getElementById("btnOwnDomains").href = "domains.html";
						} else {
							document
								.getElementById("btnOwnDomains")
								.addEventListener("click", (e) => {
									modalMsg(
										"danger.svg",
										"Domínios Próprios",
										"Você não tem permissão para cadastrar domínios próprios",
										"Ver Opções de Planos",
										"btn-success"
									);
									btnModalListener = (e) => {
										e.preventDefault();
										top.location.href = "subscribe.html";
									};
									document
										.getElementById("modalInfo" + "btn")
										.addEventListener("click", btnModalListener, true);
								});
						}

						//top.location.href="domains.html"
					})
					.catch((error) => {
						modalAlert(error.message);

						console.log(error);
						//document.getElementById('btnOwnDomains').innerHTML="Criar Link";
					});
			}

			
			


			//disable criar links if email is not verfired
			if (!user.emailVerified && document.getElementById("newLink01")) {
				document.getElementById("newLink01").href = "#";
				//document.getElementById("newLink02").href="#";
				document.getElementById("alertEmailConfirm").style.display = "block";
				document.getElementById("alertUserEmail").innerText = userLoggedEmail;

				document.getElementById("newLink01").addEventListener("click", (e) => {
					e.preventDefault();

					modalMsg(
						"email.svg",
						"Confirme seu email",
						"Para criar novos links é necesssário confirmar o seu email de acesso.",
						"Reenviar Email",
						"btn-outline-secondary"
					);

					btnModalListener = (e) => {
						e.preventDefault();
						fnSendVerifyEmail(
							auth.currentUser.email,
							auth.currentUser.uid,
							"modalInfo" + "btn"
						);
					};
					document
						.getElementById("modalInfo" + "btn")
						.addEventListener("click", btnModalListener, true);
				});
			} else if (document.getElementById("newLink01")) {
				document.getElementById("newLink01").addEventListener("click", (e) => {
					const checkCreateLinkfn = functions.httpsCallable("checkCreateLink");
					document.getElementById("newLink01").innerHTML = `
            <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Aguarde...</span>
            </div>Aguarde...`;

					checkCreateLinkfn({
						userEmail: sessionStorage.getItem("viewAsUsr"),
					})
						.then((result) => {
							//var showHiddenStr=;
							//linkList.innerHTML = `Aguarde...`;
							

							top.location.href = "link-new.html";
						})
						.catch((error) => {
							//modalAlert(error.message);
							modalMsg(
								"danger.svg",
								"Criar Link",
								error.message,
								"Ver Opções de Planos",
								"btn-success"
							);
							btnModalListener = (e) => {
								e.preventDefault();
								top.location.href = "subscribe.html";
							};
							document
								.getElementById("modalInfo" + "btn")
								.addEventListener("click", btnModalListener, true);
							console.log(error);
							document.getElementById("newLink01").innerHTML = "Criar Link";
						});
				});
			}

			user.admin = idTokenResult.claims.admin;
			setupUI(user);

			//verifica se tem plano ou é admin. senão abre um alerta sugerindo assinatura
			checkUserPlan(user.admin,modalAlert);

			if (document.getElementById("btnShowHidden")) {
				document
					.getElementById("btnShowHidden")
					.addEventListener("click", (e) => {
						var showHidden =
							document
								.getElementById("btnShowHidden")
								.getAttribute("data-hidden") === "true";
						//const getLinksByUser = functions.httpsCallable("getLinksByUser");
						document.getElementById("btnShowHidden").innerHTML = `
            <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Aguarde...</span>
            </div>Aguarde...`;
							firebaseService.callFirebaseFunction("getLinksByUser", {
								userEmail: sessionStorage.getItem("viewAsUsr"),
								showHidden: showHidden,
							})
							.then((result) => {
								//var showHiddenStr=;
								//linkList.innerHTML = `Aguarde...`;
								
								setupLinks(result.data, showHidden);
								if (showHidden) {
									document
										.getElementById("btnShowHidden")
										.classList.remove("btn-outline-danger");
									document
										.getElementById("btnShowHidden")
										.classList.add("btn-outline-primary");
									document.getElementById("btnShowHidden").innerHTML =
										'<i class="fas fa-eye"></i> Exibir Links Visíveis';
								} else {
									document
										.getElementById("btnShowHidden")
										.classList.remove("btn-outline-primary");
									document
										.getElementById("btnShowHidden")
										.classList.add("btn-outline-danger");
									document.getElementById("btnShowHidden").innerHTML =
										'<i class="fas fa-eye-slash"></i> Exibir Links Ocultos';
								}
								document
									.getElementById("btnShowHidden")
									.setAttribute("data-hidden", (!showHidden).toString());
							})
							.catch((error) => {
								modalAlert(error.message);
								console.log(error);
							});
					});
			}

			if (document.getElementById("userEmail")) {
				const userEmail = document.getElementById("userEmail");
				let userEmailTxt = "";
				if (sessionStorage.getItem("viewAsUsr")) {
					userEmailTxt =
						sessionStorage.getItem("viewAsUsr") +
						' <b class="text-danger">(as admin)</b> <i class="fas fa-caret-down"></i>';
				} else if (!auth.currentUser.emailVerified)
					userEmailTxt =
						'<span class="text-danger">' +
						auth.currentUser.email +
						' (Não verificado) <i class="fas fa-caret-down"></i></span>';
				else
					userEmailTxt =
						auth.currentUser.email + ' <i class="fas fa-caret-down"></i>';

				if (document.getElementById("userEmail") && userEmail != null);
				document.getElementById("userEmail").innerHTML = userEmailTxt;
			}

			// last 1000 clicks

			//getCnameByUser({ userEmail: sessionStorage.getItem("viewAsUsr") })
				firebaseService.callFirebaseFunction("getCnameByUser", {
					userEmail: sessionStorage.getItem("viewAsUsr"),
				})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					//console.log(result.data.length);
					//setupLastClicks(result.data);
					if (result.data.length) {
						result.data.forEach((domanQr) => {
							if (domanQr.isSet) linkDomain = "https://" + domanQr.domain + "/";
						});
					}

					//linkDomain='http://192.168.1.12:5001/deeplink-b3800/us-central1/api/'; //when using dev
					//session vars to get link details
					let linkTypeSession = "";
					let shortLinkID = "";

					//pages with this id to trigger somes scripts
					const linksPages = document.getElementById("linksPages");

					//alert('passou aqui')

					if (linksPages) {
						linkTypeSession = sessionStorage.getItem("linkType");
						shortLinkID = sessionStorage.getItem("shortLinkID");
						if (document.getElementById("dashTitle")) {
							if (window.location.pathname == "/link-routes.html")
								document.getElementById("dashTitle").innerHTML =
									'<a href="' +
									linkDomain +
									linkTypeSession +
									"/" +
									shortLinkID +
									'" target="_blank">' +
									linkDomain +
									linkTypeSession +
									"/" +
									shortLinkID +
									'</a> &nbsp; <a href="#" ><i class="fas fa-clipboard" id="dashClpLNk" data-bs-toggle="tooltip" title="Copiar Link"></i></a> &nbsp;';
							else
								document.getElementById("dashTitle").innerHTML =
									'<a href="' +
									linkDomain +
									linkTypeSession +
									"/" +
									shortLinkID +
									'" target="_blank">' +
									linkDomain +
									linkTypeSession +
									"/" +
									shortLinkID +
									'</a> &nbsp; <a href="#" ><i class="fas fa-clipboard" id="dashClpLNk" data-bs-toggle="tooltip" title="Copiar Link"></i></a> &nbsp; <a class="small text-primary" href="link-routes.html">Editar Link</a>';

							var tooltip = new bootstrap.Tooltip(
								document.getElementById("dashClpLNk")
							);
							document
								.getElementById("dashClpLNk")
								.addEventListener("click", (e) => {
									e.preventDefault();

									//modalAlert("Função disponível em breve", "warning");
									navigator.clipboard.writeText(
										linkDomain + linkTypeSession + "/" + shortLinkID
									);
									document
										.getElementById("dashClpLNk")
										.setAttribute("data-bs-original-title", "Link Copiado");

									tooltip.show();

									// reset the tooltip title

									document
										.getElementById("dashClpLNk")
										.setAttribute("data-bs-original-title", "Copiar Link");
								});
						}
					}
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}); //.orderBy('shortLink', 'asc')

		if (document.getElementById("TbLinksList")) {
			//const getLinksByUser = functions.httpsCallable("getLinksByUser");
			firebaseService.callFirebaseFunction("getLinksByUser", {
				userEmail: sessionStorage.getItem("viewAsUsr"),
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					
					//setupLastClicks(result.data);
					if (linkList != null) {
						//linkList.innerHTML = `Aguarde...`;
						setupLinks(result.data);

						//total clicks of one user
						const totalClickshtml = document.getElementById("totalClicks");

						if (totalClickshtml) {
							auth.onAuthStateChanged(function (user) {
								if (user) {
									// User is signed in.
									// you can safely access the users properties

									//alert(firebase.auth().currentUser.email);
									let appClicks = 0;
									let totalClicks = 0;
									let iOSClicks = 0;
									let androidClicks = 0;
									let windowsClicks = 0;
									let macOSClicks = 0;
									let linuxClicks = 0;
									let otherOSClicks = 0;

									result.data.forEach((doc) => {
										appClicks += doc.appClicks || 0;
										totalClicks += doc.totalClicks || 0;
										iOSClicks += doc.iOSClicks || 0;
										windowsClicks += doc.windowsClicks || 0;
										macOSClicks += doc.macOSClicks || 0;
										linuxClicks += doc.linuxClicks || 0;
										androidClicks += doc.androidClicks || 0;
										otherOSClicks += doc.otherOSClicks || 0;
									});
									totalClickshtml.innerHTML = totalClicks;
									document.getElementById("appClicks").innerHTML = appClicks;
									document.getElementById("httpClicks").innerHTML =
										totalClicks - appClicks;
									document.getElementById("iOSClicks").innerHTML = iOSClicks;
									document.getElementById("androidClicks").innerHTML =
										androidClicks;
									document.getElementById("windowsClicks").innerHTML =
										windowsClicks;
									document.getElementById("macOSClicks").innerHTML =
										macOSClicks;
									document.getElementById("linuxClicks").innerHTML =
										linuxClicks;
									document.getElementById("otherOSClicks").innerHTML =
										otherOSClicks;
								} else {
									// No user is signed in.
									if (window.location.pathname != "/confirm-email-success.html")
										top.location.href = "login.html";
								}
							});
						}
					} // End link list
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}

		//modal to complete information who sign up with google
		if (document.getElementById("modalUserDetails")) {
			var modalUserDetails = new bootstrap.Modal(
				document.getElementById("modalUserDetails"),
				""
			);

				firebaseService.callFirebaseFunction("getUserDetails", {
					email: sessionStorage.getItem("viewAsUsr"),
				})
				.then((result) => {
					//var showHiddenStr=;
					//linkList.innerHTML = `Aguarde...`;
					

					if (
						result.data.userPhoneNumber &&
						result.data.userPhoneNumber != ""
					) {
						
					} else {
						// doc.data() will be undefined in this case
						if (!sessionStorage.getItem("modalUserDetails")) {
							modalUserDetails.toggle();
							sessionStorage.setItem("modalUserDetails", "true");
						}
						
					}

					//top.location.href="domains.html"
				})
				.catch((error) => {
					modalAlert(error.message);

					console.log(error);
					//document.getElementById('btnOwnDomains').innerHTML="Criar Link";
				});

			// user details form
			const userDetailsForm = document.querySelector("#userDetails-form");

			if (userDetailsForm) {
				userDetailsForm.addEventListener("submit", (e) => {
					e.preventDefault();

					
					// get user info
					const phoneNumber = userDetailsForm["userDet-phoneNumber"];
					const onlyNumbers = phoneNumber.value.replace(/\D/g, "");
					phoneNumber.classList.remove("is-invalid");
					//(onlyNumbers.length);

					if (onlyNumbers.length < 11)
						phoneNumber.classList.toggle("is-invalid");
					//modalAlert('Valor digitar o telefone completo','warning');
					else {
						// sign up the user & add firestore data

						document.getElementById("btnUserDet").innerHTML = `
                      <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Aguarde...</span>
                      </div>Aguarde...`;

						const addUserDetailsNew = functions.httpsCallable("addUserDetails");

						addUserDetailsNew({
							userPhoneNumber: phoneNumber.value,
						})
							.then((result) => {
								
								//gtag_report_signup_conversion();
								modalUserDetails.toggle();
								modalMsg(
									"success.svg",
									"Cadastro atualizado",
									"Obrigado por preencher as informações adicionais."
								);
							})
							.catch((err) => {
								document.getElementById("btnUserDet").innerHTML = "Confirmar";
								//modalUserDetails.toggle();
								//modalAlert(err.message);
								console.log(err);
							});
					}
				});
			}
		}

		//get All Main Linktypes

		if (document.getElementById("modalLinkTypes")) {
			const getMainLinkTypes = functions.httpsCallable("getMainLinkTypes");

			getMainLinkTypes()
				.then((result) => {
					let linkTypeDiv = "",
						linkTypesbyCatDiv = "";
					let liLinkTypes = `                    
    <li class="nav-item">
    <a href="#" id="allLinkTypes" class="nav-link text-nowrap active">
      Todos
    </a>
    </li>`;
					document
						.getElementById("liLinkTypes")
						.insertAdjacentHTML("beforeend", liLinkTypes);

					document
						.getElementById("allLinkTypes")
						.addEventListener("click", (e) => {
							var linkByCatNav = document.querySelectorAll(".linkByCatNav");
							var linkByCatClass = document.querySelectorAll(".linkByCatClass");
							//alert('teste');

							for (var i = 0; i < linkByCatClass.length; i++) {
								(function (i) {
									linkByCatClass[i].style.display = "none";
									linkByCatNav[i].classList.remove("active");
								})(i);
							}
							//document.getElementById(category).style.display='';
							document.getElementById("mainLinkTypesDiv").style.display = "";
							document.getElementById("allLinkTypes").classList.add("active");
						});
					let arrCategories = [];
					//modalAlert('Link Criado com Sucesso','success','links.html');
					
					result.data.forEach((doc) => {
						linkTypeDiv =
							`
      <div class="col mt-3">
      <a href="#" id="btnAll` +
							doc.id +
							`">
        <div class="avatar avatar-sm">
          <img src="/assets/img/icons/` +
							doc.media +
							`.png" class="avatar-img" />
        </div>
        <br>
         ` +
							doc.description.split(" - ")[0] +
							`
      </a>
      </div>
              
    `;

						//document.getElementById('mainLinkTypesDiv').innerHTML=linkTypeDiv;
						document
							.getElementById("mainLinkTypesDiv")
							.insertAdjacentHTML("beforeend", linkTypeDiv);

						document
							.getElementById("btnAll" + doc.id)
							.addEventListener("click", (e) => {
								openLinkWiz(doc.description.split(" - ")[0], "btnAll", true);
							});

						//openLinkWiz(doc.media,'btnAll');

						arrCategories.push(doc.category);
					});
					//mainLinkTypesDiv

					let uniqueCategories = [...new Set(arrCategories)];

					uniqueCategories.forEach((category) => {
						liLinkTypes =
							`
      <li class="nav-item">
        <a href="#" class="nav-link text-nowrap linkByCatNav" id="btn` +
							category +
							`">
          ` +
							category +
							`
        </a>
      </li>
              
    `;

						document
							.getElementById("liLinkTypes")
							.insertAdjacentHTML("beforeend", liLinkTypes);

						document
							.getElementById("btn" + category)
							.addEventListener("click", (e) => {
								var linkByCatNav = document.querySelectorAll(".linkByCatNav");
								var linkByCatClass =
									document.querySelectorAll(".linkByCatClass");
								//alert('teste');

								for (var i = 0; i < linkByCatClass.length; i++) {
									(function (i) {
										linkByCatClass[i].style.display = "none";
										linkByCatNav[i].classList.remove("active");
									})(i);
								}
								document.getElementById(category).style.display = "";
								document.getElementById("mainLinkTypesDiv").style.display =
									"none";
								document
									.getElementById("allLinkTypes")
									.classList.remove("active");
								document
									.getElementById("btn" + category)
									.classList.add("active");
							});

						const linkTypesByCat = result.data.filter(function (element) {
							return element.category == category;
						});

						linkTypesbyCatDiv =
							`<div class="row mt-5 row-cols-4 row-cols-lg-6  text-center w-100 linkByCatClass" id="` +
							category +
							`" style="display:none"></div>`;
						document
							.getElementById("linkTypByCat")
							.insertAdjacentHTML("beforeend", linkTypesbyCatDiv);

						linkTypesByCat.forEach((linkTyp) => {
							linkTypesbyCatDiv =
								`
        <div class="col mt-3">
        <a href="#" id="btnCat` +
								category +
								linkTyp.media +
								`">
          <div class="avatar avatar-sm">
            <img src="/assets/img/icons/` +
								linkTyp.media +
								`.png" class="avatar-img" />
          </div>
          <br>
          ` +
								linkTyp.description.split(" - ")[0] +
								`
        </a>
        </div>
                
      `;
							document
								.getElementById(category)
								.insertAdjacentHTML("beforeend", linkTypesbyCatDiv);
							document
								.getElementById("btnCat" + category + linkTyp.media)
								.addEventListener("click", (e) => {
									openLinkWiz(
										linkTyp.description.split(" - ")[0],
										"btnCat" + category,
										true
									);
								});
							//console.log(linkTypesByCat);
						});
						//document.getElementById('linkTypByCat').insertAdjacentHTML('beforeend', linkTypesbyCatDiv);
					});

					//document.getElementById('mainLinkTypesDiv').innerHTML=linkTypeDiv;
					//document.getElementById('liLinkTypes').innerHTML=liLinkTypes;
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
				});
		}

		// main linktypes create wizard

		if (document.getElementById("modalCreateLink")) {
			var MostUsed = [];
			MostUsed.push(
				"Site",
				"Ifood",
				"Instagram",
				"Rappi",
				"Spotify",
				"Twitch",
				"Whatsapp",
				"Youtube"
			);

			for (let j = 0; j < MostUsed.length; j++) {
				document
					.getElementById("btnLnkCrt" + MostUsed[j])
					.addEventListener("click", (e) => {
						openLinkWiz(MostUsed[j]);
					});
			}
		}

		//function atrib link create wizard

		function openLinkWiz(
			BaseLnkTyp,
			baseBtnName = "btnWizMedia",
			modalToogle = false
		) {
			//console.log(BaseLnkTyp.toLowerCase());
			document.getElementById("httpDivFromWiz").innerHTML =
				"Selecione Primeiro um formato do link";
			getLinkTypesByBaseDesc(BaseLnkTyp).then(
				(snapshot) => {
					//const Linkmedia = snapshot.docs[0].data();

					//let btnsLnkTyp='';

					document.getElementById("btnLnkTypMedia").innerHTML = "";
					document.getElementById("createLinkWiz").disabled = true;

					snapshot.docs.forEach((doc) => {
						document
							.getElementById("btnLnkTypMedia")
							.insertAdjacentHTML(
								"beforeend",
								'<button type="button" id="btnWizMedia' +
									doc.id +
									'" class="btn btn-outline-dark">' +
									doc.data().description.split(" - ")[1] +
									"</button>&nbsp;&nbsp;&nbsp;"
							);

						document
							.getElementById("btnWizMedia" + doc.id)
							.addEventListener("click", (e) => {
								let linkInputType = '<div class="row">';
								const linkArr = doc.data().fallback.split("uLinkId");

								linkInputType +=
									'<div class="col-auto">' + linkArr[0] + "</div>";
								linkInputType +=
									'<div class="col-auto"><input type="text" class="form-control" id="httpLinkFromWiz" required="required" placeholder="id"></div>';

								linkInputType +=
									'<input type="hidden" id="httpFallbackFromWiz" required="required" value="' +
									doc.data().fallback +
									'">';

								if (linkArr.length == 2)
									linkInputType +=
										'<div class="col-auto">' + linkArr[1] + "</div>";

								linkInputType += "</div>";

								document.getElementById("httpDivFromWiz").innerHTML =
									linkInputType;

								document.getElementById("createLinkWiz").disabled = false;

								//document.getElementById('httpLinkFromWiz').setAttribute('placeholder',doc.data().description.split(' - ')[1]+'-id');
							});
					});

					//console.log(btnsLnkTyp);
					document.getElementById("mainLnkTypTit").innerHTML = BaseLnkTyp;

					//document.getElementById('btnLnkTypMedia').innerHTML = btnsLnkTyp;

					//enableLinktypeClick();

					if (modalToogle) {
						modalLinkTypes.toggle();
						document.getElementById("modalBackBtn").style.display = "";
					} else {
						document.getElementById("modalBackBtn").style.display = "none";
					}

					modalCreateLink.toggle();
				},
				(err) => console.log(err.message)
			);
		}

		if (document.getElementById("linkTypeList")) {
			// get last 10 clicks and fill table in link details

				getLinkTypes()
				.then(
					(snapshot) => {
					setupLinkTypes(snapshot);
				});
		}

		//test if user has subscrtion

		if (document.getElementById("linkPagesMenu")) {
			const checkUserSubscription = functions.httpsCallable(
				"checkUserSubscription"
			);

			checkUserSubscription()
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					console.log(result);
					//setupLastClicks(result.data);
					if (result.data.alertType == "success")
						document.getElementById("urlShortLink").href =
							"link-urlshorts.html";
					else {
						document
							.getElementById("urlShortLink")
							.addEventListener("click", (e) => {
								modalAlert(
									"Somente usuários assinantes podem criar encurtadores de link",
									"warning"
								);
							});
					}
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});

			if (document.getElementsByName("shorUrlID")[0])
				document.getElementsByName("shorUrlID")[0].value = makeid(7);
		}

		if (document.getElementById("totalClicksinSummary")) {
			const getLinkDetails = functions.httpsCallable("getLinkDetails");

			getLinkDetails({
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					
					const linkClicks = result.data;

					// if (linkClicks.linkType == "site") {
					// 	document.getElementById("liPixelRemarketing").href = "#";

					// 	document
					// 		.getElementById("liPixelRemarketing")
					// 		.addEventListener("click", (e) => {
					// 			e.preventDefault();

					// 			modalMsg(
					// 				"danger.svg",
					// 				"Recurso não permitido",
					// 				"Para links em site https recomendamos o uso de pixels e analytics na página de destino"
					// 			);
					// 		});
					// }

					//Fill total collum data
					//document.getElementById('totalClicksinSummarycol').style.display='';
					document.getElementById("totalClicksinSummary").innerHTML =
						linkClicks.totalClicks || 0;

					if (document.getElementById("dashLnkDesc"))
						document.getElementById("dashLnkDesc").innerText =
							linkClicks.description;

					const summaryClicks = document.getElementById("summaryClicks");

					if (summaryClicks) {
						var clicksArray = [];
						var dataArray = [];

						const legendsArray = [
							"Android",
							"iOS",
							"Windows",
							"Linux",
							"macOS",
						]; //, 'Outros'

						const totClickstoArr =
							linkClicks.androidClicks +
							linkClicks.iOSClicks +
							linkClicks.windowsClicks +
							linkClicks.linuxClicks +
							linkClicks.macOSClicks +
							linkClicks.otherOSClicks;
						clicksArray.push(
							Math.round((linkClicks.androidClicks * 100) / totClickstoArr)
						);
						clicksArray.push(
							Math.round((linkClicks.iOSClicks * 100) / totClickstoArr)
						);
						clicksArray.push(
							Math.round((linkClicks.windowsClicks * 100) / totClickstoArr)
						);
						clicksArray.push(
							Math.round((linkClicks.linuxClicks * 100) / totClickstoArr)
						);
						clicksArray.push(
							Math.round((linkClicks.macOSClicks * 100) / totClickstoArr)
						);
						// clicksArray.push(Math.round(linkClicks.otherOSClicks*100/(totClickstoArr)));

						document.getElementById("totalClicksinDetails").innerHTML =
							linkClicks.totalClicks || 0;

						document.getElementById("appClicksinDetails").innerHTML =
							linkClicks.appClicks || 0;
						document.getElementById("httpClicksinDetails").innerHTML =
							linkClicks.httpClicks || 0;
						document.getElementById("androidClicksinDetails").innerHTML =
							linkClicks.androidClicks || 0;
						document.getElementById("iOSClicksinDetails").innerHTML =
							linkClicks.iOSClicks || 0;
						document.getElementById("windowsClicksinDetails").innerHTML =
							linkClicks.windowsClicks || 0;
						document.getElementById("linuxClicksinDetails").innerHTML =
							linkClicks.linuxClicks || 0;
						document.getElementById("macOSClicksinDetails").innerHTML =
							linkClicks.macOSClicks || 0;
						document.getElementById("otherOSClicksinDetails").innerHTML =
							linkClicks.otherOSClicks || 0;

						dataArray["legends"] = legendsArray;
						dataArray["clicks"] = clicksArray;

						//return dataArray;
						showTrafficChart(dataArray, "trafficChart");
						clicksArray = [];
						dataArray = [];

						//fill the second chart
						const totClickstoArr2 =
							(linkClicks.androidMobileClicks || 0) +
							(linkClicks.androidTabletClicks || 0) +
							(linkClicks.iPhoneClicks || 0) +
							(linkClicks.iPadClicks || 0) +
							(linkClicks.pcClicks || 0);
						clicksArray.push(
							Math.round(
								(linkClicks.androidMobileClicks * 100) / totClickstoArr2
							)
						);
						clicksArray.push(
							Math.round(
								(linkClicks.androidTabletClicks * 100) / totClickstoArr2
							)
						);
						clicksArray.push(
							Math.round((linkClicks.iPhoneClicks * 100) / totClickstoArr2)
						);
						clicksArray.push(
							Math.round(((linkClicks.iPadClicks || 0) * 100) / totClickstoArr2)
						);
						clicksArray.push(
							Math.round((linkClicks.pcClicks * 100) / totClickstoArr2)
						);

						dataArray["clicks"] = clicksArray;
						const legendsArrayPlat = [
							"Android Phone",
							"Android Tablet",
							"iPhone",
							"iPad",
							"Pc's",
						]; //, 'Outros'
						dataArray["legends"] = legendsArrayPlat;

						//return dataArray;
						showTrafficChart(dataArray, "trafficChartPlatform");

						const checkUserSubscription = functions.httpsCallable(
							"checkUserSubscription"
						);

						checkUserSubscription()
							.then((result) => {
								//modalAlert('Link Criado com Sucesso','success','links.html');
								console.log(result);
								//setupLastClicks(result.data);
								if (result.data.alertType == "success")
									document.getElementById("urlShortLink").href =
										"link-urlshorts.html";
								else {
									document
										.getElementById("urlShortLink")
										.addEventListener("click", (e) => {
											modalAlert(
												"Somente usuários assinantes podem criar encurtadores de link",
												"warning"
											);
										});
								}
							})
							.catch((error) => {
								modalAlert(error.message);
								console.log(error);
							});

						//qrcode

						const checkSubscription = functions.httpsCallable(
							"checkUserSubscription"
						);

						checkSubscription()
							.then((result) => {
								//modalAlert('Link Criado com Sucesso','success','links.html');
								console.log(result);
								//setupLastClicks(result.data);
								if (result.data.alertType == "success")
									document.getElementById(
										"qrCode"
									).innerHTML = `<img src="https://api.qrserver.com/v1/create-qr-code/?data=${linkDomain}${sessionStorage.getItem(
										"linkType"
									)}/${sessionStorage.getItem(
										"shortLinkID"
									)}&amp;size=280x280" alt="" title="" />`;
								else {
									const QRCodeStyling = require("qr-code-styling");
									const qrCode = new QRCodeStyling({
										width: 280,
										height: 280,
										type: "svg",
										data:
											linkDomain +
											sessionStorage.getItem("linkType") +
											"/" +
											sessionStorage.getItem("shortLinkID"),
										image:
											"http://" + window.location.host + "/assets/img/logo.svg",
										dotsOptions: {
											color: "#4267b2",
											type: "rounded",
										},
										backgroundOptions: {
											color: "#e9ebee",
										},
										imageOptions: {
											crossOrigin: "anonymous",
										},
									});

									qrCode.append(document.getElementById("qrCode"));
									//qrCode.download({ name: "qr", extension: "svg" });
								}
							})
							.catch((error) => {
								modalAlert(error.message);
								console.log(error);
							});
					}
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
				});
		}

		// link Details page data

		// last 1000 clicks
		if (document.getElementById("lastClicksTbody")) {
			let lastClickPag = 10;
			let lastClickTotal = 1000;
			if (window.location.pathname == "/link-details.html") {
				lastClickPag = 6;
				lastClickTotal = 100;
			}

			const getLasClicks = functions.httpsCallable("getLastClicks");

			getLasClicks({
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
				lastClickTotal: lastClickTotal,
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					
					setupLastClicks(result.data, lastClickPag);
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}

		// link url short
		if (document.getElementById("tblUrlShorts")) {
			const getUrlShorts = functions.httpsCallable("getUrlShorts");

			getUrlShorts({
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					

					var listOptions = {
						valueNames: [
							"link",
							"urlshort",
							"utm_source",
							"utm_medium",
							"utm_campaign",
							"utm_term",
							"utm_content",
							"options",
						],
						page: 6,
						pagination: true,
					};

					// Init list
					var lCList = new List("tblUrlShorts", listOptions);

					//console.log(data);
					if (result.data.length) {
						result.data.forEach((doc) => {
							const li = `
                  <a href="#" class="edtUrlShort"><i class="fas fa-edit"></i></a> &nbsp;
                  <a href="#" class="text-danger delUrlShort"><i class="fas fa-unlink"></i></a> 
                `;

							lCList.add({
								link: linkDomain + doc.linkType + "/" + doc.shortLink,
								urlshort:
									linkDomain +
									doc.id +
									' <input type="hidden" class="clsUrlShortID" value="' +
									doc.id +
									'">',
								utm_source: doc.utm_source || "none",
								utm_medium: doc.utm_medium || "none",
								utm_campaign: doc.utm_campaign || "none",
								utm_term: doc.utm_term || "none",
								utm_content: doc.utm_content || "none",
								options: li,
							});
						});
						//Menu click listerners

						// fix pagination problem
						var pageClass = document.querySelectorAll(".page");
						pageClass.forEach((item) => (item.href = "#!"));

						var edtUrlShort = document.querySelectorAll(".edtUrlShort");
						var delUrlShort = document.querySelectorAll(".delUrlShort");

						if (edtUrlShort != null) {
							//alert('teste');

							var clsUrlShortID = document.querySelectorAll(".clsUrlShortID");
							//var linkType = document.querySelectorAll('.linkTypeID');

							for (var i = 0, l = edtUrlShort.length; i < l; i++) {
								(function (i) {
									edtUrlShort[i].onclick = function () {
										//modalAlert('linkType: ' + linkType[i].value + '/ Shorlink: ' + shortLinkID[i].value, "success");
										//alert('Element #' + i);

										//let jsarray = [linkType[i].value, shortLinkID[i].value];
										//sessionStorage.setItem("linkType", linkType[i].value);
										sessionStorage.setItem(
											"urlShortID",
											clsUrlShortID[i].value
										);
										//alert(clsUrlShortID[i].value);

										top.location.href = "link-urlshorts-edit.html";
									};
									delUrlShort[i].onclick = function () {
										if (
											confirm(
												"Deseja mesmo excluir esse encurtador? Essa operação não poderá ser desfeita"
											)
										) {
											const delUrlShortFn =
												functions.httpsCallable("delUrlShort");

											delUrlShortFn({
												urlShortID: clsUrlShortID[i].value,
											})
												.then((result) => {
													
													modalAlert(
														"Encurtador Excluído com Sucesso",
														"warning",
														"link-urlshorts.html"
													);
												})
												.catch((error) => {
													modalAlert(error.message);
													console.log(error);
												});
										}
									};
								})(i);
							}
						}
					} else {
						lCList.add({
							link: "Nenhum registro encontrado...",
						});
					}
					lCList.remove("link", "Aguarde...");

					//setupLastClicks(result.data);
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}

		// link regions page data
		const regionClicks = document.getElementById("regionClicks");

		if (regionClicks) {
			const getClicksByRegion = functions.httpsCallable("getClicksByRegion");

			getClicksByRegion({
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					//console.log(result);
					setupClicksbyRegion(result.data.regionClicks, "region");
					setupClicksbyRegion(result.data.countryClicks, "country");
					setupClicksbyRegion(result.data.cityClicks, "city");
					clicksbyRegionToExcel("region", "btnClickByRegion");
					clicksbyRegionToExcel("city", "btnClickByCity");
					clicksbyRegionToExcel("country", "btnClickByCountry");
					//setupLastClicks(result.data);
					//setupClicksbyRegion(result.data.regionClicks,'region');
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}

		// link utm data page
		const utmClicks = document.getElementById("utmClicks");

		if (utmClicks) {
			const getClicksByUTM = functions.httpsCallable("getClicksByUTM");

			getClicksByUTM({
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					//console.log(result);
					setupClicksbyRegion(result.data.utm_source, "source");
					setupClicksbyRegion(result.data.utm_medium, "medium");
					setupClicksbyRegion(result.data.utm_campaign, "campaign");
					setupClicksbyRegion(result.data.utm_term, "term");
					setupClicksbyRegion(result.data.utm_content, "content");
					//clicksbyRegionToExcel('region','btnClickByRegion');
					//clicksbyRegionToExcel('city','btnClickByCity');
					//clicksbyRegionToExcel('country','btnClickByCountry');
					//setupLastClicks(result.data);
					//setupClicksbyRegion(result.data.regionClicks,'region');
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}

		// link facebook pixel e google analytics data
		const linkRemarketing = document.getElementById("linkRemarketing");

		if (linkRemarketing) {
			const getLinkDetails = functions.httpsCallable("getLinkDetails");

			getLinkDetails({
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			})
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					//console.log(result);
					setupRemarketing(result.data);

					//setupLastClicks(result.data);
					//setupClicksbyRegion(result.data.regionClicks,'region');
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
				});
		}

		//get users list
		if (document.getElementById("listUsers")) {
			//const getUsers = functions.httpsCallable("getUsers");

			firebaseService.callFirebaseFunction("getUsers")
				.then((result) => {
					//const getAllUserDetails = functions.httpsCallable('getAllUserDetails');

					if (document.getElementById("totalUsersCount"))
						document.getElementById("totalUsersCount").innerText =
							" Total: " + result.data.length;
					setupListUsers(result.data, functions, modalAlert);

					// getAllUserDetails()
					// .then(resultUserDetails => {

					//   //modalAlert('Link Criado com Sucesso','success','links.html');
					//   //console.log(resultUserDetails.data);
					//   setupListUsers(result.data,resultUserDetails.data,functions,modalAlert);

					// })
					// .catch(error => {
					//     modalAlert(error);
					//     console.log(error);
					// });

					//modalAlert('Link Criado com Sucesso','success','links.html');
					
					//setupListUsers(result.data);
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
				});
		}
	} else {
		if (
			window.location.pathname != "/" &&
			window.location.pathname != "/index.html" &&
			window.location.pathname != "/login.html" &&
			window.location.pathname != "/sign-up.html" &&
			window.location.pathname != "/password-reset.html" &&
			window.location.pathname != "/confirm-email-success.html"
		) {
			top.location.href = "login.html";
		} else {
			setupUI();
			//document.getElementById('mainLogoLink').href='/';
		}
		//setupLinks(null);
	}
});

function roundTo(n, digits) {
	if (digits === undefined) {
		digits = 0;
	}

	var multiplicator = Math.pow(10, digits);
	n = parseFloat((n * multiplicator).toFixed(11));
	var test = Math.round(n) / multiplicator;
	return +test.toFixed(digits);
}

// login
const loginForm = document.querySelector("#login-form");

if (loginForm) {
	//remember me elements
	const rmCheck = document.getElementById("rememberMe"),
		emailInput = document.getElementById("login-email");

	if (localStorage.checkbox && localStorage.checkbox !== "") {
		rmCheck.setAttribute("checked", "checked");
		emailInput.value = localStorage.username;
	} else {
		rmCheck.removeAttribute("checked");
		emailInput.value = "";
	}

	loginForm.addEventListener("submit", (e) => {
		e.preventDefault();

		// get user info
		const email = loginForm["login-email"].value;
		const password = loginForm["login-password"].value;

		//remember my login action

		if (rmCheck.checked && emailInput.value !== "") {
			localStorage.username = emailInput.value;
			localStorage.checkbox = rmCheck.value;
		} else {
			localStorage.username = "";
			localStorage.checkbox = "";
		}

		// alert('passou aqui');

		// log the user in
		auth
			.signInWithEmailAndPassword(email, password)
			.then((cred) => {
				// close the signup modal & reset form
				//const modal = document.querySelector('#modal-login');
				//M.Modal.getInstance(modal).close();
				//loginForm.reset();
				//console.log(cred);
				if (cred.user.emailVerified) checkQueryString();
				else {
					if (
						confirm(
							"E-mail não verificado. Deseja reenviar e-mail para confirmação agora?"
						)
					) {
						var user = auth.currentUser;
						user.sendEmailVerification();
						modalAlert(
							"E-mail de confirmação enviado. Após validação você poderá para criar links no sistema :-)",
							"success",
							"links.html"
						);
						//auth.signOut();
					} else {
						//auth.signOut();
						modalAlert(
							"E necessário ter e-mail confirmado para criar links no sistema",
							"warning",
							"links.html"
						);
					}
				}
				//loginForm.querySelector('.error').innerHTML = '';
			})
			.catch((err) => {
				//loginForm.querySelector('.error').innerHTML = err.message;
				//showNotification(err.message);
				let errmsg = "";
				if (err.code == "auth/wrong-password")
					errmsg = "Senha inválida. Tente novamente";
				else if (err.code == "auth/user-not-found")
					errmsg = "Usuário não encontrado";
				else if (err.code == "auth/email-already-in-use")
					errmsg = "Este e-mail já é utlizado por outra conta.";
				else errmsg = err.message;
				modalAlert(errmsg);
				console.log(err);
			});
	});
}

// signup with google account
const signUpWithGoogle = document.getElementById("signUpWithGoogle");

if (signUpWithGoogle) {
	signUpWithGoogle.addEventListener("click", (e) => {
		const googleProvider = new firebase.auth.GoogleAuthProvider();

		auth
			.signInWithPopup(googleProvider)
			.then((user) => {
				//console.log(user);
				fbq("track", "CompleteRegistration");
				gtag_report_signup_conversion();
				//alert('passou aqui');
				setTimeout(function () {
					//your code to be executed after 2 seconds
					window.location.assign("./links.html");
				}, 2000);
			})
			.catch((error) => {
				if (
					error.code != "auth/popup-closed-by-user" &&
					error.code != "auth/cancelled-popup-request"
				) {
					modalAlert(error);
					console.log(error);
				}
			});
	});
}

// signup with google account
const loginWithGoogle = document.getElementById("loginWithGoogle");

if (loginWithGoogle) {
	loginWithGoogle.addEventListener("click", (e) => {
		const googleProvider = new firebase.auth.GoogleAuthProvider();

		auth
			.signInWithPopup(googleProvider)
			.then(() => {
				//window.location.assign("./links.html");
				checkQueryString();
			})
			.catch((error) => {
				if (
					error.code != "auth/popup-closed-by-user" &&
					error.code != "auth/cancelled-popup-request"
				) {
					modalAlert(error);
					console.log(error);
				}
			});
	});
}

// signup with email and password
const signupForm = document.querySelector("#signup-form");

if (signupForm) {
	signupForm.addEventListener("submit", async (e) => {
		e.preventDefault();
		try {
			const checkAlternateEmail = functions.httpsCallable("checkAlternateEmail");

			checkAlternateEmail({userEmail:signupForm["signup-email"].value})
			.then((result) => {
				console.log(result);
				//document.getElementById('btn-addr-form').innerHTML='Salvar Dados';
			})
			.catch((error) => {
				modalAlert(
					'E-mail já cadastrado em nosso sistema... Favor usar outro."',
					"alert"
				);
				return false;
				
			});
			
		} catch (error) {
			modalAlert(
				'E-mail já cadastrado em nosso sistema... Favor usar outro."',
				"alert"
			);
			return false;
			
		}
		

		// re
		var select = document.getElementById("deeplinkChoices");
		var selected = [...select.options]
			.filter((option) => option.selected)
			.map((option) => option.value);
		//console.log(selected.length);
		if (selected.length == 0) {
			modalAlert(
				'Favor Selecionar ao menos uma Opção na pergunta: "Onde você pretende utilizar o deeplink?"',
				"alert"
			);
			select.focus();
			return false;
		}

		if (
			signupForm["login-password"].value != signupForm["login-password2"].value
		) {
			modalAlert("Senhas não conferem", "alert");
			select.focus();
			return false;
		}

		//console.log(document.getElementById('deeplink_choices').value);
		// get user info
		const email = signupForm["signup-email"].value;
		const password = signupForm["login-password"].value;
		const phoneNumber = signupForm["signup-phoneNumber"].value;
		const onlyNumbers = phoneNumber.replace(/\D/g, "");
		const displayName = signupForm["displayName"].value;
		//(onlyNumbers.length);

		if (onlyNumbers.length < 11)
			modalAlert("Valor digitar o telefone completo", "warning");
		else {
			// sign up the user & add firestore data

			document.getElementById("btnSignup").innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;


			// try {
			// 	const createAcContact = functions.httpsCallable("createAcContact");
			// 	let bodyAcContact = {
			// 		email: email,
			// 		firstName: displayName.split(" ")[0],
			// 		phone: onlyNumbers
			// 	};

			// 	if (displayName.split(" ")[0]!=displayName.split(" ").pop())
			// 		bodyAcContact.lastName = displayName.split(" ").pop();

			// 	createAcContact(bodyAcContact)
			// 		.then((result) => {
			// 			console.log("ok");
			// 		})
			// 		.catch((error) => {
			// 			console.log(error);
			// 		});
			// } catch (error) {
			// 	console.log(error);
				
			// }

			auth
				.createUserWithEmailAndPassword(email, password)
				.then((cred) => {
					// send email verification
					var user = auth.currentUser;
					//console.log(user);
					user.sendEmailVerification();

					// const sendVerifyEmail = functions.httpsCallable('sendVerifyEmail');

					// sendVerifyEmail({
					//   email: user.email,
					//   uid: user.uid,
					// })
					// .then(result => {
					const addUserDetails = functions.httpsCallable("addUserDetails");

					addUserDetails({
						userPhoneNumber: onlyNumbers,
						displayName: displayName,
						deeplinkChoices: selected,
					})
						.then((result) => {
							
							gtag_report_signup_conversion(); //#dc3545
							setTimeout(function () {
								//your code to be executed after 2 seconds
								document.getElementById("btnSignup").innerHTML = "Cadastrar";
								modalMsg(
									"success.svg",
									"Cadastro realizado",
									"Confirme seu email para poder criar links e ter acesso completo à plataforma.",
									"Acessar AppClick",
									"btn-primary",
									"links.html"
								);
								btnModalListener = (e) => {
									e.preventDefault();
									top.location.href = "links.html";
								};
								document
									.getElementById("modalInfo" + "btn")
									.addEventListener("click", btnModalListener, true);
								//modalAlert('Usuário Cadastrado, lembre-se de confirmar seu e-mail para poder criar links','success','links.html');
							}, 2000);
						})
						.catch((err) => {
							document.getElementById("btnSignup").innerHTML = "Cadastrar";
							modalAlert(err.message);
							console.log(err);
						});

					// }).catch(err => {
					//   modalAlert(err.message);
					//   console.log(err);
					// });
				})
				.catch((err) => {
					//loginForm.querySelector('.error').innerHTML = err.message;
					//showNotification(err.message);
					let errmsg = "";
					if (err.code == "auth/wrong-password")
						errmsg = "Senha inválida. Tente novamente";
					else if (err.code == "auth/user-not-found")
						errmsg = "Usuário não encontrado";
					else if (err.code == "auth/email-already-in-use")
						errmsg = "Este e-mail já é utlizado por outra conta.";
					else errmsg = err.message;
					modalAlert(errmsg);
					document.getElementById("btnSignup").innerHTML = "Cadastrar";
					console.log(err);
				});
		}
	});
}

// logout
const logout = document.querySelector("#logout");
if (logout) {
	logout.addEventListener("click", (e) => {
		e.preventDefault();
		auth.signOut();
		sessionStorage.clear();
		top.location.href = "/";
	});
}

// resetpassword
const resetemailForm = document.querySelector("#reset-password-form");
if (resetemailForm) {
	resetemailForm.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnPassReset").innerHTML = `
    <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only">Aguarde...</span>
    </div>Aguarde...`;

		// get user info
		const emailAddress = resetemailForm["email-reset"].value;

		auth
			.sendPasswordResetEmail(emailAddress)
			.then(function () {
				document.getElementById("btnPassReset").innerHTML = "Redefinir Senha";
				// Email sent.
				modalAlert("Email enviado com sucesso", "success", "login.html");
			})
			.catch(function (err) {
				// An error happened.
				document.getElementById("btnPassReset").innerHTML = "Redefinir Senha";
				modalAlert(err.message);
				console.log({ html: err.message });
			});
	});
}

const loggedOutLinks = document.querySelectorAll(".logged-out");
const loggedInLinks = document.querySelectorAll(".logged-in");
const accountDetails = document.querySelector(".account-details");
const adminItems = document.querySelectorAll(".admin");
const linkTypes = document.getElementById("linkTypes");
const linkTypesListcontainer = document.querySelector(
	".linkTypesListcontainer"
);

function makeid(length) {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const setupUI = (user) => {
	if (user) {
		if (user.admin) {
			adminItems.forEach((item) => (item.style.display = "block"));
			if (sessionStorage.getItem("viewAsUsr") && user.admin) {
				console.log(sessionStorage.getItem("viewAsUsr"));
				document.getElementById("viewingAs").style.display = "block";
				document.getElementById("viewingAs").addEventListener("click", (e) => {
					e.preventDefault();
					sessionStorage.removeItem("viewAsUsr");
					top.location.href = "links.html";
				});
			}
			//change text of peding domains
			if (document.getElementById("lnkPendDomains")) {

					firebaseService.callFirebaseFunction("getPendingDomains")
					.then((result) => {
						if (result.data.length)
							document.getElementById("lnkPendDomains").innerHTML =
								"Domínios pendentes (" + result.data.length + ")";
					})
					.catch((error) => {
						modalAlert(error.message);
						console.log(error);
					});
			}
		}
		//sessionStorage.setItem("viewAsUsr", e.path[1].innerText.trim());

		// toggle user UI elements
		loggedInLinks.forEach((item) => (item.style.display = "block"));
		loggedOutLinks.forEach((item) => (item.style.display = "none"));
		//Select on Create Link
		/*db.collection('linkTypes').orderBy('media', 'asc').get()
        .then(snapshot => {
          if (linkTypes)
            setupSelectLinks(snapshot.docs);
        }, err => console.log(err.message));*/
		if (document.getElementById("linkTypeRows")) {
			const getLinkTypes = functions.httpsCallable("getLinkTypes");

			getLinkTypes()
				.then((result) => {
					//modalAlert('Link Criado com Sucesso','success','links.html');
					
					setupSelectLinks(result);
					//setupLastClicks(result.data);
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
				});
		}
	} else {
		// clear account info
		//accountDetails.innerHTML = '';
		// toggle user elements
		adminItems.forEach((item) => (item.style.display = "none"));
		loggedInLinks.forEach((item) => (item.style.display = "none"));
		loggedOutLinks.forEach((item) => (item.style.display = "block"));
	}
};

// creating list as global
var LuserLinkList;
var listOptions = {
	valueNames: [
		"id",
		"description",
		"shortLink",
		"web",
		"totalClicks",
		"created_at",
		"status",
		"options",
	],
	page: 8,
	pagination: true,
};
// Init list
LuserLinkList = new List("TbLinksList", listOptions);
// setup links function new
const setupLinks = (data, hidden = false) => {
	const userLinkList = document.getElementById("TbLinksList");
	//console.log(data.length);
	LuserLinkList.clear();
	if (data.length) {
		//LuserLinkList.add({description:'Aguarde...'});

		data.forEach((doc) => {
			const link = doc;

			let li = `
    <button href="#!" class="btn btn-primary btn-sm linkDetails fas fa-plus-circle" title="Ver Detalhes"></button>
     
`;

			if (hidden) {
				li +=
					'<button href="#!" class="btn btn-primary btn-sm linkHide fas fas fa-eye" title="Deixar Link Vísivel Novamente" data-hidden="false"></button>';
			} else {
				li +=
					'<button href="#!" class="btn btn-danger btn-sm linkHide fas fas fa-eye-slash" title="Ocultar Link" data-hidden="true"></button>';
			}

			let checkedStatus;
			if (link.active) checkedStatus = "checked";

			const colStatus =
				`
			<div class="form-check form-switch ">
			<input class="form-check-input linkActive" type="checkbox" ` +
				checkedStatus +
				`/>
			</div>
			`;

			// html += li;
			let timeFixer = 0;

			if (link.created_at) timeFixer = link.created_at["_seconds"];
			else timeFixer = 327271200;

			const fullLink=linkDomain + link.linkType +	'/' + link.shortLink;

			LuserLinkList.add({
				id: link.id,
				description: link.description +
				'<a href="' + fullLink +
					'" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="' + fullLink +'">' +
					' <i class="fas fa-link"></i></a>' +
					'<input type="hidden" class="shortLinkID" value="' +
					link.shortLink +
					'" /><input type="hidden" class="linkTypeID" value="' +
					link.linkType +
					'" />',
				shortLink:'',
				web:
					'<a href="'+link.fallback+'" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="' +
					link.fallback +
					'"> <i class="fas fa-link"></i> link<a>',
				totalClicks: link.totalClicks,
				created_at:
					'<span style="display:none">' +
					timeFixer +
					"</span>" +
					toDateTime(timeFixer).toLocaleString("pt-BR"),
				status: colStatus,
				options: li,
			});
		});
		LuserLinkList.remove("description", "Aguarde...");
		LuserLinkList.sort("created_at", { order: "desc" });
		//linkList.innerHTML = html

		//link hide/unhide click
		hideUnhideUserLink();

		// Link Details click
		var linkElems = document.querySelectorAll(".linkDetails");

		if (linkElems != null) {
			//alert('teste');

			var shortLinkID = document.querySelectorAll(".shortLinkID");
			var linkType = document.querySelectorAll(".linkTypeID");

			for (var i = 0, l = linkElems.length; i < l; i++) {
				(function (i) {
					linkElems[i].onclick = function () {
						//modalAlert('linkType: ' + linkType[i].value + '/ Shorlink: ' + shortLinkID[i].value, "success");
						//alert('Element #' + i);

						//let jsarray = [linkType[i].value, shortLinkID[i].value];
						sessionStorage.setItem("linkType", linkType[i].value);
						sessionStorage.setItem("shortLinkID", shortLinkID[i].value);

						top.location.href = "link-details.html";
					};
				})(i);
			}
		}

		// fix pagination problem
		var pageClass = document.querySelectorAll(".page");
		pageClass.forEach((item) => (item.href = "#!"));
	} else {
		//userLinkList.innerHTML = 'Sem Links Criados no momento';
		LuserLinkList.add({
			id: "",
			description: "Nenhum Link Encontrado...",
			shortLink: "",
			web: "",
			totalClicks: "",
			created_at: "",
			options: "",
		});
	}

	LuserLinkList.on("updated", function (menuList) {
		hideUnhideUserLink(menuList);

		var linkElems = document.querySelectorAll(".linkDetails");
		//console.log(menuList);
		var shortLinkID = document.querySelectorAll(".shortLinkID");
		var linkType = document.querySelectorAll(".linkTypeID");
		for (var i = 0, l = linkElems.length; i < l; i++) {
			(function (i) {
				linkElems[i].onclick = function () {
					//modalAlert('linkType: ' + linkType[i].value + '/ Shorlink: ' + shortLinkID[i].value, "success");
					//alert('Element #' + i);

					//let jsarray = [linkType[i].value, shortLinkID[i].value];
					sessionStorage.setItem("linkType", linkType[i].value);
					sessionStorage.setItem("shortLinkID", shortLinkID[i].value);

					top.location.href = "link-details.html";
				};
			})(i);
		}
		// fix pagination problem
		pageClass = document.querySelectorAll(".page");
		pageClass.forEach((item) => (item.href = "#!"));
	});
};

// setup links function old
/*
  const setupLinks = (data) => {
    //console.log(data);
    if (data != null) {
      let html = '';
      let linkActive = '';
      let linkAction = '';

      data.forEach(doc => {
        const link = doc;

        if (link.active) {
          linkActive = '<span class="text-success">●</span> Ativo';
          linkAction = `<a href="#" class="dropdown-item actLink">Desativar</a>`;
        } else {
          linkActive = '<span class="text-warning">●</span> Inativo';
          linkAction = `<a href="#" class="dropdown-item actLink">Ativar</a>`;
        }
        const li = `
        <tr>
        
        <td class="link-shortLink">
          ${linkDomain}${link.linkType}/${link.shortLink} 
          <input type="hidden" class="shortLinkID" value="${link.shortLink}" />
          <input type="hidden" class="linkTypeID" value="${link.linkType}" />
        </td>
        <td class="link-android">
          <span class="text-warning">●</span> ${link.android.substr(0, 20)}...
        </td>
        <td class="link-ios">
              ${link.ios.substr(0, 20)}...
        </td>
        <td class="link-web">
              ${link.fallback.substr(0, 20)}...
        </td>
        <td class="text-right">
        ${linkActive}
        </td>
        <td class="text-right">
          <div class="dropdown">
            <a href="#" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fe fe-more-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              ${linkAction}
              <a href="#" class="dropdown-item linkDetails">
                DashBoard
              </a>
            </div>
          </div>
        </td>
      </tr>          
      `;
        html += li;

      });
      linkList.innerHTML = html

    } else {
      linkList.innerHTML = 'Sem Links Criados no momento';
    }


  };
  */

// setup enable/disable users click

const enableDisableAdmins = (linkClass) => {
	const enableDisableAdminsClick = document.querySelectorAll("." + linkClass);

	if (enableDisableAdminsClick != null) {
		//alert('teste');
		enableDisableAdminsClick.forEach((item) =>
			item.addEventListener("click", (e) => {
				e.preventDefault();
				var txtAtivo = "";
				var sendBoolEnabled;
				console.log(item.innerText);
				if (item.innerText == "● Usuário ") {
					txtAtivo = "ativar";
					sendBoolEnabled = true;
				} else {
					txtAtivo = "desativar";
					sendBoolEnabled = false;
				}

				//modalAlert("Função disponível em breve", "warning");
				if (confirm("deseja " + txtAtivo + " esse usuário como Admin?")) {
					// const enableDisableAdmin =
					// 	functions.httpsCallable("enableDisableAdmin");

					// enableDisableAdmin({
					// 	email: item.getElementsByTagName("span")[0].id,
					// 	enabled: sendBoolEnabled,
					// })
					firebaseService.callFirebaseFunction("enableDisableAdmin", {
						email: item.getElementsByTagName("span")[0].id,
						enabled: sendBoolEnabled,
						},{useCache:false})
						.then((result) => {
							
							modalAlert(result.data.message, result.data.alert, "users.html");
							return true;
						})
						.catch((error) => {
							modalAlert(error.message);
						});
				}
			})
		);
	}
};

function viewAsClickEvent(e) {
	e.preventDefault();
	var path = e.path || (e.composedPath && e.composedPath());
	//console.log(e.path[2].innerText.trim());
	sessionStorage.setItem("viewAsUsr", path[2].innerText.trim());
	top.location.href = "links.html";
}

const viewAs = (linkClass) => {
	const viewAsClass = document.querySelectorAll("." + linkClass);

	if (viewAsClass != null) {
		//alert('teste');
		viewAsClass.forEach((item) =>
			item.removeEventListener("click", viewAsClickEvent)
		);
		viewAsClass.forEach((item) =>
			item.addEventListener("click", viewAsClickEvent)
		);
	}
};

const enableDisableFakeSubscriptions = (linkClass) => {
	const enableDisableFakeClick = document.querySelectorAll("." + linkClass);

	if (enableDisableFakeClick != null) {
		//alert('teste');
		enableDisableFakeClick.forEach((item) =>
			item.addEventListener("click", (e) => {
				e.preventDefault();
				var txtAtivo = "";
				var sendBoolEnabled;
				console.log(item.innerText);
				if (item.innerText == "● Ativa ") {
					txtAtivo = "desativar";
					sendBoolEnabled = false;
				} else {
					txtAtivo = "ativar";
					sendBoolEnabled = true;
				}

				//modalAlert("Função disponível em breve", "warning");
				if (
					confirm("deseja " + txtAtivo + " assinatura falsa com esse usuário?")
				) {
					const enableDisableFakeSubscription = functions.httpsCallable(
						"enableDisableFakeSubscription"
					);

					enableDisableFakeSubscription({
						email: item.getElementsByTagName("span")[0].id,
						enabled: sendBoolEnabled,
					})
						.then((result) => {
							//console.log(result);
							modalAlert(result.data.message, "success", "users.html");
							return true;
						})
						.catch((error) => {
							modalAlert(error.message);
						});
				}
			})
		);
	}
};

// setup show/hide users links

function hideUnhideUserLinkClickEvent(e) {
	e.preventDefault();

	//console.log(e);
	//return;
	//sessionStorage.setItem("viewAsUsr", e.path[2].innerText.trim());
	//top.location.href='links.html'
	var txtAtivo = "";
	var sendBoolHidden;
	//console.log(e.path[2].getElementsByTagName('td')[0].innerHTML);
	if (e.target.getAttribute("data-hidden") == "true") {
		txtAtivo = "ocultar";
		sendBoolHidden = true;
	} else {
		txtAtivo = "tornar visível";
		sendBoolHidden = false;
	}

	//modalAlert("Função disponível em breve", "warning");

	if (confirm("deseja " + txtAtivo + " esse link?")) {
		// const hideUnhideLink = functions.httpsCallable("hideUnhideLink");

		// hideUnhideLink({
		// 	docid: e.path[2].getElementsByTagName("td")[0].innerHTML,
		// 	hidden: sendBoolHidden,
		// })

		firebaseService.callFirebaseFunction("hideUnhideLink", {
			docid: e.path[2].getElementsByTagName("td")[0].innerHTML,
			hidden: sendBoolHidden,
			},{useCache:false})
			.then((result) => {
				//console.log(result);
				modalAlert(result.data.message, "success");
				LuserLinkList.remove(
					"id",
					e.path[2].getElementsByTagName("td")[0].innerHTML
				);
			})
			.catch((error) => {
				modalAlert(error.message);
			});
	}
}

const hideUnhideUserLink = (tableList) => {
	const hideUnhideUserLinkClick = document.querySelectorAll(".linkHide");

	if (hideUnhideUserLinkClick != null) {
		//alert('teste');
		hideUnhideUserLinkClick.forEach((item) =>
			item.removeEventListener("click", hideUnhideUserLinkClickEvent)
		);

		hideUnhideUserLinkClick.forEach((item) =>
			item.addEventListener("click", hideUnhideUserLinkClickEvent)
		);
	}

	const linkActiveClick = document.querySelectorAll(".linkActive");

	const linkActiveClickEvent = function (e) {
		e.preventDefault();

		var path = e.path || (e.composedPath && e.composedPath());

		let txtActive = "";

		const chkboxprojActive = e.target;

		if (chkboxprojActive.checked) txtActive = "ativa";
		else txtActive = "desativa";

		//console.log(path[3].getElementsByTagName("td")[0].innerHTML);
		const linkId = path[3].getElementsByTagName("td")[0].innerHTML;

		let btnModalListener = (e) => {
			e.preventDefault();
			//console.log(!chkboxprojActive.checked);

			var btnTxt = e.target.innerHTML;

			e.target.innerHTML = `
			<div class="spinner-border spinner-border-sm" role="status">
			<span class="sr-only">Carregando...</span>
			</div>Aguarde...`;

			// const editLinkfn = functions.httpsCallable("editLink");

			// editLinkfn({
			// 	userEmail: sessionStorage.getItem("viewAsUsr"),
			// 	link: linkId,
			// 	changes: {
			// 		active: !chkboxprojActive.checked,
			// 	},
			// })
			firebaseService.callFirebaseFunction("editLink", {
				userEmail: sessionStorage.getItem("viewAsUsr"),
				link: linkId,
				changes: {
					active: !chkboxprojActive.checked,
				},
			},{useCache:false})
				.then((result) => {
					chkboxprojActive.checked = !chkboxprojActive.checked;
					globModalInfo.toggle();
				})
				.catch((error) => {
					e.target.innerHTML = error.message;
					e.target.disabled = true;
				});
		};
		document
			.getElementById("modalInfo" + "btn")
			.addEventListener("click", btnModalListener, true);

		//alert("aki");
		modalMsgNew(
			"help.svg",
			"Deseja " + txtActive + "r esse Link",
			"Confirme sua ação para evitar que seu link não seja " +
				txtActive +
				"do por engano",
			txtActive.charAt(0).toUpperCase() + txtActive.slice(1) + "r Link",
			"btn-primary",
			false,
			btnModalListener,
			true,
			globModalInfo
		);
	};

	if (linkActiveClick != null) {
		linkActiveClick.forEach((item) => {
			item.onclick = linkActiveClickEvent;
		});
	}
};

// setup lastclick in link-details function
const setupLastClicks = (data, lastClickPag = 10) => {
	const lastClicksList = document.getElementById("lastClicksTbody");
	//console.log(data);
	if (data != null) {
		//list options

		var listOptions = {
			valueNames: ["os", "region", "city", "country", "date"],
			page: lastClickPag,
			pagination: true,
		};

		// Init list
		var lCList = new List("lastClicksTbody", listOptions);

		//console.log(data);
		for (var i = 0; i < data.length; i++) {
			lCList.add({
				os: data[i]["OS"],
				region: data[i]["region"],
				city: data[i]["city"],
				country: data[i]["country"],
				date: toDateTime(data[i]["datetime"]["_seconds"]).toLocaleString(
					"pt-BR"
				),
			});
		}

		/*
      data.forEach(doc => {
        const link = doc.data();

        lCList.add({
          os: link.OS,
          region: link.region,
          city: link.city,
          country: link.country,
          date: link.datetime.toDate().toLocaleString('pt-BR')
        });
        
        

      });
      */

		lCList.remove("os", "Aguarde...");

		// fix pagination problem
		var pageClass = document.querySelectorAll(".page");
		pageClass.forEach((item) => (item.href = "#!"));

		lCList.on("updated", function (menuList) {
			// fix pagination problem
			pageClass = document.querySelectorAll(".page");
			pageClass.forEach((item) => (item.href = "#!"));
		});

		//lastClicksList.innerHTML = tablePagheader + html + tablePagfooter;
	} else {
		lastClicksList.innerHTML = "Sem Cliques no momento";
	}
};

// setup clicks by City,Region,State in link-details function
const setupClicksbyRegion = (data, location) => {
	//list options

	var listOptions = {
		valueNames: [location, "clicks"],
		page: 10,
		pagination: true,
	};

	// Init list
	var RegionList = new List("Clicksby" + location + "Tbody", listOptions);

	const regionClicksList = document.getElementById(
		"Clicksby" + location + "Tbody"
	);
	//console.log(data);
	if (data != null) {
		for (var [key, value] of Object.entries(data)) {
			if (key == "none") key = "nenhum";

			RegionList.add({
				[location]: key || "N/A",
				clicks: value,
			});
		}
		RegionList.remove(location, "Aguarde...");
		RegionList.sort("clicks", { order: "desc" });
		// fix pagination problem
		var pageClass = document.querySelectorAll(".page");
		pageClass.forEach((item) => (item.href = "#!"));
		//regionClicksList.innerHTML = html

		RegionList.on("updated", function (menuList) {
			// fix pagination problem
			pageClass = document.querySelectorAll(".page");
			pageClass.forEach((item) => (item.href = "#!"));
		});
	} else {
		regionClicksList.innerHTML = "Sem Cliques no momento";
	}
};

// setup Facebook pixel  and Google analytics forms
const setupRemarketing = (data) => {
	//Facebook Pixel

	if (data.hasFacebookPixel) {
		document.getElementById("hasFacebookPixel").checked = true;
		document.getElementById("lblhasFacebookPixel").innerText = "Ligado";
		document.getElementById("facebookPixelID").value = data.facebookPixelID;
		document.getElementById("facebookPixelID").readOnly = false;
		document.getElementById("btnFaceID").disabled = false;
		document.getElementById("FacedocID").value = data.id;
	} else {
		document.getElementById("hasFacebookPixel").checked = false;
		document.getElementById("lblhasFacebookPixel").innerText = "Desligado";
		document.getElementById("facebookPixelID").value =
			data.facebookPixelID || "";
		document.getElementById("facebookPixelID").readOnly = true;
		document.getElementById("btnFaceID").disabled = true;
		document.getElementById("FacedocID").value = data.id;
	}
	const formFacePixel = document.getElementById("edit-face-pixel-form");

	formFacePixel.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnFaceID").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Carregando...</span>
      </div>Aguarde...`;

		const editFacePixel = functions.httpsCallable("editFacePixel");
		editFacePixel({
			facebookPixelID: formFacePixel.facebookPixelID.value,
			hasFacebookPixel: formFacePixel.hasFacebookPixel.checked,
			docid: formFacePixel.docid.value,
			facebookPixelEvent: formFacePixel.facebookPixelEvent.value,
		})
			.then((result) => {
				console.log(result);
				modalAlert(
					"Facebook Pixel Configurado com Sucesso",
					"success",
					"link-remarketing.html"
				);
				document.getElementById("btnFaceID").innerHTML = "Atualizar";
			})
			.catch((error) => {
				modalAlert(error);
				document.getElementById("btnFaceID").innerHTML = "Atualizar";
				console.log(error);
			});
	});

	//facebook pixel events

	const getFacePixelEvents = functions.httpsCallable("getFacePixelEvents");
	getFacePixelEvents().then((result) => {
		
		let selectToFill = document.getElementById("facebookPixelEvent");
		var opt = document.createElement("option");
		opt.value = "0";
		opt.innerHTML = "Selecione Evento (Opcional)";
		selectToFill.appendChild(opt);

		result.data.forEach((doc) => {
			var opt = document.createElement("option");
			opt.value = doc.id;
			opt.innerHTML = doc["pt-br"];
			selectToFill.appendChild(opt);
		});

		//alert(data.facebookPixelEvent);

		if (data.facebookPixelEvent) selectToFill.value = data.facebookPixelEvent;
		else selectToFill.value = "0";
	});

	document
		.getElementById("hasFacebookPixel")
		.addEventListener("change", (e) => {
			e.preventDefault();
			//console.log(e);
			if (document.getElementById("hasFacebookPixel").checked == true) {
				document.getElementById("lblhasFacebookPixel").innerText = "Ligado";
				document.getElementById("facebookPixelID").readOnly = false;
				document.getElementById("btnFaceID").disabled = false;
			} else {
				document.getElementById("lblhasFacebookPixel").innerText = "Desligado";
				document.getElementById("facebookPixelID").readOnly = true;
				document.getElementById("btnFaceID").disabled = false;
			}
		});

	//Facebook Pixel Cont
	/*
  if(data.hasFacebookPixelCont){
    document.getElementById("hasFacebookPixelCont").checked=true;
    document.getElementById("lblhasFacebookPixelCont").innerText="Ligado";
    document.getElementById("facebookPixelContID").value=data.facebookPixelContID;
    document.getElementById("facebookPixelContID").readOnly=false;
    document.getElementById("btnFaceIDCont").disabled=false;
    document.getElementById("FacedocIDCont").value=data.id;
  }else{
    document.getElementById("hasFacebookPixelCont").checked=false;
    document.getElementById("lblhasFacebookPixelCont").innerText="Desligado";
    document.getElementById("facebookPixelContID").value=data.facebookPixelContID||'';
    document.getElementById("facebookPixelContID").readOnly=true;
    document.getElementById("btnFaceIDCont").disabled=true;
    document.getElementById("FacedocIDCont").value=data.id;
  }
  const formFacePixelCont = document.getElementById('edit-face-pixelcont-form');

  formFacePixelCont.addEventListener('submit', (e) => {
      e.preventDefault();

      document.getElementById('btnFaceIDCont').innerHTML=`
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Carregando...</span>
      </div>Aguarde...`;

            const editFacePixelCont = functions.httpsCallable('editFacePixelCont');
            editFacePixelCont({ 
                facebookPixelContID: formFacePixelCont.facebookPixelContID.value ,
                hasFacebookPixelCont: formFacePixelCont.hasFacebookPixelCont.checked ,
                facebookPixelEventCont: formFacePixelCont.facebookPixelEventCont.value,
                docid: formFacePixelCont.docid.value
            })
            .then(result => {
                
                console.log(result);
                modalAlert('Facebook Pixel de Contigência Configurado com Sucesso','success','link-remarketing.html');
                document.getElementById('btnFaceIDCont').innerHTML='Atualizar';
            })
            .catch(error => {
                modalAlert(error);
                document.getElementById('btnFaceIDCont').innerHTML='Atualizar';
                console.log(error);
            });
          
            

    });
    

        //facebook pixel events

        //const getFacePixelEventsCont = functions.httpsCallable('getFacePixelEvents');
        getFacePixelEvents()
                .then(result => {
                    
                    let selectToFill=document.getElementById('facebookPixelEventCont');
                    var opt = document.createElement('option');
                    opt.value = "0";
                    opt.innerHTML = 'Selecione Evento (Opcional)';
                    selectToFill.appendChild(opt); 
    
                    result.data.forEach(doc => {
                      var opt = document.createElement('option');
                      opt.value = doc.id;
                      opt.innerHTML = doc['pt-br'];
                      selectToFill.appendChild(opt);
                
                      
                
                    });
    
                    //alert(data.facebookPixelEvent);
    
                    if(data.facebookPixelEventCont)
                    selectToFill.value = data.facebookPixelEventCont;
                    else
                    selectToFill.value = "0";
    
        })

    document.getElementById('hasFacebookPixelCont').addEventListener('change', (e) => {
      e.preventDefault();
      //console.log(e);
      if(document.getElementById("hasFacebookPixelCont").checked==true){
        document.getElementById("lblhasFacebookPixelCont").innerText="Ligado";
        document.getElementById("facebookPixelContID").readOnly=false;
        document.getElementById("btnFaceIDCont").disabled=false;
      }else{
        document.getElementById("lblhasFacebookPixelCont").innerText="Desligado";
        document.getElementById("facebookPixelContID").readOnly=true;
        document.getElementById("btnFaceIDCont").disabled=false;

      }
          
            

    });
    */

	//Facebook Pixel API
	if (data.hasFacebookPixelAPI) {
		document.getElementById("hasFacebookPixelAPI").checked = true;
		document.getElementById("lblhasFacebookPixelAPI").innerText = "Ligado";
		document.getElementById("facebookPixelAPIToken").value =
			data.facebookPixelAPIToken;
		document.getElementById("facebookPixelAPIToken").readOnly = false;
		document.getElementById("facebookPixelAPIID").value =
			data.facebookPixelAPIID;
		document.getElementById("facebookPixelAPIID").readOnly = false;
		document.getElementById("btnFaceAPIID").disabled = false;
		document.getElementById("FacedocAPIID").value = data.id;
	} else {
		document.getElementById("hasFacebookPixelAPI").checked = false;
		document.getElementById("lblhasFacebookPixelAPI").innerText = "Desligado";
		document.getElementById("facebookPixelAPIToken").value =
			data.facebookPixelAPIToken || "";
		document.getElementById("facebookPixelAPIToken").readOnly = true;
		document.getElementById("facebookPixelAPIID").value =
			data.facebookPixelAPIID || "";
		document.getElementById("facebookPixelAPIID").readOnly = true;
		document.getElementById("btnFaceAPIID").disabled = true;
		document.getElementById("FacedocAPIID").value = data.id;
	}
	const formFacePixelAPI = document.getElementById("edit-face-pixel-api-form");

	formFacePixelAPI.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnFaceAPIID").innerHTML = `
        <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Carregando...</span>
        </div>Aguarde...`;

		const editFacePixelAPI = functions.httpsCallable("editFacePixelAPI");
		editFacePixelAPI({
			facebookPixelAPIToken: formFacePixelAPI.facebookPixelAPIToken.value,
			facebookPixelAPIID: formFacePixelAPI.facebookPixelAPIID.value,
			hasFacebookPixelAPI: formFacePixelAPI.hasFacebookPixelAPI.checked,
			facebookPixelAPIEvent: formFacePixelAPI.facebookPixelAPIEvent.value,
			docid: formFacePixelAPI.docid.value,
		})
			.then((result) => {
				console.log(result);
				modalAlert(
					"Facebook Pixel API Configurado com Sucesso",
					"success",
					"link-remarketing.html"
				);
				document.getElementById("btnFaceAPIID").innerHTML = "Atualizar";
			})
			.catch((error) => {
				console.log(error);
				modalAlert(
					"Erro ao tentar cadastrar Pixel API.<br>Mensagem de retorno:<br>" +
						error.message
				);
				document.getElementById("btnFaceAPIID").innerHTML = "Atualizar";
			});
	});

	//facebook pixel events

	//const getFacePixelEventsCont = functions.httpsCallable('getFacePixelEvents');
	getFacePixelEvents()
		.then((result) => {
			
			let selectToFill = document.getElementById("facebookPixelAPIEvent");
			var opt = document.createElement("option");
			opt.value = "0";
			opt.innerHTML = "Selecione Evento (Opcional)";
			selectToFill.appendChild(opt);

			result.data.forEach((doc) => {
				var opt = document.createElement("option");
				opt.value = doc.id;
				opt.innerHTML = doc["pt-br"];
				selectToFill.appendChild(opt);
			});

			//alert(data.facebookPixelEvent);

			if (data.facebookPixelAPIEvent)
				selectToFill.value = data.facebookPixelAPIEvent;
			else selectToFill.value = "0";
		})
		.catch((error) => {
			modalAlert(error);
			console.log(error);
		});

	document
		.getElementById("hasFacebookPixelAPI")
		.addEventListener("change", (e) => {
			e.preventDefault();
			//console.log(e);
			if (document.getElementById("hasFacebookPixelAPI").checked == true) {
				document.getElementById("lblhasFacebookPixelAPI").innerText = "Ligado";
				document.getElementById("facebookPixelAPIToken").readOnly = false;
				document.getElementById("facebookPixelAPIID").readOnly = false;
				document.getElementById("btnFaceAPIID").disabled = false;
			} else {
				document.getElementById("lblhasFacebookPixelAPI").innerText =
					"Desligado";
				document.getElementById("facebookPixelAPIToken").readOnly = true;
				document.getElementById("facebookPixelAPIID").readOnly = true;
				document.getElementById("btnFaceAPIID").disabled = false;
			}
		});

	//Google Analytics

	if (data.hasGoogleAnalytics) {
		document.getElementById("hasGoogleAnalytics").checked = true;
		document.getElementById("lblhasGoogleAnalytics").innerText = "Ligado";
		document.getElementById("gaID").value = data.gaID;
		document.getElementById("gaID").readOnly = false;
		document.getElementById("btnGaID").disabled = false;
		document.getElementById("gadocID").value = data.id;
	} else {
		document.getElementById("hasGoogleAnalytics").checked = false;
		document.getElementById("lblhasGoogleAnalytics").innerText = "Desligado";
		document.getElementById("gaID").value = data.gaID || "";
		document.getElementById("gaID").readOnly = true;
		document.getElementById("btnGaID").disabled = true;
		document.getElementById("gadocID").value = data.id;
	}
	const formGA = document.getElementById("edit-ga-form");

	formGA.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnGaID").innerHTML = `
        <div class="spinner-border spinner-border-sm text-success" role="status">
        <span class="sr-only">Carregando...</span>
        </div>Aguarde...`;

		const editGoogleAnalytics = functions.httpsCallable("editGoogleAnalytics");
		editGoogleAnalytics({
			gaID: formGA.gaID.value,
			hasGoogleAnalytics: formGA.hasGoogleAnalytics.checked,
			docid: formGA.docid.value,
		})
			.then((result) => {
				console.log(result);
				modalAlert(
					"Google Analytics Configurado com Sucesso",
					"success",
					"link-remarketing.html"
				);
				document.getElementById("btnGaID").innerHTML = "Atualizar";
			})
			.catch((error) => {
				modalAlert(error);
				document.getElementById("btnGaID").innerHTML = "Atualizar";
				console.log(error);
			});
	});

	document
		.getElementById("hasGoogleAnalytics")
		.addEventListener("change", (e) => {
			e.preventDefault();
			//console.log(e);
			if (document.getElementById("hasGoogleAnalytics").checked == true) {
				document.getElementById("lblhasGoogleAnalytics").innerText = "Ligado";
				document.getElementById("gaID").readOnly = false;
				document.getElementById("btnGaID").disabled = false;
			} else {
				document.getElementById("lblhasGoogleAnalytics").innerText =
					"Desligado";
				document.getElementById("gaID").readOnly = true;
				document.getElementById("btnGaID").disabled = false;
			}
		});

	//Google TAG Manager

	if (data.hasGoogleTagManager) {
		document.getElementById("hasGoogleTagManager").checked = true;
		document.getElementById("lblhasGoogleTagManager").innerText = "Ligado";
		document.getElementById("gtmID").value = data.gtmID;
		document.getElementById("gtmID").readOnly = false;
		document.getElementById("btnGtmID").disabled = false;
		document.getElementById("gtmdocID").value = data.id;
	} else {
		document.getElementById("hasGoogleTagManager").checked = false;
		document.getElementById("lblhasGoogleTagManager").innerText = "Desligado";
		document.getElementById("gtmID").value = data.gtmID || "";
		document.getElementById("gtmID").readOnly = true;
		document.getElementById("btnGtmID").disabled = true;
		document.getElementById("gtmdocID").value = data.id;
	}
	const formGTM = document.getElementById("edit-gtm-form");

	formGTM.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnGtmID").innerHTML = `
        <div class="spinner-border spinner-border-sm text-success" role="status">
        <span class="sr-only">Carregando...</span>
        </div>Aguarde...`;

		const editGoogleTagManager = functions.httpsCallable(
			"editGoogleTagManager"
		);
		editGoogleTagManager({
			gtmID: formGTM.gtmID.value,
			hasGoogleTagManager: formGTM.hasGoogleTagManager.checked,
			docid: formGTM.docid.value,
		})
			.then((result) => {
				console.log(result);
				modalAlert(
					"Google Tag Manager Configurado com Sucesso",
					"success",
					"link-remarketing.html"
				);
				document.getElementById("btnGtmID").innerHTML = "Atualizar";
			})
			.catch((error) => {
				modalAlert(error);
				document.getElementById("btnGtmID").innerHTML = "Atualizar";
				console.log(error);
			});
	});

	document
		.getElementById("hasGoogleTagManager")
		.addEventListener("change", (e) => {
			e.preventDefault();
			//console.log(e);
			if (document.getElementById("hasGoogleTagManager").checked == true) {
				document.getElementById("lblhasGoogleTagManager").innerText = "Ligado";
				document.getElementById("gtmID").readOnly = false;
				document.getElementById("btnGtmID").disabled = false;
			} else {
				document.getElementById("lblhasGoogleTagManager").innerText =
					"Desligado";
				document.getElementById("gtmID").readOnly = true;
				document.getElementById("btnGtmID").disabled = false;
			}
		});

	//Taboola Pixel

	if (data.hasTaboolaPixel) {
		document.getElementById("hasTaboolaPixel").checked = true;
		document.getElementById("lblhasTaboolaPixel").innerText = "Ligado";
		document.getElementById("taboolaPixelID").value = data.taboolaPixelID;
		document.getElementById("taboolaPixelID").readOnly = false;
		document.getElementById("btnTaboolaPixel").disabled = false;
		document.getElementById("taboolaPixeldocID").value = data.id;
	} else {
		document.getElementById("hasTaboolaPixel").checked = false;
		document.getElementById("lblhasTaboolaPixel").innerText = "Desligado";
		document.getElementById("taboolaPixelID").value = data.taboolaPixelID || "";
		document.getElementById("taboolaPixelID").readOnly = true;
		document.getElementById("btnTaboolaPixel").disabled = true;
		document.getElementById("taboolaPixeldocID").value = data.id;
	}
	const formTaboolaPixel = document.getElementById("edit-taboolapixel-form");

	formTaboolaPixel.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnTaboolaPixel").innerHTML = `
        <div class="spinner-border spinner-border-sm text-success" role="status">
        <span class="sr-only">Carregando...</span>
        </div>Aguarde...`;

		const editTaboolaPixel = functions.httpsCallable("editTaboolaPixel");
		editTaboolaPixel({
			taboolaPixelID: formTaboolaPixel.taboolaPixelID.value,
			hasTaboolaPixel: formTaboolaPixel.hasTaboolaPixel.checked,
			docid: formTaboolaPixel.docid.value,
		})
			.then((result) => {
				console.log(result);
				modalAlert(
					"Taboola Pixel Configurado com Sucesso",
					"success",
					"link-remarketing.html"
				);
				document.getElementById("btnTaboolaPixel").innerHTML = "Atualizar";
			})
			.catch((error) => {
				modalAlert(error);
				document.getElementById("btnTaboolaPixel").innerHTML = "Atualizar";
				console.log(error);
			});
	});

	document.getElementById("hasTaboolaPixel").addEventListener("change", (e) => {
		e.preventDefault();
		//console.log(e);
		if (document.getElementById("hasTaboolaPixel").checked == true) {
			document.getElementById("lblhasTaboolaPixel").innerText = "Ligado";
			document.getElementById("taboolaPixelID").readOnly = false;
			document.getElementById("btnTaboolaPixel").disabled = false;
		} else {
			document.getElementById("lblhasTaboolaPixel").innerText = "Desligado";
			document.getElementById("taboolaPixelID").readOnly = true;
			document.getElementById("btnTaboolaPixel").disabled = false;
		}
	});

	//Twitter Pixel

	if (data.hasTwitterPixel) {
		document.getElementById("hasTwitterPixel").checked = true;
		document.getElementById("lblhasTwitterPixel").innerText = "Ligado";
		document.getElementById("twitterPixelID").value = data.twitterPixelID;
		document.getElementById("twitterPixelID").readOnly = false;
		document.getElementById("btnTwitterPixel").disabled = false;
		document.getElementById("twitterPixeldocID").value = data.id;
	} else {
		document.getElementById("hasTwitterPixel").checked = false;
		document.getElementById("lblhasTwitterPixel").innerText = "Desligado";
		document.getElementById("twitterPixelID").value = data.twitterPixelID || "";
		document.getElementById("twitterPixelID").readOnly = true;
		document.getElementById("btnTwitterPixel").disabled = true;
		document.getElementById("twitterPixeldocID").value = data.id;
	}
	const formTwitterPixel = document.getElementById("edit-twiterpixel-form");

	formTwitterPixel.addEventListener("submit", (e) => {
		e.preventDefault();

		document.getElementById("btnTwitterPixel").innerHTML = `
            <div class="spinner-border spinner-border-sm text-success" role="status">
            <span class="sr-only">Carregando...</span>
            </div>Aguarde...`;

		const editTwitterPixel = functions.httpsCallable("editTwitterPixel");
		editTwitterPixel({
			twitterPixelID: formTwitterPixel.twitterPixelID.value,
			hasTwitterPixel: formTwitterPixel.hasTwitterPixel.checked,
			docid: formTwitterPixel.docid.value,
		})
			.then((result) => {
				console.log(result);
				modalAlert(
					"Twitter Pixel Configurado com Sucesso",
					"success",
					"link-remarketing.html"
				);
				document.getElementById("btnTwitterPixel").innerHTML = "Atualizar";
			})
			.catch((error) => {
				modalAlert(error);
				document.getElementById("btnTwitterPixel").innerHTML = "Atualizar";
				console.log(error);
			});
	});

	document.getElementById("hasTwitterPixel").addEventListener("change", (e) => {
		e.preventDefault();
		//console.log(e);
		if (document.getElementById("hasTwitterPixel").checked == true) {
			document.getElementById("lblhasTwitterPixel").innerText = "Ligado";
			document.getElementById("twitterPixelID").readOnly = false;
			document.getElementById("btnTwitterPixel").disabled = false;
		} else {
			document.getElementById("lblhasTwitterPixel").innerText = "Desligado";
			document.getElementById("twitterPixelID").readOnly = true;
			document.getElementById("btnTwitterPixel").disabled = false;
		}
	});
};

// setup Linktypes in link-types function
const setupLinkTypes = (data) => {
	const linkTypeList = document.getElementById("linkTypeList");
	//console.log(data);
	if (data != null) {
		//list options

		var listOptions = {
			valueNames: ["media", "description", "fallback", "android", "ios"],
		};

		/*,
      page: 10,
      pagination: true
  */

		// Init list
		var lCList = new List("linkTypeList", listOptions);

		data.forEach((doc) => {
			const link = doc.data();

			lCList.add({
				media: link.media,
				description: link.description,
				fallback: link.fallback.substr(0, 35),
				android: link.android.substr(0, 35),
				ios: link.ios.substr(0, 35),
			});
		});

		lCList.remove("media", "Aguarde...");
		// fix pagination problem
		var pageClass = document.querySelectorAll(".page");
		pageClass.forEach((item) => (item.href = "#!"));

		// Create clicks listener to edit a linkype

		const linkTypeRows = document.querySelectorAll(".linkTypeRows");
		const linkTypeIDCols = document.querySelectorAll(".linkTypeIDCols");

		if (linkTypeRows) {
			//alert('teste');
			for (var i = 0, l = linkTypeRows.length; i < l; i++) {
				(function (i) {
					linkTypeIDCols[i].onclick = function () {
						//modalAlert('linkType: ' + linkType[i].value + '/ Shorlink: ' + shortLinkID[i].value, "success");
						//alert('Element #' + i);
						//modalAlert(linkTypeIDCols[i].innerText, "warning");
						sessionStorage.setItem("linkType", linkTypeIDCols[i].innerText);
						//console.log (linkTypeIDCols[i].innerText);
						top.location.href = "linktype-edit.html";
					};
				})(i);
			}
		}

		//lastClicksList.innerHTML = tablePagheader + html + tablePagfooter;
	} else {
		linkTypeList.innerHTML = "Sem Cliques no momento";
	}
};

// setup SelectMedialinks
const setupSelectLinks = (data) => {
	if (data.length) {
		let html = "";
		html = '<option value="" disabled selected>Mídia Social</option>';
		data.forEach((doc) => {
			const link = doc.data();
			const li = `
      <option value="${link.media}">${link.media}</option>
      `;
			html += li;
		});
		linkTypes.innerHTML = html;
		//var elems = document.querySelectorAll('select');
		//var instances = M.FormSelect.init(elems, {});
		//console.log(html);
	} else {
		//linkTypes.innerHTML = '<h5 class="center-align">Faça Login para ver seus Links</h5>';
	}
};

// notification
const notification = document.querySelector(".notification");

const showNotification = (message) => {
	notification.textContent = message;
	notification.classList.add("active");
	setTimeout(() => {
		notification.classList.remove("active");
		notification.textContent = "";
	}, 4000);
};

// export clicks to excel

const exportClicksToExcel = document.getElementById("exportClicksToExcel");

if (exportClicksToExcel != null) {
	exportClicksToExcel.addEventListener("click", (e) => {
		//e.preventDefault();

		exportClicksToExcel.innerHTML = `
      <div class="spinner-border spinner-border-sm text-success" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
      Aguarde...`;
		exportClicksToExcel.disabled = true;

		axios({
			url: backEndBaseURL + "/clicksToExcel",
			method: "POST",
			data: {
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			},
			headers: {
				"Content-Type": "application/json",
			},
			responseType: "blob", // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "clicks.xlsx");
				document.body.appendChild(link);
				link.click();
				exportClicksToExcel.innerText = "Exportar Todos os Cliques";
				exportClicksToExcel.disabled = false;
				modalAlert("Arquivo Gerado com sucesso.", "success");
			})
			.catch((error) => {
				modalAlert(error);
				console.log(error);
				exportClicksToExcel.innerText = "Exportar Todos os Cliques";
				exportClicksToExcel.disabled = false;
			});
	});
}

const clicksbyRegionToExcel = (region, buttonID) => {
	const expButtonID = document.getElementById(buttonID);
	expButtonID.addEventListener("click", (e) => {
		//e.preventDefault();

		//console.log(data);

		expButtonID.innerHTML = `
      <div class="spinner-border spinner-border-sm text-success" role="status">
                            <span class="sr-only">Carregando...</span>
                          </div>
      Aguarde...`;
		expButtonID.disabled = true;

		//backEndBaseURL='http://localhost:5000/deeplink-b3800/us-central1';

		axios({
			url: backEndBaseURL + "/regionClicksToExcel",
			method: "POST",
			data: {
				region: region,
				linkType: sessionStorage.getItem("linkType"),
				shortLink: sessionStorage.getItem("shortLinkID"),
			},
			headers: {
				"Content-Type": "application/json",
			},
			responseType: "blob", // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", region + "-clicks.xlsx");
				document.body.appendChild(link);
				link.click();
				expButtonID.innerText = "Exportar";
				expButtonID.disabled = false;
				modalAlert("Arquivo Gerado com sucesso.", "success");
			})
			.catch((error) => {
				modalAlert(error);
				console.log(error);
				expButtonID.innerText = "Exportar";
				expButtonID.disabled = false;
			});
	});
};

import "./chart";

import {
	ArcElement,
	BarController,
	BarElement,
	BubbleController,
	CategoryScale,
	Chart,
	Decimation,
	DoughnutController,
	Filler,
	Legend,
	LineController,
	LineElement,
	LinearScale,
	LogarithmicScale,
	PieController,
	PointElement,
	PolarAreaController,
	RadarController,
	RadialLinearScale,
	ScatterController,
	TimeScale,
	TimeSeriesScale,
	Title,
	Tooltip,
} from "chart.js";
import { Firestore } from "firebase/firestore";

Chart.register(
	ArcElement,
	BarController,
	BarElement,
	BubbleController,
	CategoryScale,
	Decimation,
	DoughnutController,
	Filler,
	Legend,
	LinearScale,
	LineController,
	LineElement,
	LogarithmicScale,
	PieController,
	PointElement,
	PolarAreaController,
	RadarController,
	RadialLinearScale,
	ScatterController,
	TimeScale,
	TimeSeriesScale,
	Title,
	Tooltip
);

// Audience chart

const audienceChart = document.getElementById("audienceChart");

if (audienceChart) {
	new Chart(audienceChart, {
		type: "line",
		options: {
			scales: {
				yAxisOne: {
					display: "auto",
					grid: {
						color: "#283E59",
					},
					ticks: {
						callback: function (value) {
							return value + "k";
						},
					},
				},
				yAxisTwo: {
					display: "auto",
					grid: {
						color: "#283E59",
					},
					ticks: {
						callback: function (value) {
							return value + "%";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			datasets: [
				{
					label: "Customers",
					data: [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25, 40],
					yAxisID: "yAxisOne",
				},
				{
					label: "Sessions",
					data: [50, 75, 35, 25, 55, 87, 67, 53, 25, 80, 87, 45],
					yAxisID: "yAxisOne",
					hidden: true,
				},
				{
					label: "Conversion",
					data: [40, 57, 25, 50, 57, 32, 46, 28, 59, 34, 52, 48],
					yAxisID: "yAxisTwo",
					hidden: true,
				},
			],
		},
	});
}

// Convertions chart

const conversionsChart = document.getElementById("conversionsChart");

if (conversionsChart) {
	new Chart(conversionsChart, {
		type: "bar",
		options: {
			scales: {
				y: {
					ticks: {
						callback: function (val) {
							return val + "%";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Oct 1",
				"Oct 2",
				"Oct 3",
				"Oct 4",
				"Oct 5",
				"Oct 6",
				"Oct 7",
				"Oct 8",
				"Oct 9",
				"Oct 10",
				"Oct 11",
				"Oct 12",
			],
			datasets: [
				{
					label: "2020",
					data: [25, 20, 30, 22, 17, 10, 18, 26, 28, 26, 20, 32],
				},
				{
					label: "2019",
					data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22],
					backgroundColor: "#d2ddec",
					hidden: true,
				},
			],
		},
	});
}

// Traffic chart

const showTrafficChart = (data, chartElementId) => {
	const trafficChart = document.getElementById(chartElementId);
	new Chart(trafficChart, {
		type: "doughnut",
		options: {
			plugins: {
				tooltip: {
					callbacks: {
						afterLabel: function () {
							return "%";
						},
					},
				},
			},
		},
		data: {
			labels: data.legends,
			datasets: [
				{
					data: data.clicks,
					backgroundColor: [
						"#2C7BE5",
						"#A6C5F7",
						"#00008B",
						"#000000",
						"#FFCBDB",
						"#D2DDEC",
					],
				} /*,
        {
          data: [15, 45, 20],
          backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC'],
          hidden: true,
        },*/,
			],
		},
	});
};

//Chart.update();

/*

const trafficChart = document.getElementById('trafficChart');

if (trafficChart) {
  new Chart(trafficChart, {
    type: 'doughnut',
    options: {
      plugins: {
        tooltip: {
          callbacks: {
            afterLabel: function () {
              return '%';
            },
          },
        },
      },
    },
    data: {
      labels: ['Direct', 'Organic', 'Referral'],
      datasets: [
        {
          data: [60, 25, 15],
          backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC'],
        },
        {
          data: [15, 45, 20],
          backgroundColor: ['#2C7BE5', '#A6C5F7', '#D2DDEC'],
          hidden: true,
        },
      ],
    },
  });
}
*/

// Traffic chart (alt)

const trafficChartAlt = document.getElementById("trafficChartAlt");

if (trafficChartAlt) {
	new Chart(trafficChartAlt, {
		type: "doughnut",
		options: {
			plugins: {
				tooltip: {
					callbacks: {
						afterLabel: function () {
							return "%";
						},
					},
				},
			},
		},
		data: {
			labels: ["Direct", "Organic", "Referral"],
			datasets: [
				{
					data: [60, 25, 15],
					backgroundColor: ["#2C7BE5", "#A6C5F7", "#D2DDEC"],
				},
				{
					data: [15, 45, 20],
					backgroundColor: ["#2C7BE5", "#A6C5F7", "#D2DDEC"],
					hidden: true,
				},
			],
		},
	});
}

// Sales chart

const salesChart = document.getElementById("salesChart");

if (salesChart) {
	new Chart(salesChart, {
		type: "line",
		options: {
			scales: {
				y: {
					ticks: {
						callback: function (value) {
							return "$" + value + "k";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Oct 1",
				"Oct 3",
				"Oct 6",
				"Oct 9",
				"Oct 12",
				"Oct 5",
				"Oct 18",
				"Oct 21",
				"Oct 24",
				"Oct 27",
				"Oct 30",
			],
			datasets: [
				{
					label: "All",
					data: [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25],
				},
				{
					label: "Direct",
					data: [7, 40, 12, 27, 34, 17, 19, 30, 28, 32, 24],
					hidden: true,
				},
				{
					label: "Organic",
					data: [2, 12, 35, 25, 36, 25, 34, 16, 4, 14, 15],
					hidden: true,
				},
			],
		},
	});
}

// Orders chart

const ordersChart = document.getElementById("ordersChart");

if (ordersChart) {
	new Chart(ordersChart, {
		type: "bar",
		options: {
			scales: {
				y: {
					ticks: {
						callback: function (value) {
							return "$" + value + "k";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			datasets: [
				{
					label: "Sales",
					data: [25, 20, 30, 22, 17, 10, 18, 26, 28, 26, 20, 32],
				},
				{
					label: "Affiliate",
					data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22],
					backgroundColor: "#d2ddec",
					hidden: true,
				},
			],
		},
	});
}

// Earnings chart

const earningsChart = document.getElementById("earningsChart");

if (earningsChart) {
	new Chart(earningsChart, {
		type: "bar",
		options: {
			scales: {
				yAxisOne: {
					display: "auto",
					ticks: {
						callback: function (value) {
							return "$" + value + "k";
						},
					},
				},
				yAxisTwo: {
					display: "auto",
					ticks: {
						callback: function (value) {
							return value + "k";
						},
					},
				},
				yAxisThree: {
					display: "auto",
					ticks: {
						callback: function (value) {
							return value + "%";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
			],
			datasets: [
				{
					label: "Earnings",
					data: [
						18, 10, 5, 15, 10, 20, 15, 25, 20, 26, 25, 29, 18, 10, 5, 15, 10,
						20,
					],
					yAxisID: "yAxisOne",
				},
				{
					label: "Sessions",
					data: [
						50, 75, 35, 25, 55, 87, 67, 53, 25, 80, 87, 45, 50, 75, 35, 25, 55,
						19,
					],
					yAxisID: "yAxisTwo",
					hidden: true,
				},
				{
					label: "Bounce",
					data: [
						40, 57, 25, 50, 57, 32, 46, 28, 59, 34, 52, 48, 40, 57, 25, 50, 57,
						29,
					],
					yAxisID: "yAxisThree",
					hidden: true,
				},
			],
		},
	});
}

// Weekly hours chart

const weeklyHoursChart = document.getElementById("weeklyHoursChart");

if (weeklyHoursChart) {
	new Chart(weeklyHoursChart, {
		type: "bar",
		options: {
			scales: {
				y: {
					ticks: {
						callback: function (value) {
							return value + "hrs";
						},
					},
				},
			},
		},
		data: {
			labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
			datasets: [
				{
					data: [21, 12, 28, 15, 5, 12, 17, 2],
				},
			],
		},
	});
}

// Overview chart

const overviewChart = document.getElementById("overviewChart");

if (overviewChart) {
	new Chart(overviewChart, {
		type: "line",
		options: {
			scales: {
				yAxisOne: {
					display: "auto",
					ticks: {
						callback: function (value) {
							return "$" + value + "k";
						},
					},
				},
				yAxisTwo: {
					display: "auto",
					ticks: {
						callback: function (value) {
							return value + "hrs";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			datasets: [
				{
					label: "Earned",
					data: [0, 10, 5, 15, 10, 20, 15, 25, 20, 30, 25, 40],
					yAxisID: "yAxisOne",
				},
				{
					label: "Hours Worked",
					data: [7, 35, 12, 27, 34, 17, 19, 30, 28, 32, 24, 39],
					yAxisID: "yAxisTwo",
					hidden: true,
				},
			],
		},
	});
}

// Sparkline chart

const sparklineChart = document.getElementById("sparklineChart");

if (sparklineChart) {
	new Chart(sparklineChart, {
		type: "line",
		options: {
			scales: {
				y: {
					display: false,
				},
				x: {
					display: false,
				},
			},
			elements: {
				line: {
					borderWidth: 2,
				},
				point: {
					hoverRadius: 0,
				},
			},
			plugins: {
				tooltip: {
					external: () => false,
				},
			},
		},
		data: {
			labels: new Array(12).fill("Label"),
			datasets: [
				{
					data: [0, 15, 10, 25, 30, 15, 40, 50, 80, 60, 55, 65],
				},
			],
		},
	});
}

// Sparkline chart (gray)

const sparklineCharts = document.querySelectorAll(
	"#sparklineChartSocialOne, #sparklineChartSocialTwo, #sparklineChartSocialThree, #sparklineChartSocialFour"
);

if (sparklineCharts) {
	[].forEach.call(sparklineCharts, function (chart) {
		new Chart(chart, {
			type: "line",
			options: {
				scales: {
					y: {
						display: false,
					},
					x: {
						display: false,
					},
				},
				elements: {
					line: {
						borderWidth: 2,
						borderColor: "#D2DDEC",
					},
					point: {
						hoverRadius: 0,
					},
				},
				plugins: {
					tooltip: {
						external: () => false,
					},
				},
			},
			data: {
				labels: new Array(12).fill("Label"),
				datasets: [
					{
						data: [0, 15, 10, 25, 30, 15, 40, 50, 80, 60, 55, 65],
					},
				],
			},
		});
	});
}

// Feed chart

const feedChart = document.getElementById("feedChart");

if (feedChart) {
	new Chart(feedChart, {
		type: "bar",
		options: {
			scales: {
				y: {
					ticks: {
						callback: function (value) {
							return "$" + value + "k";
						},
					},
				},
			},
		},
		data: {
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			datasets: [
				{
					label: "Sales",
					data: [25, 20, 30, 22, 17, 10, 18, 26, 28, 26, 20, 32],
				},
				{
					label: "Affiliate",
					data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22],
					backgroundColor: "#d2ddec",
					hidden: true,
				},
			],
		},
	});
}

/// WOULD BE LINKS.js

//old Linktype Selection mode

const linkSelect = document.getElementById("linkTypeInput");

if (linkSelect) {
	linkSelect.addEventListener("keyup", (event) => {
		const linkTypeChoose = document.getElementById("linkTypeChoose");

		if (event.target.value.length > 2) {
			document.querySelector(".afterSelectMedia").innerHTML = "";
			const searchText = event.target.value.toLowerCase();
			//let html = '';
			//alert(searchText);
			// db.collection("linkTypes")
			// 	.orderBy("descriptionLowerCase")
			// 	.startAt(searchText)
			// 	.endAt(searchText + "\uf8ff")
			// 	.get()
			getLinkTypesByBaseDesc(searchText)
				.then(
					(snapshot) => {
						//const Linkmedia = snapshot.docs[0].data();
						let html = "";
						let img;

						snapshot.forEach((doc) => {
							const link = doc.data();
							if (imageExists("/assets/img/icons/" + doc.id + ".png"))
								img = '<img src="/assets/img/icons/' + doc.id + '.png" />';
							else img = '<span class="avatar-title rounded">logo</span>';

							const li = `
              <div class="row rowlinktypeToClick">
              <div class="col">
                <a href="#" class="linktypeToClick">
                  <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Heading -->
                          <span class="h2 mb-0 linktypeDescText">
                          ${link.description}
                          </span>
                        </div>
                        <input type="hidden" class="linktypeIDToClick" value="${doc.id}" />
                        <div class="col-auto">
                          <div class="avatar avatar-xl">
                            ${img}
                          </div>
                        </div>
                      </div> <!-- / .row -->
                    </div>
                    
                  </div>
                </a>
              </div>
              </div>
              `;
							html += li;
						});

						//console.log(Linkmedia);
						linkTypeChoose.innerHTML = html + "";

						enableLinktypeClick();
					},
					(err) => console.log(err.message)
				);

			//console.log(data);
		}
	});
}

//new type using http entire link

const httpLinkNew = document.getElementById("httpLinkNew");

if (httpLinkNew) {
	/*
    document.getElementById("btnUrlPaste").addEventListener("click", (e) => {
      //e.preventDefault();
      const text = navigator.clipboard.readText();
      httpLinkNew.value = text;

    });
    */

	httpLinkNew.addEventListener(
		"keyup",
		debounce(async (e) => {
			await validateNewLink();
		}, 2000)
	);
	//event.preventDefault();
}

async function validateNewLink() {
	document.getElementById("linkTypeChooseNew").innerHTML = "";
	document.getElementById("createLink").innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;
	document.getElementById("httpLinkNew").classList.remove("is-invalid");
	document.getElementById("httpLinkNew").classList.remove("is-valid");
	document.getElementById("linkErrorFeedBack").innerText =
		"Favor Fornecer um link válido";

	let searchText = document.getElementById("httpLinkNew").value;
	if (document.getElementById("httpLinkNew").value.indexOf(" ") !== -1) {
		document.getElementById("afterSelectMedia").innerHTML = "";
		document.getElementById("linkTypeChooseNew").innerHTML = "";
		document.getElementById("httpLinkNew").classList.add("is-invalid");
		document.getElementById("linkErrorFeedBack").innerText =
			"Seu Link contém espaços. Favor removê-los";
		document.getElementById("createLink").innerHTML = "Criar Link";
		document.getElementById("createLink").disabled = true;
	} else if (document.getElementById("httpLinkNew").value.length > 8) {
		document.getElementById("afterSelectMedia").innerHTML = "";

		//let html = '';
		//alert(searchText);
		const getLinkTypebyLinkHttp = functions.httpsCallable(
			"getLinkTypebyLinkHttp"
		);

		getLinkTypebyLinkHttp({
			linkHttps: searchText,
		})
			.then(async (result) => {
				if (result.data) {
					/*if (result.data.linkType!='whatsapp')
                searchText=searchText.split('?')[1];*/

					await enableafterSelectMediaNew(result.data, searchText);
				} else {
					document.getElementById("afterSelectMedia").innerHTML = "";
					document.getElementById("linkTypeChooseNew").innerHTML = "";
					document.getElementById("httpLinkNew").classList.add("is-invalid");
					document.getElementById("linkErrorFeedBack").innerText =
						"Link inválido ou não encontrado.";
					document.getElementById("createLink").innerHTML = "Criar Link";
					document.getElementById("createLink").disabled = true;
				}
			})
			.catch((error) => {
				modalAlert(error.message);
				console.log(error);
			}); //
	} else {
		document.getElementById("afterSelectMedia").innerHTML = "";
		document.getElementById("linkTypeChooseNew").innerHTML = "";
		document.getElementById("httpLinkNew").classList.add("is-invalid");
		document.getElementById("createLink").innerHTML = "Criar Link";
		document.getElementById("createLink").disabled = true;

	}
}

function enableLinktypeClick() {
	// Link Type click on New Link

	//alert('passou aqui');

	var linktypeToClickElems = document.querySelectorAll(".linktypeToClick");

	if (linktypeToClickElems != null) {
		//alert('teste');

		//var shortLinkID = document.querySelectorAll('.shortLinkID');
		var linkTypeIDClick = document.querySelectorAll(".linktypeIDToClick"); //enableLinktypeClick
		var linktypeDescText = document.querySelectorAll(".linktypeDescText");

		for (var i = 0, l = linktypeToClickElems.length; i < l; i++) {
			(function (i) {
				linktypeToClickElems[i].onclick = function () {
					//modalAlert('linkType: ' + linkType[i].value + '/ Shorlink: ' + shortLinkID[i].value, "success");

					enableafterSelectMedia(linkTypeIDClick[i].value); //linktypeDescText
					//linktypeDescText[i].innerHTML=linktypeDescText[i].innerText + ' (Selecionado)';

					var rowlinktypeToClick = document.querySelectorAll(
						".rowlinktypeToClick"
					);

					for (var j = 0; j < rowlinktypeToClick.length; j++) {
						if (i != j) rowlinktypeToClick[j].remove();
					}

					//rowlinktypeToClick
					/*
            //let jsarray = [linkType[i].value, shortLinkID[i].value];
            sessionStorage.setItem("linkType", linkType[i].value);
            sessionStorage.setItem("shortLinkID", shortLinkID[i].value);
            

       
            */
				};
			})(i);
		}
	}
}

function enableafterSelectMedia(linkTypeID) {
	const afterSelectMedia = document.querySelector(".afterSelectMedia");

	//let html = '';
	// db.collection("linkTypes")
	// 	.where("media", "==", linkTypeID)
	// 	.get()
	getLinkTypesByMedia(linkTypeID)
		.then(
			(snapshot) => {
				const Linkmedia = snapshot.docs[0].data();

				var html = `
                    <div class="form-group">
                    <!-- Label  -->
                    <label class="form-label">
                      Descrição
                    </label>

                    <!-- Text -->
                    <small class="form-text text-muted">
                      Nome amigável para este link
                    </small>

                    <!-- Input -->
                    <input type="text"  id="description" name="description" class="form-control" required>
                    </div>
                    <div class="form-group">
                      <!-- Label  -->
                      <label class="form-label">
                        ID do Link
                      </label>

                      <!-- Text -->
                      <small class="form-text text-muted">
                        Seu ID do link Ex:${Linkmedia.fallback
													.replace("uLinkId", "<b>ID</b>")
													.replace(
														"LuserID",
														"userID"
													)} (Somente conteúdo em <b>negrito</b>)
                      </small>

                      <!-- Input -->
                      <input type="hidden" name="linkType" value="${linkTypeID}"> 
                      <input type="text"  id="uLinkId" name="uLinkId" class="form-control" required>
                    </div>`;

				if (Linkmedia.LuserID) {
					html =
						html +
						`
                <div class="form-group">
                  <!-- Label  -->
                  <label class="form-label">
                    ID do Usuário
                  </label>

                  <!-- Text -->
                  <small class="form-text text-muted">
                    Seu ID do link Ex:${Linkmedia.fallback.replace(
											"LuserID",
											"<b>userID</b>"
										)} (Somente conteúdo em <b>negrito</b>)
                  </small>

                  <!-- Input -->
                  <input type="text"  id="LuserID" name="LuserID" class="form-control" required>
                </div>`;
				}

				html =
					html +
					`

                    <div class="row">
                      <div class="col-12 col-md-6">
                        <!-- First Collum-->
                        <div class="form-group">
                        <!-- Label -->
                        ${linkDomain}${Linkmedia.media}/
                        </div>
                      </div>
                    
                      <div class="col-12 col-md-6">
                        <!-- Second  Collum -->
                        <div class="form-group">
                        <!-- Input -->
                        <input type="text"  id="shortLink" name="shortLink" class="form-control" value="` +
					makeid(4) +
					`" required>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" style="display:block">
                    <!-- Label  -->
                    <label class="form-label"  style="display:block">
                      Link Android
                    </label>
                 
                    <!-- Input -->
                    <input type="text"  id="android" name="android" class="form-control" value="${Linkmedia.android}" disabled  style="display:block">
                    </div>

                    <div class="form-group" style="display:block">
                    <!-- Label  -->
                    <label class="form-label" style="display:block">
                      Link iOS
                    </label>
                   
                    <!-- Input -->
                    <input type="text"  id="ios" name="ios" class="form-control" value="${Linkmedia.ios}" disabled  style="display:block">
                    </div>

                    <div class="form-group">
                    <!-- Label  -->
                    <label class="form-label">
                      Link Http (web)
                    </label>
                   
                    <!-- Input -->
                    <input type="text"  id="fallback" name="fallback" class="form-control" value="${Linkmedia.fallback}" disabled>
                    </div>
                    
                    `;

				//console.log(Linkmedia);
				afterSelectMedia.innerHTML = html;
				//M.updateTextFields();
				//M.CharacterCounter.init(document.querySelectorAll('.has-character-counter'));
				listenuLinkId(linkTypeID);

				if (Linkmedia.LuserID)
					//tiktok expection
					listenLuserID(linkTypeID);

				document.getElementById("createLink").disabled = false;
				document.getElementById("uLinkId").focus();
			},
			(err) => console.log(err.message)
		);
}

async function enableafterSelectMediaNew(data, searchText) {
	//modalAlert('Link Criado com Sucesso','success','links.html');
	//console.log(data);
	let Linkmedia = data;
	let img = "";
	let uLinkId = "";
	//const uLinkId= searchText.split(Linkmedia.media.fallback)[1];
	if (Linkmedia.media == "site") {
		uLinkId = searchText;
	} else if (Linkmedia.media == "instagramlive") {
		let searchTextSplit = searchText.split("/");
		console.log(searchTextSplit);
		Linkmedia.https.forEach((lnkHttps) => {
			let checkLinkHttps = lnkHttps.split("/");
			if (
				checkLinkHttps[2] == searchTextSplit[2] &&
				checkLinkHttps[4] == searchTextSplit[4]
			) {
				//alert('passou aqui');
				
				uLinkId = searchTextSplit[3];
			}
		});
	} else {
		//ifood id missing check
		if (Linkmedia.media=="ifood")
			searchText = await getFinalURL(searchText);

		Linkmedia.https.forEach((lnkHttps) => {
			let testLinkhttps = searchText.split(lnkHttps);
			if (testLinkhttps.length > 1) {
				//alert('passou aqui');
				//console.log(searchText);
				uLinkId = testLinkhttps[1];
			}
		});
	}
	//console.log(uLinkId);
	// if (Linkmedia.media=="ifood"){
	// 	let finalURL="";
	// 	try {
	// 		finalURL = await getFinalURL(document.getElementById("fallback").value);
	// 		//console.log(finalURL);
	// 	} catch (error) {
			
	// 	}
	// 	document.getElementById("fallback").value = finalURL;
	// 	console.log(document.getElementById("fallback").value);
	// }

	//console.log(uLinkId);
	if (Linkmedia.media != "zoom" && Linkmedia.media != "site") uLinkId = uLinkId.split("?")[0];

	uLinkId = uLinkId.endsWith("/") ? uLinkId.slice(0, -1) : uLinkId;
	//console.log(uLinkId);

	if (imageExists("/assets/img/icons/" + Linkmedia.media + ".png"))
		img =
			'<img src="/assets/img/icons/' +
			Linkmedia.media +
			'.png" class="avatar-img" />';
	else img = '<span class="avatar-title rounded">logo</span>';

	let li = `
  <div class="row">
  <h3 class="text-dark text-center">
  Informações do link
  </h3>
  <div class="col-sm"></div>
  <div class="col-auto rounded">
      <div class="card border">
        <div class="card-body">
          <div class="row align-items-center">
          <div class="col-auto">
          <div class="avatar avatar-sm">
            ${img}
          </div>
          </div>
            <div class="col">
              <!-- Heading -->
              <span class="h2 mb-0 linktypeDescText">
              ${Linkmedia.description}
              </span>
            </div>
          </div> <!-- / .row -->
        </div>
        
      </div>
  </div>
  <div class="col-sm"></div>
  </div>
  <hr class="mt-2">
  <div class="row">
    <div class="col text-center">
      <h3 class="text-dark">
      Comportamento do seu link
      </h3>
      <h4 class="text-muted mb-4">
      O que acontecerá ao acessarem esse link em dispositivos mobile?
      </h4>
      
    </div>
  </div>
  
  `;

	//console.log(Linkmedia.android.substring(0,4));

	if (Linkmedia.android.substring(0, 4) == "http"||Linkmedia.media=="site") {
		li += `
  <div class="row text-center">
    <div class="col-12 col-lg-6 col-xl">


      <!-- Hours -->
      <div class="card">
        
        <div class="card-body bg-gray-200 rounded border border-dark">
          <div class="row align-items-center">
            <div class="col">

              <!-- Title -->
              <h4 class="mb-3">
                Aplicativo Sem Deeplink
              </h6>

              <!-- Heading -->
              <span class="mb-0">
                Esse aplicativo não tem suporte para deeplink e será aberto no navegador.
                Dependendo as configurações do cliente/usuário ele pode ser redirecionado para o aplicativo.
              </span>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>

    </div>
  
    
  </div>
  `;
	} else {
		li += `
  <div class="row">
    <div class="col-12 col-lg-6 col-xl">


      <!-- Hours -->
      <div class="card">
        
        <div class="card-body bg-gray-200 rounded border border-dark">
          <div class="row align-items-center">
            <div class="col">

              <!-- Title -->
              <h4 class="mb-3">
                Usuário tem o app instalado
              </h6>

              <!-- Heading -->
              <span class="mb-0">
                A pessoa será encaminhada diretamente à página específica dentro do app.
              </span>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6 col-xl">

      <!-- Exit -->
      <div class="card">
        <div class="card-body bg-gray-200 rounded border border-dark">
          <div class="row align-items-center">
            <div class="col">

              <!-- Title -->
              <h4 class="mb-3">
                Usuário não tem o app instalado
              </h6>

              <!-- Heading -->
              <span class="mb-0">
              Neste caso o link será aberto no navegador principal do dispositivo.
              </span>

            </div>
          </div> <!-- / .row -->
        </div>
      </div>

    </div>
    
  </div>
  `;
	}

	document.getElementById("linkTypeChooseNew").innerHTML = li;


	var html =
		`

    <hr class="mt-2">
  <div class="row">
    <div class="col text-center">
      <h3 class="text-dark">
      Configure seu link
      </h3>
      <h4 class="text-muted mb-4">
      Personalize no campo abaixo o final do seu link
      </h4>
      
    </div>
  </div>
  <div class="row">
  <div class="col-12 col-md-6 text-lg-right text-center">
    <!-- First Collum-->
    
    <div class="form-group">
    <!-- Label -->
    ${linkDomain}${Linkmedia.media}/
    </div>
  </div>

  <div class="col-12 col-md-6">
    <!-- Second  Collum -->
    <div class="form-group">
    <!-- Input -->
    <input type="text"  id="shortLink" name="shortLink" class="form-control" value="` +
		makeid(4) +
		`" required>
    <div class="invalid-feedback" id="shortLinkError">
    Favor Fornecer um link válido
    </div>
    </div>
  </div>
</div>
        
        <div class="form-group text-center">
        <h4 class="text-muted mb-4">
        Crie uma descrição para facilitar a busca dentro da dashboard
        </h4>

        <!-- Input -->
        <input type="text"  id="description" name="description" class="form-control" placeholder="Nome amigável para este link" required>
        </div>
        <div class="form-group">
          <!-- Label  
          <label class="form-label">
            ID do Link
          </label>-->

          <!-- Text 
          <small class="form-text text-muted">
            Seu ID do link Ex:${Linkmedia.fallback
							.replace("uLinkId", "<b>ID</b>")
							.replace(
								"LuserID",
								"userID"
							)} (Somente conteúdo em <b>negrito</b>)
          </small>-->

          <!-- Input -->
          <input type="hidden" name="linkType" value="${Linkmedia.media}"> 
          <input type="hidden"  id="uLinkId" name="uLinkId" class="form-control" value="${uLinkId}" required>
        </div>`;

	html =
		html +
		`


        <div class="form-group" style="display:none">
        <!-- Label  -->
        <label class="form-label">
          Link Android
        </label>
    
        <!-- Input -->
        <input type="text"  id="android" name="android" class="form-control" value="${Linkmedia.android.replace(
					"uLinkId",
					uLinkId
				)}" disabled>
        </div>

        <div class="form-group"  style="display:none">
        <!-- Label  -->
        <label class="form-label">
          Link iOS
        </label>
      
        <!-- Input -->
        <input type="text"  id="ios" name="ios" class="form-control" value="${Linkmedia.ios.replace(
					"uLinkId",
					uLinkId
				)}" disabled>
        </div>

        <div class="form-group" style="display:none">
        <!-- Label  -->
        <label class="form-label">
          Link Http (web)
        </label>
      
        <!-- Input -->
        <input type="text"  id="fallback" name="fallback" class="form-control" value="${Linkmedia.fallback.replace(
					"uLinkId",
					uLinkId
				)}" disabled>
        </div>
        
        `;

	document.getElementById("afterSelectMedia").innerHTML = html;

	Inputmask({ regex: "^[A-Za-z0-9_-]*$" }).mask(
		document.getElementById("shortLink")
	);

	

	document.getElementById("createLink").innerHTML = "Criar Link";
	document.getElementById("createLink").disabled = false;
	document.getElementById("httpLinkNew").classList.add("is-valid");
	//fix ifood link
	
	//console.log(Linkmedia);
	await fixuLinkId(Linkmedia);
}

if (linkList != null) {
	//linkList.innerHTML = `Aguarde...`;
	//setupLinks(snapshot);

	const testeOnClick = document.querySelectorAll(".actLink");

	if (testeOnClick != null) {
		//alert('teste');
		testeOnClick.forEach((item) =>
			item.addEventListener("click", (e) => {
				e.preventDefault();

				modalAlert("Função disponível em breve", "warning");
			})
		);
	}
} // End link list

function listenuLinkId(linkTypeID) {
	const upduLinkId = document.getElementById("uLinkId");

	if (upduLinkId) {
		upduLinkId.addEventListener("keyup", async (e) => {
			e.preventDefault();

			let mediaLink = linkTypeID;

			let upduLinkIdText = "";

			//alert(mediaLink);

			if (mediaLink.length) {
				let html = "";
				// db.collection("linkTypes")
				// 	.where("media", "==", mediaLink)
				// 	.get()
				getLinkTypesByMedia(mediaLink)
					.then(
						(snapshot) => {
							const Linkmedia = snapshot.docs[0].data();

							//check if its rappi to change UlinkId

							/*
              if (mediaLink=='rappi'){
                upduLinkIdText=upduLinkId.value.split("-")[0];
              }else{
                upduLinkIdText=upduLinkId.value
              }
              */

							document.getElementById("android").value =
								Linkmedia.android.replace("uLinkId", upduLinkId.value);
							document.getElementById("ios").value = Linkmedia.ios.replace(
								"uLinkId",
								upduLinkId.value
							);
							document.getElementById("fallback").value =
								Linkmedia.fallback.replace("uLinkId", upduLinkId.value);

							if (mediaLink == "tiktoksong") {
								let ttsongLink = upduLinkId.value.split("-");
								document.getElementById("android").value =
									Linkmedia.android.replace(
										"uLinkId",
										ttsongLink[ttsongLink.length - 1]
									);
							}

							if (mediaLink == "iheart" || mediaLink == "iheartartist") {
								let iHeartMusicID = upduLinkId.value.split("-");
								document.getElementById("android").value =
									Linkmedia.android.replace(
										"uLinkId",
										iHeartMusicID[iHeartMusicID.length - 1]
									);
								document.getElementById("ios").value = Linkmedia.ios.replace(
									"uLinkId",
									iHeartMusicID[iHeartMusicID.length - 1]
								);
							}
							//console.log(Linkmedia);
							//afterSelectMedia.innerHTML = html
							//M.updateTextFields();
							//M.CharacterCounter.init(document.querySelectorAll('.has-character-counter'));
							//document.getElementById("uLinkId").focus();

							//set var to button on add ou edit link

							let btnLinkActs, btnLinkActsTxt;

							if (document.getElementById("createLink")) {
								btnLinkActs = document.getElementById("createLink");
								btnLinkActsTxt = "Criar Link";
							} else {
								btnLinkActs = document.getElementById("btnedtLink");
								btnLinkActsTxt = "Atualizar Link";
							}
							if (mediaLink == "tiktokuser") {
							}
							if (mediaLink == "ifood") {
								//alert(upduLinkId.value);
								let uuidIfood = upduLinkId.value.split("/");
								//console.log(Linkmedia.ios.replace('uLinkId', uuidIfood[uuidIfood.length-1]));
								document.getElementById("ios").value = Linkmedia.ios.replace(
									"uLinkId",
									uuidIfood[uuidIfood.length - 1]
								);
							}
							if (mediaLink == "tiktokvideo") {
								let uuidTikTok = upduLinkId.value.split("/");
								document.getElementById("android").value =
									Linkmedia.android.replace(
										"uLinkId",
										uuidTikTok[uuidTikTok.length - 1]
									);
								document.getElementById("ios").value = Linkmedia.ios.replace(
									"uLinkId",
									uuidTikTok[uuidTikTok.length - 1]
								);
							}
							if (mediaLink == "twstatus") {
								let uuidTwitter = upduLinkId.value.split("/");
								document.getElementById("ios").value = Linkmedia.ios.replace(
									"uLinkId",
									uuidTwitter[uuidTwitter.length - 1]
								);
							}

							if (
								mediaLink == "facebook" ||
								mediaLink == "facegroup" ||
								mediaLink == "facepage"
							) {
								var btnAddLinkText = "";

								switch (mediaLink) {
									case "facebook":
										btnAddLinkText = "usuário";
										break;
									case "facegroup":
										btnAddLinkText = "grupo";
										break;
									case "facepage":
										btnAddLinkText = "página";
										break;
								}

								btnLinkActs.disabled = true;
								btnLinkActs.innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;

								const params = new FormData();
								params.append(
									mediaLink,
									document.getElementById("uLinkId").value
								);
								axios
									.post("https://site.appclick.me/facecraw.php", params)
									.then((response) => {
										//console.log(response.data);

										if (response.data != "not found") {
											//document.getElementById('android').value = Linkmedia.android.replace('uLinkId', iHeartMusicID[iHeartMusicID.length-1]);
											document.getElementById("ios").value =
												Linkmedia.ios.replace("uLinkId", response.data);
											btnLinkActs.innerHTML = btnLinkActsTxt;
											btnLinkActs.disabled = false;
										} else {
											btnLinkActs.innerHTML =
												"Colocar " +
												btnAddLinkText +
												" de facebook válido(a) para criar link";
										}
										// modalAlert('Arquivo Gerado com sucesso.','success');
									})
									.catch((error) => {
										modalAlert(error);
										console.log(error);
									});
							}

							if (
								mediaLink == "instagramp" ||
								mediaLink == "igtv" ||
								mediaLink == "instagramreel"
							) {
								var btnAddLinkText = "";

								switch (mediaLink) {
									case "instagramp":
										btnAddLinkText = "post";
										break;
									case "igtv":
										btnAddLinkText = "vídeo";
										break;
									case "instagramreel":
										btnAddLinkText = "reel";
										break;
								}

								btnLinkActs.disabled = true;
								btnLinkActs.innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;

								let finalInstaUrl = "";

								if (
									document.getElementById("httpLinkNew").value.slice(-1) == "/"
								)
									finalInstaUrl = document
										.getElementById("httpLinkNew")
										.value.slice(0, -1);
								else
									finalInstaUrl = document.getElementById("httpLinkNew").value;

								const getInstagramInfo =
									functions.httpsCallable("getInstagramInfo");
								getInstagramInfo({
									url: finalInstaUrl,
								})
									.then((result) => {
										

										if (result.data) {
											const instaMediaID =
												result.data.graphql.shortcode_media.id;
											//document.getElementById('android').value = Linkmedia.android.replace('uLinkId', iHeartMusicID[iHeartMusicID.length-1]);
											document.getElementById("ios").value =
												Linkmedia.ios.replace("uLinkId", instaMediaID);
											//console.log(document.getElementById('ios').value);
											btnLinkActs.innerHTML = btnLinkActsTxt;
											btnLinkActs.disabled = false;
										} else {
											btnLinkActs.innerHTML =
												"Colocar " +
												btnAddLinkText +
												" de instagram válido(a) para criar link";
										}
										// modalAlert('Arquivo Gerado com sucesso.','success');
									})
									.catch((error) => {
										//modalAlert(error);
										console.log(error);
										// btnLinkActs.innerHTML =
										// 	"Colocar " +
										// 	btnAddLinkText +
										// 	" de instagram válido(a) para criar link";
										document.getElementById("ios").value =finalInstaUrl;
										//console.log(document.getElementById('ios').value);
										btnLinkActs.innerHTML = btnLinkActsTxt;
										btnLinkActs.disabled = false;
										console.log(error);
									});
							}

							if (mediaLink == "ubereats") {
								var btnAddLinkText = "";

								switch (mediaLink) {
									case "ubereats":
										btnAddLinkText = "post";
										break;
								}

								btnLinkActs.disabled = true;
								btnLinkActs.innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;

								const params = new FormData();
								params.append(
									mediaLink,
									document.getElementById("uLinkId").value
								);
								axios
									.post("https://site.appclick.me/uberEatscraw.php", params)
									.then((response) => {
										//console.log(response.data.toString());

										if (response.data != "not found") {
											const instaUberEatsID = response.data.replace(/'/g, "");
											//document.getElementById('android').value = Linkmedia.android.replace('uLinkId', iHeartMusicID[iHeartMusicID.length-1]);
											document.getElementById("ios").value =
												Linkmedia.ios.replace("uLinkId", instaUberEatsID);
											document.getElementById("android").value =
												Linkmedia.android.replace("uLinkId", instaUberEatsID);
											btnLinkActs.innerHTML = btnLinkActsTxt;
											btnLinkActs.disabled = false;
										} else {
											btnLinkActs.innerHTML =
												"Colocar link de Uber Eats válido(a) para criar link";
										}
										// modalAlert('Arquivo Gerado com sucesso.','success');
									})
									.catch((error) => {
										modalAlert(error);
										console.log(error);
									});
							}
						},
						(err) => console.log(err.message)
					);
			} else {
				//linkList.innerHTML = '<h5 class="center-align">Faça Login para ver seus Links</h5>';
			}
		});
	}
}

async function fixuLinkId(Linkmedia) {
	console.log(Linkmedia);

	const upduLinkId = document.getElementById("uLinkId");

	const mediaLink = Linkmedia.media || Linkmedia.linkType;

	if (mediaLink != "whatsapp" && mediaLink != "zoom" && mediaLink != "site") {
		let newUlinkIdval = upduLinkId.value.split("?")[0];
		newUlinkIdval = newUlinkIdval.endsWith("/")
			? newUlinkIdval.slice(0, -1)
			: newUlinkIdval;

		document.getElementById("uLinkId").value = newUlinkIdval;
	}

	//alert(upduLinkId.value);

	if (mediaLink.length) {
		//const Linkmedia = snapshot.docs[0].data();

		if (mediaLink == "tiktoksong") {
			let ttsongLink = upduLinkId.value.split("-");
			document.getElementById("android").value = Linkmedia.android.replace(
				"uLinkId",
				ttsongLink[ttsongLink.length - 1]
			);
		}

		if (mediaLink == "iheart" || mediaLink == "iheartartist") {
			let iHeartMusicID = upduLinkId.value.split("-");
			document.getElementById("android").value = Linkmedia.android.replace(
				"uLinkId",
				iHeartMusicID[iHeartMusicID.length - 1]
			);
			document.getElementById("ios").value = Linkmedia.ios.replace(
				"uLinkId",
				iHeartMusicID[iHeartMusicID.length - 1]
			);
		}

		//console.log(Linkmedia);
		//afterSelectMedia.innerHTML = html
		//M.updateTextFields();
		//M.CharacterCounter.init(document.querySelectorAll('.has-character-counter'));
		//document.getElementById("uLinkId").focus();

		//set var to button on add ou edit link

		let btnLinkActs, btnLinkActsTxt;

		if (document.getElementById("createLink")) {
			btnLinkActs = document.getElementById("createLink");
			btnLinkActsTxt = "Criar Link";
		} else {
			btnLinkActs = document.getElementById("btnedtLink");
			btnLinkActsTxt = "Atualizar Link";
		}
		if (mediaLink == "tiktokuser") {
		}
		if (mediaLink == "ifood") {
			//alert(upduLinkId.value);
			
			

			
			let uuidIfood = upduLinkId.value.split("/");
			//console.log(uuidIfood[uuidIfood.length - 1]);
			
			//console.log(Linkmedia.ios.replace('uLinkId', uuidIfood[uuidIfood.length-1]));
			document.getElementById("ios").value = 
				"ifood://restaurant/"+uuidIfood[uuidIfood.length - 1]
			;
			//console.log(document.getElementById("ios").value);
		}
		if (mediaLink == "tiktok") {
			let uuidTikTok = upduLinkId.value.split("/");
			document.getElementById("android").value = Linkmedia.android.replace(
				"uLinkId",
				uuidTikTok[uuidTikTok.length - 1]
			);
			document.getElementById("ios").value = Linkmedia.ios.replace(
				"uLinkId",
				uuidTikTok[uuidTikTok.length - 1]
			);
		}
		if (mediaLink == "twstatus") {
			let uuidTwitter = upduLinkId.value.split("/");
			document.getElementById("ios").value = Linkmedia.ios.replace(
				"uLinkId",
				uuidTwitter[uuidTwitter.length - 1]
			);
		}

		if (
			mediaLink == "facebook" ||
			mediaLink == "facegroup" ||
			mediaLink == "facepage"
		) {
			var btnAddLinkText = "";

			switch (mediaLink) {
				case "facebook":
					btnAddLinkText = "usuário";
					break;
				case "facegroup":
					btnAddLinkText = "grupo";
					break;
				case "facepage":
					btnAddLinkText = "página";
					break;
			}

			btnLinkActs.disabled = true;
			btnLinkActs.innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;

			const params = new FormData();
			params.append(mediaLink, document.getElementById("uLinkId").value);
			axios
				.post("https://site.appclick.me/facecraw.php", params)
				.then((response) => {
					//console.log(response.data);

					if (response.data != "not found") {
						//document.getElementById('android').value = Linkmedia.android.replace('uLinkId', iHeartMusicID[iHeartMusicID.length-1]);
						document.getElementById("ios").value = Linkmedia.ios.replace(
							"uLinkId",
							response.data
						);
						btnLinkActs.innerHTML = btnLinkActsTxt;
						btnLinkActs.disabled = false;
					} else {
						btnLinkActs.innerHTML =
							"Colocar " +
							btnAddLinkText +
							" de facebook válido(a) para criar link";
					}
					// modalAlert('Arquivo Gerado com sucesso.','success');
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
				});
		}

		if (
			mediaLink == "instagramp" ||
			mediaLink == "igtv" ||
			mediaLink == "instagramreel"
		) {
			var btnAddLinkText = "";

			switch (mediaLink) {
				case "instagramp":
					btnAddLinkText = "post";
					break;
				case "igtv":
					btnAddLinkText = "vídeo";
					break;
				case "instagramreel":
					btnAddLinkText = "reel";
					break;
			}

			btnLinkActs.disabled = true;
			btnLinkActs.innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;

			let finalInstaUrl = "";

			if (document.getElementById("httpLinkNew").value.slice(-1) == "/")
				finalInstaUrl = document
					.getElementById("httpLinkNew")
					.value.slice(0, -1);
			else finalInstaUrl = document.getElementById("httpLinkNew").value;

			const getInstagramInfo = functions.httpsCallable("getInstagramInfo");
			getInstagramInfo({
				url: finalInstaUrl,
			})
				.then((result) => {
					

					if (result.data) {
						const instaMediaID = result.data.graphql.shortcode_media.id;
						//document.getElementById('android').value = Linkmedia.android.replace('uLinkId', iHeartMusicID[iHeartMusicID.length-1]);
						document.getElementById("ios").value = Linkmedia.ios.replace(
							"uLinkId",
							instaMediaID
						);
						//console.log(document.getElementById('ios').value);
						btnLinkActs.innerHTML = btnLinkActsTxt;
						btnLinkActs.disabled = false;
					} else {
						btnLinkActs.innerHTML =
							"Colocar " +
							btnAddLinkText +
							" de instagram válido(a) para criar link";
					}
					// modalAlert('Arquivo Gerado com sucesso.','success');
				})
				.catch((error) => {
					// modalAlert(
					// 	"Houve um erro ao processar sua requisição. Tente novamente em alguns minutos."
					// );
					// btnLinkActs.innerHTML =
					// 	"Colocar " +
					// 	btnAddLinkText +
					// 	" de instagram válido(a) para criar link";
					
					document.getElementById("ios").value =finalInstaUrl;
					//console.log(document.getElementById('ios').value);
					btnLinkActs.innerHTML = btnLinkActsTxt;
					btnLinkActs.disabled = false;
					console.log(error);
				});
		}

		if (mediaLink == "ubereats") {
			var btnAddLinkText = "";

			switch (mediaLink) {
				case "ubereats":
					btnAddLinkText = "post";
					break;
			}

			btnLinkActs.disabled = true;
			btnLinkActs.innerHTML = `
                  <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Aguarde...</span>
                  </div>Aguarde...`;

			const params = new FormData();
			params.append(mediaLink, document.getElementById("uLinkId").value);
			axios
				.post("https://site.appclick.me/uberEatscraw.php", params)
				.then((response) => {
					//console.log(response.data.toString());

					if (response.data != "not found") {
						const instaUberEatsID = response.data.replace(/'/g, "");
						//document.getElementById('android').value = Linkmedia.android.replace('uLinkId', iHeartMusicID[iHeartMusicID.length-1]);
						document.getElementById("ios").value = Linkmedia.ios.replace(
							"uLinkId",
							instaUberEatsID
						);
						document.getElementById("android").value =
							Linkmedia.android.replace("uLinkId", instaUberEatsID);
						btnLinkActs.innerHTML = btnLinkActsTxt;
						btnLinkActs.disabled = false;
					} else {
						btnLinkActs.innerHTML =
							"Colocar link de Uber Eats válido(a) para criar link";
					}
					// modalAlert('Arquivo Gerado com sucesso.','success');
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
				});
		}
	} else {
		//linkList.innerHTML = '<h5 class="center-align">Faça Login para ver seus Links</h5>';
	}
}

function listenLuserID(linkTypeID) {
	const updLuserID = document.getElementById("LuserID");
	const upduLinkId = document.getElementById("uLinkId");

	if (updLuserID) {
		updLuserID.addEventListener("keyup", (e) => {
			e.preventDefault();

			let mediaLink = linkTypeID;

			//alert(mediaLink);

			if (mediaLink.length) {
				let html = "";
				getLinkTypesByMedia(mediaLink)
					.then(
						(snapshot) => {
							const Linkmedia = snapshot.docs[0].data();
							document.getElementById("fallback").value = Linkmedia.fallback
								.replace("LuserID", updLuserID.value)
								.replace("uLinkId", upduLinkId.value);
							document.getElementById("android").value = Linkmedia.android
								.replace("LuserID", updLuserID.value)
								.replace("uLinkId", upduLinkId.value);
							//console.log(Linkmedia);
							//afterSelectMedia.innerHTML = html
							//M.updateTextFields();
							//M.CharacterCounter.init(document.querySelectorAll('.has-character-counter'));
							//document.getElementById("uLinkId").focus();
						},
						(err) => console.log(err.message)
					);
			} else {
				//linkList.innerHTML = '<h5 class="center-align">Faça Login para ver seus Links</h5>';
			}
		});
	}
}

//NEW link routes page

const editLinkNew = document.getElementById("editLinkNew");
if (editLinkNew != null) {
	let linkTypeSession = sessionStorage.getItem("linkType");
	let shortLinkID = sessionStorage.getItem("shortLinkID");
	document.getElementById("linkTypeInput").value = linkTypeSession;
	document.getElementById("shortLink").value = shortLinkID;
	document.getElementById("linkWithoutID").innerHTML =
		linkDomain + linkTypeSession + "/";
	let linkHttp;
	let link;

	//let html = '';
	// db
	// 	.collection("linkTypes")
	// 	.where("media", "==", linkTypeSession)
	// 	.get()
	getLinkTypesByMedia(linkTypeSession)
		.then((snapshot) => {
			//const Linkmedia = snapshot.docs[0].data();
			let html = '<div class="row">';
			let img;

			snapshot.forEach((doc) => {
				link = doc.data();
				linkHttp = link.fallback;
				if (imageExists("/assets/img/icons/" + doc.id + ".png"))
					img = '<img src="/assets/img/icons/' + doc.id + '.png" />';
				else img = '<span class="avatar-title rounded">logo</span>';

				const li = `
              <div class="col">
                <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Heading -->
                          <span class="h2 mb-0">
                          ${link.description}
                          </span>
                        </div>
                        <input type="hidden" class="linktypeIDToClick" value="${doc.id}" />
                        <div class="col-auto">
                          <div class="avatar avatar-xl">
                            ${img}
                          </div>
                        </div>
                      </div> <!-- / .row -->
                    </div>
                    
                  </div>
              </div>
              `;
				html += li;
			});
			document.getElementById("linkTypeChoose").innerHTML = html;
		}),
		// db
		// 	.collection("links")
		// 	.where("linkType", "==", linkTypeSession)
		// 	.where("shortLink", "==", shortLinkID)
		// 	.get()
		checkLinkExists(linkTypeSession,shortLinkID)
			.then(
				(snapshot) => {
					const Linkmedia = snapshot.docs[0].data();
					//console.log(Linkmedia);
					document.getElementById("android").value = Linkmedia.android;
					document.getElementById("ios").value = Linkmedia.ios;
					document.getElementById("fallback").value = Linkmedia.fallback;
					document.getElementById("uLinkId").value = Linkmedia.uLinkId;
					document.getElementById("description").value = Linkmedia.description;
					document.getElementById("docID").value = snapshot.docs[0].id;

					//document.getElementById('idExample').innerHTML='Seu ID do link Ex: ('+Linkmedia.fallback.replace(Linkmedia.uLinkId,'<b>'+Linkmedia.uLinkId+'</b>')+') (Somente conteúdo em <b>negrito</b>)';
					document.getElementById("linkFallBack").innerHTML =
						linkHttp.split("uLinkId")[0];

					const linkArr = Linkmedia.fallback.split(Linkmedia.uLinkId);

					if (linkArr.length == 2)
						document.getElementById("lnkExtraData").innerHTML = linkArr[1];
					//console.log(Linkmedia);
					//afterSelectMedia.innerHTML = html
					//M.updateTextFields();
					//M.CharacterCounter.init(document.querySelectorAll('.has-character-counter'));

					document.getElementById("uLinkId").addEventListener(
						"keyup",
						debounce(async (e) => {
							e.preventDefault();
							
							//get ifood id if link is without
							if (Linkmedia.linkType=="ifood"){
								const startUrl = "https://www.ifood.com.br/delivery/";
								let finalURL = await getFinalURL(startUrl+document.getElementById("uLinkId").value);
								document.getElementById("uLinkId").value = finalURL.split(startUrl)[1];
								//console.log(finalURL);

							}
								

							document.getElementById("android").value = link.android.replace(
								"uLinkId",
								document.getElementById("uLinkId").value
							);
							document.getElementById("ios").value = link.ios.replace(
								"uLinkId",
								document.getElementById("uLinkId").value
							);
							document.getElementById("fallback").value = link.fallback.replace(
								"uLinkId",
								document.getElementById("uLinkId").value
							);

							await fixuLinkId(Linkmedia);
							//console.log(Linkmedia);
						}, 500)
					);
				},
				(err) => console.log(err.message)
			);
	//listenuLinkId(linkTypeSession);
	document.getElementById("uLinkId").focus();
}

//link routes page

const editLink = document.getElementById("editLink");
if (editLink != null) {
	let linkTypeSession = sessionStorage.getItem("linkType");
	let shortLinkID = sessionStorage.getItem("shortLinkID");
	document.getElementById("linkTypeInput").value = linkTypeSession;
	document.getElementById("shortLink").value = shortLinkID;
	document.getElementById("linkWithoutID").innerHTML =
		linkDomain + linkTypeSession + "/";
	let linkHttp;

	//let html = '';
	// db
	// 	.collection("linkTypes")
	// 	.where("media", "==", linkTypeSession)
	// 	.get()
	getLinksByMedia(linkTypeSession)
		.then((snapshot) => {
			//const Linkmedia = snapshot.docs[0].data();
			let html = '<div class="row">';
			let img;

			snapshot.forEach((doc) => {
				const link = doc.data();
				linkHttp = link.fallback;
				if (imageExists("/assets/img/icons/" + doc.id + ".png"))
					img = '<img src="/assets/img/icons/' + doc.id + '.png" />';
				else img = '<span class="avatar-title rounded">logo</span>';

				const li = `
              <div class="col-12 col-lg-6 col-xl">
                <div class="card">
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col">
                          <!-- Heading -->
                          <span class="h2 mb-0">
                          ${link.description}
                          </span>
                        </div>
                        <input type="hidden" class="linktypeIDToClick" value="${doc.id}" />
                        <div class="col-auto">
                          <div class="avatar avatar-xl">
                            ${img}
                          </div>
                        </div>
                      </div> <!-- / .row -->
                    </div>
                    
                  </div>
              </div>
              `;
				html += li;
			});
			document.getElementById("linkTypeChoose").innerHTML = html;
		}),
		db
			.collection("links")
			.where("linkType", "==", linkTypeSession)
			.where("shortLink", "==", shortLinkID)
			.get()
			.then(
				(snapshot) => {
					const Linkmedia = snapshot.docs[0].data();
					console.log(Linkmedia);
					document.getElementById("android").value = Linkmedia.android;
					document.getElementById("ios").value = Linkmedia.ios;
					document.getElementById("fallback").value = Linkmedia.fallback;
					document.getElementById("uLinkId").value = Linkmedia.uLinkId;
					document.getElementById("description").value = Linkmedia.description;
					document.getElementById("docID").value = snapshot.docs[0].id;

					//document.getElementById('idExample').innerHTML='Seu ID do link Ex: ('+Linkmedia.fallback.replace(Linkmedia.uLinkId,'<b>'+Linkmedia.uLinkId+'</b>')+') (Somente conteúdo em <b>negrito</b>)';
					document.getElementById("idExample").innerHTML =
						"Seu ID do link Ex: (" +
						linkHttp.replace("uLinkId", "<b>uLinkId</b>") +
						") (Somente conteúdo em <b>negrito</b>)";

					if (Linkmedia.LuserID) {
						document.getElementById("hasLuserID").innerHTML = `
                <div class="form-group">
                  <!-- Label  -->
                  <label class="form-label">
                    ID do Usuário
                  </label>

                  <!-- Text -->
                  <small class="form-text text-muted">
                    Seu ID do link Ex:${Linkmedia.fallback.replace(
											"LuserID",
											"<b>userID</b>"
										)} (Somente conteúdo em <b>negrito</b>)
                  </small>

                  <!-- Input -->
                  <input type="text"  id="LuserID" name="LuserID" class="form-control" value="${
										Linkmedia.LuserID
									}" required>
                </div>`;
						listenLuserID(linkTypeSession);
					}
					//console.log(Linkmedia);
					//afterSelectMedia.innerHTML = html
					//M.updateTextFields();
					//M.CharacterCounter.init(document.querySelectorAll('.has-character-counter'));
				},
				(err) => console.log(err.message)
			);
	listenuLinkId(linkTypeSession);
	document.getElementById("uLinkId").focus();
}

// facebook satus
function statusChangeCallback(response) {
	if (response.status === "connected") {
		console.log("Logged in and authenticated");
		//setElements(true);
		testAPI();
	} else {
		console.log("Not authenticated");
		//setElements(false);
	}
}

const requestForm = document.getElementById("create-link-form");

// add a new link

if (requestForm != null) {
	requestForm.addEventListener("submit", (e) => {
		e.preventDefault();

		let descText = document.getElementById("shortLink");
		descText.classList.remove("is-invalid");

		if (descText.value.indexOf(" ") !== -1) {
			document.getElementById("shortLinkError").innerText =
				"Seu Link contém espaços. Favor removê-los";
			descText.classList.add("is-invalid");
			descText.focus();
			return false;
		}

		document.getElementById("createLink").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Salvando...</span>
      </div>Aguarde...`;

		if (requestForm.linkType.value == "igtv") {
			var ref = require("instagram-id-to-url-segment");
			var urlSegmentToInstagramId = ref.urlSegmentToInstagramId;
			//console.log(urlSegmentToInstagramId(requestForm.uLinkId.value));
			requestForm.ios.value = requestForm.ios.value.replace(
				requestForm.uLinkId.value,
				urlSegmentToInstagramId(requestForm.uLinkId.value)
			);

			//testa se já existe o link já foi usado
		}
		if (requestForm.linkType.value == "facebook") {
			//
		}
		// db.collection("links")
		// 	.where("linkType", "==", requestForm.linkType.value)
		// 	.where("shortLink", "==", requestForm.shortLink.value)
		// 	.get()
		checkLinkExists(requestForm.linkType.value,requestForm.shortLink.value).then(
			(snapshot) => {
					if (!snapshot.empty)
						modalAlert(
							"O endereço " +
								linkDomain +
								"" +
								requestForm.linkType.value +
								"/" +
								requestForm.shortLink.value +
								" já foi usado... favor escolher outro nome",
							"warning",
							null,
							"shortLink"
						);
					else {
						//const addLink = functions.httpsCallable("addLink");
						if (document.getElementById("LuserID")) {
							firebaseService.callFirebaseFunction("addLink", {
								description: requestForm.description.value,
								linkType: requestForm.linkType.value,
								shortLink: requestForm.shortLink.value,
								android: requestForm.android.value,
								ios: requestForm.ios.value,
								fallback: requestForm.fallback.value,
								uLinkId: requestForm.uLinkId.value,
								LuserID: requestForm.LuserID.value,
								email: auth.currentUser.email,
								active: true,
							},{useCache:false})
								.then((result) => {
									document.getElementById("createLink").innerHTML =
										"Criar Link";
									modalAlert(
										"Link Criado com Sucesso",
										"success",
										"links.html"
									);
								})
								.catch((error) => {
									modalAlert(error.message);
									document.getElementById("createLink").innerHTML =
										"Criar Link";
								});
						} else {
							firebaseService.callFirebaseFunction("addLink", {
								description: requestForm.description.value,
								linkType: requestForm.linkType.value,
								shortLink: requestForm.shortLink.value,
								android: requestForm.android.value,
								ios: requestForm.ios.value,
								fallback: requestForm.fallback.value,
								uLinkId: requestForm.uLinkId.value,
								userEmail: sessionStorage.getItem("viewAsUsr"),
								active: true,
							},{useCache:false})
								.then((result) => {
									console.log(result);
									sessionStorage.setItem(
										"linkType",
										requestForm.linkType.value
									);
									sessionStorage.setItem(
										"shortLinkID",
										requestForm.shortLink.value
									);
									//modalAlert('Link Criado com Sucesso','success','links.html');
									modalMsg(
										"success.svg",
										"Link Criado",
										"Seu link foi criado com sucesso, acompanhe os dados completos em tempo real.",
										"Dashboard do Link",
										"btn-primary",
										"link-details.html"
									);
									btnModalListener = (e) => {
										e.preventDefault();
										top.location.href = "link-details.html";
									};
									document
										.getElementById("modalInfo" + "btn")
										.addEventListener("click", btnModalListener, true);
									document.getElementById("createLink").innerHTML =
										"Criar Link";
								})
								.catch((error) => {
									modalAlert(error.message);
									console.log(error);
									document.getElementById("createLink").innerHTML =
										"Criar Link";
								});
						}
					}
				},
				(err) => console.log(err.message)
			);
	});
}

//create-link-form-wizard

const requestFormWiz = document.getElementById("create-link-form-wizard");

// add a new link

if (requestFormWiz != null) {
	requestFormWiz.addEventListener("submit", async (e) => {
		e.preventDefault();

		document.getElementById("httpLinkNew").value =
			requestFormWiz.httpFallbackFromWiz.value.replace(
				"uLinkId",
				requestFormWiz.httpLinkFromWiz.value
			);
		await validateNewLink();
		modalCreateLink.toggle();
		//alert(document.getElementById('httpFallbackFromWiz').value);
	});
}

const editLinkForm = document.getElementById("edit-link-form");

// edit a link

if (editLinkForm != null) {
	editLinkForm.addEventListener("submit", (e) => {
		e.preventDefault();
		document.getElementById("btnedtLink").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Carregando...</span>
      </div>Aguarde...`;
		//testa se já existe o link já foi usado

		// const edtLink = functions.httpsCallable("edtLink");
		// edtLink({
		// 	description: editLinkForm.description.value,
		// 	uLinkId: editLinkForm.uLinkId.value,
		// 	android: editLinkForm.android.value,
		// 	ios: editLinkForm.ios.value,
		// 	fallback: editLinkForm.fallback.value,
		// 	docid: editLinkForm.docid.value,
		// })
		firebaseService.callFirebaseFunction("edtLink", {
			description: editLinkForm.description.value,
			uLinkId: editLinkForm.uLinkId.value,
			android: editLinkForm.android.value,
			ios: document.getElementById("ios").value,
			fallback: editLinkForm.fallback.value,
			docid: editLinkForm.docid.value,
		},{useCache:false})
			.then((result) => {
				modalAlert("Link Alterado com Sucesso", "success", "link-routes.html");
				document.getElementById("btnedtLink").innerHTML = "Atualizar Link";
			})
			.catch((error) => {
				modalAlert(error.message);
				console.log(error);
				document.getElementById("btnedtLink").innerHTML = "Atualizar Link";
			});
	});
}

// add url shortener

const addUrlshortForm = document.getElementById("add-urlshort-form");

if (addUrlshortForm != null) {
	addUrlshortForm.addEventListener("submit", (e) => {
		e.preventDefault();
		document.getElementById("btnUrlShort").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Carregando...</span>
      </div>Aguarde...`;

		const addUrlShort = functions.httpsCallable("addUrlShort");

		addUrlShort({
			shorUrlID: addUrlshortForm.shorUrlID.value,
			linkType: sessionStorage.getItem("linkType"),
			shortLink: sessionStorage.getItem("shortLinkID"),
			utm_source: addUrlshortForm.utm_source.value,
			utm_medium: addUrlshortForm.utm_medium.value,
			utm_campaign: addUrlshortForm.utm_campaign.value,
			utm_term: addUrlshortForm.utm_term.value,
			utm_content: addUrlshortForm.utm_content.value,
		})
			.then((result) => {
				document.getElementById("btnUrlShort").innerHTML = "Salvar Encurtador";
				modalAlert("Link Criado com Sucesso", "success", "link-urlshorts.html");
			})
			.catch((error) => {
				modalAlert(error.message);
				console.log(error);
				document.getElementById("btnUrlShort").innerHTML = "Salvar Encurtador";
			});
	});
}

// edit url shortener

const editUrlshortForm = document.getElementById("edit-urlshort-form");

if (editUrlshortForm != null) {
	const getUrlShort = functions.httpsCallable("getUrlShorts");

	getUrlShort({
		urlShortID: sessionStorage.getItem("urlShortID"),
	})
		.then((result) => {
			//modalAlert('Link Criado com Sucesso','success','links.html');
			

			

			editUrlshortForm.shorUrlID.value = sessionStorage.getItem("urlShortID");
			editUrlshortForm.utm_source.value = result.data.utm_source || "";
			editUrlshortForm.utm_medium.value = result.data.utm_medium || "";
			editUrlshortForm.utm_campaign.value = result.data.utm_campaign || "";
			editUrlshortForm.utm_term.value = result.data.utm_term || "";
			editUrlshortForm.utm_content.value = result.data.utm_content || "";

			//setupLastClicks(result.data);
		})
		.catch((error) => {
			modalAlert(error.message);
			console.log(error);
		});

	editUrlshortForm.addEventListener("submit", (e) => {
		e.preventDefault();
		document.getElementById("btnUrlShort").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Carregando...</span>
      </div>Aguarde...`;

		const addUrlShort = functions.httpsCallable("addUrlShort");

		addUrlShort({
			shorUrlID: editUrlshortForm.shorUrlID.value,
			linkType: sessionStorage.getItem("linkType"),
			shortLink: sessionStorage.getItem("shortLinkID"),
			utm_source: editUrlshortForm.utm_source.value,
			utm_medium: editUrlshortForm.utm_medium.value,
			utm_campaign: editUrlshortForm.utm_campaign.value,
			utm_term: editUrlshortForm.utm_term.value,
			utm_content: editUrlshortForm.utm_content.value,
		})
			.then((result) => {
				document.getElementById("btnUrlShort").innerHTML = "Salvar Encurtador";
				modalAlert(
					"Link Alterado com Sucesso",
					"success",
					"link-urlshorts.html"
				);
			})
			.catch((error) => {
				modalAlert(error.message);
				console.log(error);
				document.getElementById("btnUrlShort").innerHTML = "Salvar Encurtador";
			});
	});
}

const requestTypeLinkForm = document.getElementById("create-linkType-form");
// add a new link type

if (requestTypeLinkForm != null) {
	requestTypeLinkForm.addEventListener("submit", (e) => {
		e.preventDefault();
		//alert('passsou aqui');
		document.getElementById("btnlinkType").innerHTML = `
      <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Carregando...</span>
      </div>Aguarde...`;

		const addLinkType = functions.httpsCallable("addLinkType");
		if (document.getElementById("LuserID")) {
			addLinkType({
				id: requestTypeLinkForm.media.value,
				description: requestTypeLinkForm.description.value,
				android: requestTypeLinkForm.android.value,
				ios: requestTypeLinkForm.ios.value,
				fallback: requestTypeLinkForm.fallback.value,
				media: requestTypeLinkForm.media.value,
				LuserID: requestTypeLinkForm.LuserID.value,
			})
				.then((result) => {
					document.getElementById("btnlinkType").innerHTML = "Criar Tipo Link";
					modalAlert(
						"Tipo Link Criado com Sucesso",
						"success",
						"link-types.html"
					);
				})
				.catch((error) => {
					modalAlert(error);
					console.log(error);
					document.getElementById("btnlinkType").innerHTML = "Criar Tipo Link";
				});
		} else {
			addLinkType({
				id: requestTypeLinkForm.media.value,
				description: requestTypeLinkForm.description.value,
				android: requestTypeLinkForm.android.value,
				ios: requestTypeLinkForm.ios.value,
				fallback: requestTypeLinkForm.fallback.value,
				media: requestTypeLinkForm.media.value,
			})
				.then((result) => {
					modalAlert(
						"Tipo Link Criado com Sucesso",
						"success",
						"link-types.html"
					);
					document.getElementById("btnlinkType").innerHTML = "Criar Tipo Link";
				})
				.catch((error) => {
					modalAlert(error.message);
					console.log(error);
					document.getElementById("btnlinkType").innerHTML = "Criar Tipo Link";
				});
		}
	});
}

// edit linktype page
const editLinkType = document.getElementById("edit-linkType-form");
if (editLinkType != null) {
	let linkTypeSession = sessionStorage.getItem("linkType");
	//let shortLinkID = sessionStorage.getItem("shortLinkID");
	document.getElementById("media").value = linkTypeSession;
	document.getElementById("docid").value = linkTypeSession;
	//document.getElementById('shortLink').value=shortLinkID;
	//document.getElementById('linkWithoutID').innerHTML=linkDomain+linkTypeSession+'/';

	//let html = '';
	// db
	// 	.collection("linkTypes")
	// 	.where("media", "==", linkTypeSession)
	// 	.get()
	getLinkTypesByMedia(linkTypeSession)
		.then((snapshot) => {
			//const Linkmedia = snapshot.docs[0].data();
			console.log(snapshot.size);
			let html = '<div class="row">';
			let img;

			snapshot.forEach((doc) => {
				const linkTypeCollection = doc.data();
				
				/*
              if (imageExists('/src/img/icons/'+doc.id+'.png'))
              img='<img src="/src/img/icons/'+doc.id+'.png" />';
              else
              img='<span class="avatar-title rounded">logo</span>';
              */

				document.getElementById("android").value = linkTypeCollection.android;
				document.getElementById("ios").value = linkTypeCollection.ios;
				document.getElementById("fallback").value = linkTypeCollection.fallback;
				document.getElementById("description").value =
					linkTypeCollection.description || "";
			});
		}),
		(err) => console.log(err.message);
}

const editTypeLinkForm = document.getElementById("edit-linkType-form");
// edit link type submit

if (editTypeLinkForm != null) {
	editTypeLinkForm.addEventListener("submit", (e) => {
		e.preventDefault();
		document.getElementById("btnlinkType").innerHTML = `
     <div class="spinner-border spinner-border-sm" role="status">
     <span class="sr-only">Carregando...</span>
     </div>Aguarde...`;
		//alert('passsou aqui');
		const edtLinkType = functions.httpsCallable("edtLinkType");
		edtLinkType({
			docid: editTypeLinkForm.docid.value,
			description: editTypeLinkForm.description.value,
			android: editTypeLinkForm.android.value,
			ios: editTypeLinkForm.ios.value,
			fallback: editTypeLinkForm.fallback.value,
			media: editTypeLinkForm.media.value,
		})
			.then((result) => {
				modalAlert(
					"Tipo Link Alterado com Sucesso",
					"success",
					"link-types.html"
				);
				document.getElementById("btnlinkType").innerHTML = "Alterar Tipo Link";
			})
			.catch((error) => {
				modalAlert(error.message);
				console.log(error);
				document.getElementById("btnlinkType").innerHTML = "Alterar Tipo Link";
			});
	});
}

//test navigator language

var userLang = navigator.language || navigator.userLanguage;
//alert ("O idioma do navegador é: " + userLang.substr(0,2));

// run function after page load :: get/set localstorage value and run function
document.addEventListener("DOMContentLoaded", function (event) {
	var appLang = localStorage.getItem("lang");

	if (userLang.substr(0, 2) == "pt" && appLang === null) {
		appLang = "pt";
	} else if (appLang === null) {
		appLang = "en";
	}
	appLang = "pt";

	localStorage.setItem("lang", appLang); // update local-storage

	// contentUpdate function with value from local-storage - en, ru..
	contentUpdate(appLang);

	// select radiobutton which has data-value == local storage value
	// document.querySelector('[data-value="'+appLang+'"]').checked = true;
});

// change innerhtml on radiobtn click
function changeLang(langVal) {
	// set local-storage lang value from value given in onchange="changeLang(value)"
	localStorage.setItem("lang", langVal);

	// fun contentUpdate function with value from onchange="changeLang(value)"
	contentUpdate(langVal);
}

// content/innerhtml update/assign
function contentUpdate(cl) {
	// alert(cl);
	// get language json file
	fetch("assets/lang/" + cl + ".json")
		.then((response) => response.json())
		.then((langJSON) => {
			// get current langage contents in array
			//var currLang = [Object.keys(langJSON)][1],
			//var currLang = JSON.parse(langJSON);

			// set languange menu
			if (document.getElementById("selectedLanguage"))
				document.getElementById("selectedLanguage").innerHTML =
					'<img src="assets/img/site/img-' +
					cl +
					'.png" alt="" /> ' +
					langJSON["txtLang"];

			// get current language content array length
			let langCont = document.querySelectorAll(".langchange").length; //Object.keys(langJSON).length; //Object.entries(currLang).length;

			//alert(langCont);

			for (let i = 0; i < langCont; i++) {
				// get selectors which has .langchange classes
				var getSelector = document.querySelectorAll(".langchange")[i],
					// get data-key attribute from .langchange class selectors
					getAttr = getSelector.getAttribute("data-key");

				// test if the selector is image
				if (getSelector.nodeName.toLowerCase() === "img") {
					getSelector.src = "assets/img/site/" + langJSON[getAttr];
					//alert(getAttr);
				} else {
					// assign the data-key value from current language array to the .langchange[data-key] selector
					getSelector.innerHTML = langJSON[getAttr];
				}
			}
		});
}

const updateLangToEn = document.getElementById("updateLangToEn");
if (updateLangToEn) {
	updateLangToEn.addEventListener("click", (e) => {
		changeLang("en");
	});
}

const updateLangToPt = document.getElementById("updateLangToPt");
if (updateLangToPt) {
	updateLangToPt.addEventListener("click", (e) => {
		changeLang("pt");
	});
}

//change plans table

const selectPlan = document.querySelectorAll(".selectPlanoption");

if (selectPlan != null) {
	//alert('teste');
	selectPlan.forEach((item) =>
		item.addEventListener("click", (e) => {
			e.preventDefault();

			//modalAlert("Função disponível em breve", "warning");
			changePlan(e.target);
		})
	);
}

function changePlan(element) {
	//console.log(element);

	document.querySelectorAll(".selectPlanoption").forEach((e) => {
		e.className = "btn btn__blue selectPlanoption";
	});
	element.className = "btn btn__blue--active selectPlanoption";
	//let selectPlan = document.querySelectorAll('.selectPlanoption'); //Object.keys(langJSON).length; //Object.entries(currLang).length;

	var precos = {
		1: 59.0,
		2: 197.0,
		3: 497.0,
	};
	var precosAnuais = {
		1: 29.0,
		2: 97.0,
		3: 247.0,
	};
	var multiplicar = element.getAttribute("data-multiplicar");
	var desconto = element.getAttribute("data-desconto");

	document.querySelectorAll(".periodo-item").forEach((e) => {
		var plano = e.getAttribute("data-plano");

		var preco = (precos[plano] * multiplicar).toFixed(2);
		//var preco_com_desconto = (preco*(1-(desconto/100))).toFixed(2);
		var preco_com_desconto = (precosAnuais[plano] * multiplicar).toFixed(2);
		// var preco_mensal_com_desconto =(precos[plano]*(1-(desconto/100))).toFixed(2);
		var preco_mensal_com_desconto = precosAnuais[plano].toFixed(2);

		//console.log(multiplicar);

		if (desconto == 0) {
			e.getElementsByClassName("planos__card-desconto")[0].style.display =
				"none";
			e.getElementsByClassName("planos__card-cobrado")[0].style.display =
				"none";
			e.getElementsByClassName("planos__card-preco")[0].innerHTML =
				"R$ " +preco.replace(".", ",");
		} else {
			e.getElementsByClassName("planos__card-desconto")[0].innerHTML = "R$ " + precos[
				plano
			]
				.toFixed(2)
				.replace(".", ",");
			e.getElementsByClassName("planos__card-desconto")[0].style.display = "";

			e.getElementsByClassName("planos__card-preco")[0].innerHTML =
				"R$ " +preco_mensal_com_desconto.replace(".", ",");

			e.getElementsByClassName("preco_cobrado")[0].innerHTML =
				"R$ " +preco_com_desconto.replace(".", ",");
			e.getElementsByClassName("preco_periodo")[0].innerHTML = multiplicar;

			e.getElementsByClassName("planos__card-cobrado")[0].style.display = "";

			/*
        $(value).find('.preco_cobrado').html(preco_com_desconto.replace('.',','));
        $(value).find('.preco_periodo').html(multiplicar);
        $(value).find('.planos__card-cobrado').show();*/
		}
	});

	/*

    $.each($('.periodo-item'), function(key, value) {
      var plano = $(value).data('plano');
      var preco = precos[plano] * multiplicar;
      var preco_com_desconto = (preco*(1-(desconto/100))).toFixed(2);
      var preco_mensal_com_desconto =(precos[plano]*(1-(desconto/100))).toFixed(2);
      if (desconto==0){
        $(value).find('.planos__card-desconto').hide();
        $(value).find('.planos__card-cobrado').hide();
        $(value).find('.preco').html(preco_com_desconto.replace('.',','));
      }else{
        $(value).find('.planos__card-desconto').html(precos[plano].toFixed(2).replace('.',','));
        $(value).find('.planos__card-desconto').show();
        $(value).find('.preco').html(preco_mensal_com_desconto.replace('.',','));
        $(value).find('.preco_cobrado').html(preco_com_desconto.replace('.',','));
        $(value).find('.preco_periodo').html(multiplicar);
        $(value).find('.planos__card-cobrado').show();
      }
    });
    */
}

async function getFinalURL(url) {
    
	try {
		const result = await firebaseService.callFirebaseFunction("getFinalURL", {
			url: url,
		},{useCache:false})
			//console.log("getUserPayments cached");
			// console.log('URL final:', result.data.finalURL);
			return result.data.finalURL;
		
	} catch (error) {
		console.log(error);
		return url;
	}



       

}
