const redirect = async function (functions) {
	if (window.location.pathname.startsWith("/r/")) {
		console.log(window.location.pathname.substring(3));

		functions
			.httpsCallable("r")({ link: window.location.pathname.substring(1) })
			.then((response) => {
				const link = response.data.link;

				console.log(link);
				return;

				window.location.replace(link);
			});
	}
};

export { redirect };