let _functions;
let _ttlStorage;
let _verbose;

const cacheInvalidationJson = require("./cacheInvalidation");

const init = function (ttlStorage, functions, verbose = true) {
	_ttlStorage = ttlStorage;
	_functions = functions;

	if (verbose) {
		console.log(`Firebase module initiating in verbose mode`);
	}

	_verbose = verbose;
};

const callFirebaseFunction = async function (
	name,
	params = {},
	options = { useCache: true, ttlMinutes: 30 }
) {
	if (_verbose) {
		console.log(`calling ${name}`);
	}

	let cacheKey;

	if (options.useCache) {
		cacheKey = `${name}-${JSON.stringify(params, null, 0)}`;

		const cachedResult = _ttlStorage.get(cacheKey);

		if (cachedResult) {
			if (_verbose) {
				console.log(`resolved ${name} using cache`);
			}

			return cachedResult;
		}
	}

	const firebaseFunction = _functions.httpsCallable(name);

	const result = await firebaseFunction(params);

	if (options.useCache) {
		if (_verbose) {
			console.log(`cached ${name}`);
		}

		_ttlStorage.set(cacheKey, result, options.ttlMinutes * 60 * 1000);
	}
	console.log(name);

	invalidateCache(name);
	

	return result;
};

const invalidateCache = function (name) {
	cacheInvalidationJson.forEach(function ({ onCall, clean }) {
		if (onCall.includes(name)) {
			if (_verbose) {
				console.log(
					`${name} was called, cleaning cache for ${clean.join(",")}`
				);
			}

			for (let i = 0; i < clean.length; i++) {
				_ttlStorage.deleteAllThatStartWith(clean[i]);
			}
		}
	});
};

export { init, callFirebaseFunction };
